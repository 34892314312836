import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

import { ConfigService, AppConfig } from 'src/app/core/config.service';

@Component({
    selector: 'nc-welcome-message',
    templateUrl: './welcome-message.component.html',
    styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    config: AppConfig;

    constructor(
        private configService: ConfigService,
    ) { }

    ngOnInit() {
        this.subs.sink = this.configService.configChanges.subscribe((config) => {
            this.config = config;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}
