import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { CurrentDiscountsPageComponent } from '../current-discounts-page/current-discounts-page.component';
import { CouponsPageComponent } from '../my-account/coupons-page/coupons-page.component';
import { PcpRecipientsTableComponent } from '../pcp/pcp-recipients-table/pcp-recipients-table.component';
import { PcpTableByCustomerIdsDialogComponent } from '../pcp/pcp-table-by-customer-ids-dialog/pcp-table-by-customer-ids-dialog.component';
import { PcpTableByOrderIdComponent } from '../pcp/pcp-table-by-order-id/pcp-table-by-order-id.component';
import { CmsCouponsComponent } from './cms/blocks/coupons/coupons.component';
import { CmsPageAreaComponent } from './cms/cms-page-area/cms-page-area.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PortalModule } from '@angular/cdk/portal';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CouponComponent } from './coupon/coupon.component';
import { CurrencyPipe } from './currency.pipe';
import { RouterModule } from '@angular/router';
import { ProductErrorMessageComponent } from './product/product-error-message/product-error-message.component';
import { ProductsGridComponent } from './products-grid/products-grid.component';
import { ProductColorListComponent } from './products-grid/product-color-list.component';
import { ProductImageListComponent } from './products-grid/product-image-list.component';
import { ProductsFilterComponent } from './products-filter/products-filter.component';
import { ButtonComponent } from './button/button.component';
import { FavouriteButtonComponent } from './favourites/favourite-button.component';
import { WelcomeMessageComponent } from './cms/blocks/welcome-message/welcome-message.component';
import { RecommendedProductsComponent } from './cms/blocks/recommended-products/recommended-products.component';
import { ChallengesComponent } from './cms/blocks/challenges/challenges.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ShoppingCartItemComponent } from './shopping-cart/shopping-cart-item.component';
import { DialogComponent } from './dialog/dialog.component';
import { CutCornerDialogComponent } from './dialog/cut-corner-dialog.component';
import { AlertDialogComponent } from './dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DateTimePickerComponent } from './date-time-picker.component';
import { DatePickerComponent } from './date-picker.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ModelPickerComponent } from './model-picker/model-picker.component';
import { PagedDataTableComponent } from './paged-data-table/paged-data-table.component';
import { ProductDialogComponent } from './products-grid/product-dialog.component';
import { ProductGalleryComponent } from './product/product-gallery/product-gallery.component';
import { ProductGalleryNavigationComponent } from './product/product-gallery/product-gallery-navigation.component';
import { ProductGalleryCarouselComponent } from './product/product-gallery/product-gallery-carousel.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProductPriceComponent } from './product/product-price/product-price.component';
import { MkIconComponent } from './mk-icon/mk-icon.component';
import { SteppedProgressBarComponent } from './challenges/stepped-progress-bar/stepped-progress-bar.component';
import { ProductSubtitleComponent } from './product/product-subtitle/product-subtitle.component';
import { ProductDressFormComponent } from 'src/app/shared/product/product-dress-form/product-dress-form.component';
import { ProductTicketFormComponent } from 'src/app/shared/product/product-ticket-form/product-ticket-form.component';
import { ShoppingCartChallengesComponent } from './shopping-cart/shopping-cart-challenges/shopping-cart-challenges.component';
import { ShoppingCartCouponsComponent } from './shopping-cart/shopping-cart-coupons/shopping-cart-coupons.component';
import { ItemMetadataTicketComponent } from './shopping-cart/item-metadata-ticket/item-metadata-ticket.component';
import { ProductPcpFormComponent } from './product/product-pcp-form/product-pcp-form.component';
import { ItemMetadataPcpComponent } from './shopping-cart/item-metadata-pcp/item-metadata-pcp.component';
import { StartKitAddBundleComponent } from './start-kit/start-kit-add-bundle/start-kit-add-bundle.component';
import { StartKitShowBundleComponent } from 'src/app/shared/start-kit/start-kit-show-bundle/start-kit-show-bundle.component';
import { ProductConfigurableSelectorComponent } from './product/product-configurable-selector/product-configurable-selector.component';
import { ProductImageComponent } from './product/product-image/product-image.component';
import { ProductVideoComponent } from './product/product-video/product-video.component';
import { ChatSupportComponent } from '../chat-support/chat-support.component';
import { CategoryComponent } from './category/category.component';
import { StartKitPreviewDialogComponent } from './start-kit/start-kit-preview-dialog/start-kit-preview-dialog.component';
import { ProductOutOfStockComponent } from './product/product-out-of-stock/product-out-of-stock.component';
import { ProductViewComponent } from './product/product-view/product-view.component';
import { ProductTermsComponent } from 'src/app/shared/product/product-terms/product-terms.component';
import { FormErrorMessageComponent } from './form-error-message/form-error-message.component';
import { FlagCountryImage } from './flag-country-image/flag-country-image';
import { FlagLanguageImage } from './flag-language-image/flag-language-image';
import { ItemMetadataDressComponent } from './shopping-cart/item-metadata-dress/item-metadata-dress.component';
import { FinalPaymentsComponent } from './final-payments/final-payments.component';
import { MatButtonModule } from '@angular/material/button';
import { ProductsLayoutSwitchComponent } from './category/products-layout-switch/products-layout-switch.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductColorDotComponent } from './product/product-color-dot/product-color-dot.component';
import { ProductStockQuantityComponent } from './product/product-stock-quantity/product-stock-quantity.component';
import { ProductDynamicQuantitySelectorComponent } from './product/product-dynamic-quantity-selector/product-dynamic-quantity-selector.component';
import { MailTemplateComponent } from './mail-template/mail-template.component';
import { ProductCountComponent } from './category/product-count/product-count.component';
import { ProductImageRibbonsComponent } from './product/product-image-ribbons/product-image-ribbons.component';
import { ActivationOrderComponent } from '../administration/activating-order-and-discounts/activation-order/activation-order.component';
import { OrderConfirmationEmailFormComponent } from '../administration/order-confirmation-emails/order-confirmation-email-form/order-confirmation-email-form.component';
import { ProductNameComponent } from './product-name/product-name.component';
import { ProductDressValuesComponent } from '../my-account/orders-page/product-dress-values/product-dress-values.component';
import { StockMonitorButtonComponent } from './stock/stock-monitor-button/stock-monitor-button.component';
import {ShowMoreComponent} from "./show-more/show-more.component";
import {
    ProductPcpFormOrderUpdateComponent
} from "./product/product-pcp-form-order-update/product-pcp-form.component-order-update";

@NgModule({
    declarations: [
        ProductsGridComponent,
        ProductColorListComponent,
        ProductImageListComponent,
        ProductsFilterComponent,
        CmsPageAreaComponent,
        CurrencyPipe,
        ButtonComponent,
        FavouriteButtonComponent,
        WelcomeMessageComponent,
        RecommendedProductsComponent,
        ChallengesComponent,
        ShoppingCartComponent,
        ShoppingCartItemComponent,
        DialogComponent,
        CutCornerDialogComponent,
        AlertDialogComponent,
        ConfirmDialogComponent,
        DataTableComponent,
        DateTimePickerComponent,
        DatePickerComponent,
        QuantitySelectorComponent,
        ColorPickerComponent,
        ModelPickerComponent,
        PagedDataTableComponent,
        ProductDialogComponent,
        ProductGalleryComponent,
        ProductGalleryNavigationComponent,
        ProductGalleryCarouselComponent,
        ProductPriceComponent,
        BreadcrumbsComponent,
        ProgressBarComponent,
        MkIconComponent,
        SteppedProgressBarComponent,
        ProductSubtitleComponent,
        ProductDressFormComponent,
        ProductTicketFormComponent,
        PcpTableByCustomerIdsDialogComponent,
        ProductPcpFormComponent,
        PcpRecipientsTableComponent,
        PcpTableByOrderIdComponent,
        ShoppingCartChallengesComponent,
        ShoppingCartCouponsComponent,
        ItemMetadataTicketComponent,
        ItemMetadataPcpComponent,
        ProductConfigurableSelectorComponent,
        ProductImageComponent,
        ProductImageRibbonsComponent,
        ProductVideoComponent,
        StartKitAddBundleComponent,
        StartKitShowBundleComponent,
        ChatSupportComponent,
        CategoryComponent,
        StartKitPreviewDialogComponent,
        ProductOutOfStockComponent,
        ProductErrorMessageComponent,
        ProductTermsComponent,
        ProductViewComponent,
        FormErrorMessageComponent,
        FlagCountryImage,
        FlagLanguageImage,
        ItemMetadataDressComponent,
        FinalPaymentsComponent,
        ProductsLayoutSwitchComponent,
        ProductsListComponent,
        ProductColorDotComponent,
        ProductStockQuantityComponent,
        ProductDynamicQuantitySelectorComponent,
        MailTemplateComponent,
        ProductNameComponent,
        ProductCountComponent,
        ActivationOrderComponent,
        OrderConfirmationEmailFormComponent,
        ProductDressValuesComponent,
        CmsCouponsComponent,
        CouponsPageComponent,
        CurrentDiscountsPageComponent,
        CouponComponent,
        StockMonitorButtonComponent,
        ShowMoreComponent,
        ProductPcpFormOrderUpdateComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatMenuModule,
        MatSnackBarModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatDialogModule,
        MatTooltipModule,
        PortalModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDatepickerModule,
        MatButtonModule,
        MatDividerModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        ProductsGridComponent,
        ProductsFilterComponent,
        CmsPageAreaComponent,
        CurrencyPipe,
        ButtonComponent,
        FavouriteButtonComponent,
        MatSidenavModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatRadioModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatButtonModule,
        DateTimePickerComponent,
        DatePickerComponent,
        ShoppingCartComponent,
        DataTableComponent,
        QuantitySelectorComponent,
        ColorPickerComponent,
        ModelPickerComponent,
        PagedDataTableComponent,
        ProductDialogComponent,
        ProductGalleryComponent,
        ProductPriceComponent,
        BreadcrumbsComponent,
        ProgressBarComponent,
        SteppedProgressBarComponent,
        ProductSubtitleComponent,
        ProductDressFormComponent,
        ProductTicketFormComponent,
        PcpTableByCustomerIdsDialogComponent,
        ProductPcpFormComponent,
        PcpRecipientsTableComponent,
        PcpTableByOrderIdComponent,
        ChallengesComponent,
        ChatSupportComponent,
        CategoryComponent,
        ShoppingCartCouponsComponent,
        ProductOutOfStockComponent,
        ProductErrorMessageComponent,
        ProductTermsComponent,
        ProductViewComponent,
        FormErrorMessageComponent,
        FinalPaymentsComponent,
        ProductsLayoutSwitchComponent,
        MailTemplateComponent,
        FlagCountryImage,
        FlagLanguageImage,
        ActivationOrderComponent,
        RecommendedProductsComponent,
        ProductNameComponent,
        ProductDressValuesComponent,
        CouponComponent,
        StockMonitorButtonComponent,
        ShowMoreComponent,
        ProductPcpFormOrderUpdateComponent,
    ],
})
export class SharedModule {}
