<div>

    <button
        mat-icon-button
        (click)="select('grid')"
        [class.active]="layout==='grid'"
    >
        <i class="fa fa-th-large"></i>
    </button>
    <button
        mat-icon-button
        (click)="select('list')"
        [class.active]="layout==='list'"
    >
        <i class="fa fa-th-list"></i>
    </button>

</div>
