<div class="left-column">
    <div class="shopping-item-image">
        <a *ngIf="!shouldPopupProduct(item)" class="image" #imageLink [routerLink]="'/product/'+item.product_id" (click)="closeShoppingCart()">
            <nc-product-image [item]="item"></nc-product-image>
        </a>
        <a *ngIf="shouldPopupProduct(item)" class="image" #imageLink (click)="popupProductInfo(item)">
            <nc-product-image [item]="item"></nc-product-image>
        </a>
    </div>
</div>
<div class="right-column" *ngIf="!isSkippedReward()">
    <a class="name" *ngIf="shouldDisplayName() && !shouldPopupProduct(item)" [routerLink]="'/product/'+item.product_id" (click)="closeShoppingCart()">{{item.name}}</a>
    <a class="name" *ngIf="shouldDisplayName() && shouldPopupProduct(item)" (click)="popupProductInfo(item)">{{item.name}}</a>
    <div *ngIf="item.coupon_id" class="name">
        <i class="fal fa-tag ng-star-inserted"></i>
        {{item.campaignName}}
    </div>

    <nc-product-subtitle [product]="item.product"></nc-product-subtitle>
    <div class="attributes">
        <nc-product-configurable-selector *ngIf="item.rootProduct.type === 'configurable'" [item]="item"></nc-product-configurable-selector>
        <div *ngIf="canViewQuantity()" class="quantity-selector">
            <span class="quantity">{{('shopping-cart.pieces' | translate: {value: item.quantity})}}</span>
        </div>
        <div *ngIf="canChangeQuantity()" class="quantity-selector">
            <mat-spinner class="quantity-spinner" *ngIf="quantityPending" diameter="16"></mat-spinner>
            <button
                class="quantity"
                [disabled]="removePending"
                [matMenuTriggerFor]="quantityMenu"
                *ngIf="!quantityPending">
                {{('shopping-cart.pieces' | translate: {value: item.quantity})}}
            </button>
            <mat-menu #quantityMenu="matMenu">
                <button
                    mat-menu-item
                    *ngFor="let quantityOption of quantityOptions; let index = index"
                    (click)="selectQuantity(index + 1)">
                    {{index + 1}}
                </button>
            </mat-menu>
        </div>
        <div *ngIf="item.bonus_campaign_id || item.bonus_with_levels_campaign_id" class="bonus-campaign">
            <i class="fal fa-award ng-star-inserted"></i>
            {{item.campaignName}}
        </div>
        <div *ngIf="item.isChallengeReward" class="bonus-campaign">
            <i class="fal fa-trophy ng-star-inserted"></i>
            {{item.campaignName}}
        </div>
        <nc-item-metadata-ticket *ngIf="item.product.type === 'ticket'" [item]="item"></nc-item-metadata-ticket>
        <nc-item-metadata-dress *ngIf="item.product.type === 'dress'" [item]="item"></nc-item-metadata-dress>
        <nc-item-metadata-pcp *ngIf="item.product.type === 'pcp'" [item]="item"></nc-item-metadata-pcp>
        <nc-product-price *ngIf="shouldDisplayPrice()" [cartItem]="item"></nc-product-price>
        <nc-product-image-ribbons
            [product]="item.product"
            [listView]="true"
        ></nc-product-image-ribbons>
        <div class="disable-checkout-reasons" *ngIf="item.disableCheckout">
            <div *ngIf="item.disableCheckoutReasons.isOutOfStock">
                <ng-container [ngSwitch]="item.product.statusCode">
                    <form-error-message *ngSwitchCase="6">{{'product.sold-out.limited-edition' | translate}}</form-error-message>
                    <form-error-message *ngSwitchCase="7">{{'product.sold-out.under-transition' | translate}}</form-error-message>
                    <form-error-message *ngSwitchCase="8">{{'product.sold-out.phase-out' | translate}}</form-error-message>
                    <form-error-message *ngSwitchDefault>{{'product.sold-out' | translate}}</form-error-message>
                </ng-container>
            </div>
            <form-error-message *ngIf="item.disableCheckoutReasons.isQuantityUnavailable">{{'product.requested-quantity-exceeds-available-stock' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isPurchaseRequirementNotMet">
                <a href="{{getRequiredPurchaseProductUrl()}}">{{('product.requires-purchase-of-other-product' | translate: { sku: item.product?.requiredPurchaseProduct?.sku })}}</a>
            </form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isTotalLimitExceeded">{{'product.requested-quantity-exceeds-total-limit' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isOrderLimitExceeded">{{'product.requested-quantity-exceeds-order-limit' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isActivatingOrderRequired">{{'product.requires-activating-order' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isConfigurationNotSelected">{{'product.requires-product-select' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isNotPublished">{{'product.requires-product-published' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isBonusCampaignMaxCountExceeded">{{'product.requires-correct-bonus-campaign-count' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isEmailRegisteredForEvent">{{'product.requires-unique-email-for-event' | translate}}</form-error-message>
            <form-error-message *ngIf="item.disableCheckoutReasons.isRequiredEmailNotGivenForEvent">{{'product.requires-email-for-event' | translate}}</form-error-message>
        </div>
        <div class="important-information" *ngIf="item.product.importantInfo">{{item.product.importantInfo}}</div>
    </div>
</div>
<div class="right-column" *ngIf="isSkippedReward()">
    <a class="name">{{item.name}}</a>
    <div class="bonus-campaign">
        <i class="fal fa-trophy ng-star-inserted"></i>
        {{item.campaignName}}
    </div>
    <div class="skipped-reward-message" *ngIf="!item.price">
        <!-- Product is free and a coupon will be created (User has removed product) -->
        <p *ngIf="!item.challengeNoCoupon">{{('shopping-cart.skipped-reward' | translate)}}</p>
        <!-- Product is free (User has removed product) -->
        <p *ngIf="item.challengeNoCoupon">{{('shopping-cart.skipped-reward-no-coupon' | translate)}}</p>
        <button
            nc-button
            (click)="addSkippedChallengeReward()"
            buttonAppearance="plain"
            buttonSize="small"
            [buttonPending]="removePending"
            [buttonText]="('shopping-cart.add-skipped-reward' | translate)"
        ></button>
    </div>
    <div class="skipped-reward-message" *ngIf="item.price">
        <button
            nc-button
            (click)="addSkippedChallengeReward()"
            buttonAppearance="standard"
            buttonSize="small"
            [buttonPending]="removePending"
            [buttonText]="('shopping-cart.reward-add' | translate)"
        ></button>
        <!-- Product is NOT free but a coupon will be created (Default for new rewards) -->
        <p *ngIf="!item.challengeNoCoupon">{{('shopping-cart.reward-not-selected' | translate)}}</p>
        <!-- Product is NOT free (Default for new rewards) -->
        <p *ngIf="item.challengeNoCoupon"><!-- Not needed --></p>
    </div>
</div>

<button
    *ngIf="canRemove()"
    nc-button
    class="remove-button"
    buttonAppearance="plain"
    buttonIcon="times"
    [buttonPending]="removePending"
    (click)="removeProduct()">
</button>
