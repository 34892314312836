<div [formGroup]="parentForm">
    <div formGroupName="metaData">
        <div class="loading-customers" *ngIf="pending">
            <mat-spinner class="button-spinner" diameter="24"></mat-spinner>
        </div>
        <div *ngIf="!pending" class="pcp-recipients">

            <mat-form-field class="tag-select" *ngIf="tags.length > 0" >
                <mat-label>{{'product.pcp.select-tag' | translate}}</mat-label>
                <mat-select (ngModelChange)="onTagSelect($event)" [ngModel]="selectedTag" [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let option of tags" [value]="option.value">{{option.label}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="table-controls">
                <h4>{{'product.pcp.select-recipients' | translate}}</h4>
                <div class="pagination" *ngIf="pageCustomers">
                    <span>{{('product.pcp.recipient-form.pagination' | translate: {start: start + 1, end: end, total: total})}}</span>
                    <span>
                    <button
                        nc-button
                        buttonAppearance="plain"
                        buttonIcon="chevron-left"
                        [disabled]="page === 0"
                        (click)="previousPage($event)"
                    ></button>
                    <button
                        nc-button
                        buttonAppearance="plain"
                        buttonIcon="chevron-right"
                        [disabled]="page === pageCount"
                        (click)="nextPage($event)"
                    ></button>
                </span>
                </div>
            </div>
            <div class="table-container">
                <table>
                    <tr>
                        <th><mat-checkbox class="check-all" [checked]="allChecked" (change)="toggleCheckAll()"></mat-checkbox></th>
                        <th>{{'consumer.first-name' | translate}}</th>
                        <th>{{'consumer.last-name' | translate}}</th>
                        <th>{{'consumer.phone' | translate}}</th>
                        <th>{{'consumer.address' | translate}}</th>
                        <th>{{'consumer.zip-code' | translate}}</th>
                        <th>{{'consumer.city' | translate}}</th>
                        <th class="text-center">{{'consumer.language' | translate}}</th>
                        <th class="text-center">{{'consumer.country' | translate}}</th>
                    </tr>
                    <tr *ngFor="let customer of pageCustomers" class="{{customer.is_disabled ? 'disabled' : ''}}">
                        <td>
                            <mat-checkbox *ngIf="!customer.is_disabled && !customer.is_in_previous_order" [checked]="isSelected(customer)" (change)="toggle(customer)"></mat-checkbox>
                            <div *ngIf="customer.is_in_previous_order" class="in-previous-order-user" matTooltip="{{'product.pcp.recipient-form.in-previous-order-info' | translate}}" matTooltipPosition="below"><i class="fal fa-times"></i></div>
                            <div *ngIf="customer.is_disabled" class="incomplete-user" matTooltip="{{'product.pcp.recipient-form.incomplete-consumer-info' | translate}}" matTooltipPosition="below"><i class="fal fa-times"></i></div>
                        </td>
                        <td>{{customer.firstname}}</td>
                        <td>{{customer.lastname}}</td>
                        <td>{{customer.telephone}}</td>
                        <td>{{customer.street}}</td>
                        <td>{{customer.postcode}}</td>
                        <td>{{customer.city}}</td>
                        <td class="text-center">
                            <nc-flag-language-image languageCode="{{languageFromCode(customer.language)}}"></nc-flag-language-image>
                            <div *ngIf="customer.country_id && !customer.is_language_correct_for_country" class="language-not-correct-for-country">
                                <i class="fal fa-exclamation-circle"></i>
                                {{'product.pcp.recipient-form.language-not-correct-for-country' | translate}}
                            </div>
                        </td>
                        <td class="text-center">
                            <nc-flag-country-image countryCode="{{customer.country_id}}"></nc-flag-country-image>
                            <div *ngIf="customer.country_id && customer.is_country_different" class="different-country">
                                <i class="fal fa-exclamation-circle"></i>
                                {{'product.pcp.recipient-form.different-country' | translate}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="table-controls">
                <h4>{{'product.quantity' | translate}}: {{selectedCustomers?.length}}</h4>
                <div class="pagination" *ngIf="pageCustomers">
                    <span>{{('product.pcp.recipient-form.pagination' | translate: {start: start + 1, end: end, total: total})}}</span>
                    <span>
                        <button
                            nc-button
                            buttonAppearance="plain"
                            buttonIcon="chevron-left"
                            [disabled]="page === 0"
                            (click)="previousPage($event)"
                        ></button>
                        <button
                            nc-button
                            buttonAppearance="plain"
                            buttonIcon="chevron-right"
                            [disabled]="page === pageCount"
                            (click)="nextPage($event)"
                        ></button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

