import { Component, HostBinding, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MenuItem } from 'src/app/core/menu.service';
import { BackendService } from 'src/app/core/backend.service';
import { ACCOUNT_MENU } from '../account-menu-items';
import { AppConfig, ConfigService } from 'src/app/core/config.service';

@Component({
    selector: 'nc-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnChanges {

    constructor(
        private configService: ConfigService,
        private backendService: BackendService,
    ) { }

    accountMenu: MenuItem[] = ACCOUNT_MENU;
    markets;
    menuIsOpen = false;
    childSubmenuIsOpen = false;
    logoUrl = this.backendService.getIntranetUrl('/');

    @Input() menu: MenuItem[] = [];
    @Input() config: AppConfig;

    @HostBinding('class.menu-is-open') get menuIsOpenClass() {
        return this.menuIsOpen;
    }

    @HostBinding('class.child-submenu-is-open') get childSubmenuIsOpenClass() {
        return this.childSubmenuIsOpen;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && changes.config.currentValue) {
            this.markets = {
                data: { title: 'Byt språk' },
                limit_admin: false,
                children: changes.config.currentValue.markets.map((market) => ({
                    data: { title: market.languageLabel },
                    children: [],
                })),
            };
        }
    }

    toggleMenu() {
        this.menuIsOpen = !this.menuIsOpen;
    }

    getAccountMenu(): MenuItem[] {
        const isAdmin = this.configService.snapshot.config.isAdmin;

        return this.accountMenu.filter((item) => {
            // Show admin item if user is admin.
            if (isAdmin) {
                return true;
            }

            // Hide item if admin limited.
            return !item.limit_admin;
        });
    }
}
