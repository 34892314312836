import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'src/app/core/menu.service';

@Component({
    selector: 'nc-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent {

    activeIndex = -1;

    @Input() menu: MenuItem;

    @Output() selectItem: EventEmitter<MenuItem> = new EventEmitter();

    @HostBinding('class.expanded') get selectedItemClass() {
        return this.activeIndex > -1;
    }

    getBackgroundImage(item: MenuItem) {
        return item.image ? `url(${item.image.thumbnail.src})` : '';
    }

    onClickItem(item: MenuItem, index: number) {
        if (item.children.length) {
            this.activeIndex = index;
        } else {
            this.selectItem.emit(item);
        }
    }


    isCurrentPath(item: MenuItem) {
        return item.data.url === window.location.pathname
            || (window.location.pathname).startsWith(item.data.url + '/');
    }
}
