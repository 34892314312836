import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Product, ProductsAggregations, ProductService } from '../../core/product.service';
import { BreadCrumbsLink } from '../breadcrumbs/breadcrumbs.component';
import { SubSink } from 'subsink';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../core/config.service';
import { SnackBarService } from '../../core/snack-bar.service';
import { PersistedFilterService } from '../../core/persisted-filter.service';
import { catchError, flatMap, switchMap } from 'rxjs/operators';
import { ProductLayoutService } from '../../core/product-layout.service';

interface SortableOrderInterface {
    index: number;
    value: object;
    viewValue: string;
}

@Component({
    selector: 'nc-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

    @Input() baseFilters: any[];
    @Input() categoryName: string;
    @Input() breadcrumbs: BreadCrumbsLink[];
    @Input() path: string;
    @Input() noResultText?: string;
    @Input() baseFilterObservable?: Observable<any>;
    @Input() popularitySort?: any;
    @Input() queryString?: string;
    @Input() content?: any[];
    @Output() onLoaded: EventEmitter<any> = new EventEmitter();

    public aggregations: ProductsAggregations = null;
    public products: Product[] = null;
    public activeFilters: any[] = [];
    public total = 0;
    public mobileFilterIsOpen = false;
    public loadMorePending = true;
    protected from = 0;
    protected page = 1;
    protected perPage = 24; // Could be placed in config
    protected subs = new SubSink();
    protected filtersChanges: Subject<any[]> = new Subject();
    public layout: string;


    @Input() sortableOrder?: SortableOrderInterface[] = [
        {index: 1, value: {sortPriority: 'desc'}, viewValue: 'sort.popular'},
        {index: 2, value: {'name.keyword': 'asc'}, viewValue: 'sort.name-asc'},
        {index: 3, value: {'name.keyword': 'desc'}, viewValue: 'sort.name-desc'},
        {index: 4, value: {price: 'asc'}, viewValue: 'sort.price-asc'},
        {index: 5, value: {price: 'desc'}, viewValue: 'sort.price-desc'}
    ];

    public activeSort: any;

    constructor(
        protected productService: ProductService,
        protected route: ActivatedRoute,
        protected configService: ConfigService,
        protected snackBarService: SnackBarService,
        protected persistedFilterService: PersistedFilterService,
        protected productLayout: ProductLayoutService,
    ) {
        this.activeFilters = persistedFilterService.activeFilters;
        this.page = persistedFilterService.page;
    }

    getBaseFilterObservable(): Observable<any> {
        if (!!this.baseFilterObservable) {
            return this.baseFilterObservable;
        }

        return of(null);
    }

    ngOnInit() {
        this.activeSort = this.getDefaultSort();
        this.subs.sink = this.configService.localeChanges.subscribe((locale) => {
            if (locale) {
                this.getBaseFilterObservable().pipe(flatMap((result) => {
                    if (result) {
                        this.baseFilters = [{property: 'id', one: result}];
                    }

                    return this.productService.getProducts({
                        autocomplete: this.queryString,
                        filters: [
                            ...this.baseFilters,
                        ],
                        size: this.page ? this.page * this.perPage : this.perPage,
                        sort: this.activeSort.value
                    });
                })).subscribe((result) => {
                    this.aggregations = result.aggregations;
                    this.from = result.from + result.size;
                    this.total = result.total;

                    if (this.persistedFilterService.activeFilters.length) {
                        this.activeFilters = this.persistedFilterService.activeFilters;
                        this.filtersChanges.next(undefined);
                    } else {
                        this.products = result.items;
                    }

                }, () => {}, () => {
                    this.triggerLoaded();
                });
            }
        });
        this.subs.sink = this.filtersChanges.pipe(switchMap(() => {
            const filters = [
                ...this.activeFilters,
                ...this.baseFilters,
            ];
            return this.productService.getProducts({
                autocomplete: this.queryString,
                filters,
                sort: this.activeSort.value,
                size: this.page * this.perPage
            }).pipe(catchError(() => {
                this.snackBarService.error();
                return EMPTY;
            }));
        })).subscribe((result) => {
            this.products = result.items;
            this.from = result.from + result.size;
            this.total = result.total;

            this.persistFilters();
            this.triggerLoaded();
        });
        this.subs.sink = this.productLayout.onChange().subscribe((v) => this.layout = v);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public filtersChanged(event: any) {
        this.page = 1; // Reset page count when filter is edited
        this.activeFilters = event;
        this.filtersChanges.next(undefined);
    }

    public loadMoreProducts() {
        this.loadMorePending = true;
        this.productService.getProducts({
            autocomplete: this.queryString,
            filters: [
                ...this.activeFilters,
                ...this.baseFilters,
            ],
            from: this.from,
            sort: this.activeSort.value,
        }).subscribe(
            (result) => {
                this.products = [...this.products, ...result.items];
                this.from = result.from + result.size;
                this.page = Math.floor(result.from / this.perPage) + 1;
                this.total = result.total;

                this.persistFilters();
            },
            () => {},
            () => {
                this.loadMorePending = false;
                this.triggerLoaded();
            }
        );
    }

    sortChange(sort: any) {
        this.filtersChanges.next(undefined);
    }

    protected persistFilters() {
        this.persistedFilterService.setActiveFilters(this.activeFilters);
        this.persistedFilterService.setActiveSort(this.activeSort.index);
        this.persistedFilterService.setPage(this.page);
        this.persistedFilterService.applyFilterToRoute();
    }

    protected getDefaultSort() {
        const sortItem = this.sortableOrder.find((item) => {
            return item.index === this.persistedFilterService.activeSort;
        });

        return sortItem || this.sortableOrder[0];
    }

    protected triggerLoaded(): void {
        this.loadMorePending = false;
        this.onLoaded.emit();
    }
}
