import {Component, Input} from '@angular/core';
import {Product} from '../../../core/product.service';

@Component({
    selector: 'nc-product-stock-quantity',
    templateUrl: './product-stock-quantity.component.html',
    styleUrls: ['./product-stock-quantity.component.scss']
})
export class ProductStockQuantityComponent {

    @Input() product: Product;

    shouldDisplayStockQuantity(): boolean {
        return this.product.stockQuantity !== undefined
            && this.product.stockQuantity !== null;
    }

}
