import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartItem } from "src/app/core/shopping-cart-data.service";

@Component({
    selector: 'nc-item-metadata-dress',
    templateUrl: './item-metadata-dress.component.html',
    styleUrls: ['./item-metadata-dress.component.scss']
})
export class ItemMetadataDressComponent {

    @Input() item: ShoppingCartItem;

    modelLabel() {
        const value = this.item.meta_data.model;

        return this.item.product.dressOptions.models.find(option => option.id === value)?.label || value
    }

    sizeLabel() {
        const value = this.item.meta_data.size;

        return this.item.product.dressOptions.sizes.find(option => option.id === value)?.label || value
    }

}
