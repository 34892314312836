import { Component, OnInit } from '@angular/core';
import { AppConfig, ConfigService } from "../core/config.service";

// Global augmentation (to remove error messages)
declare global {
    interface Window {
        Tawk_API: object;
    }
}

@Component({
    selector: 'nc-chat-support',
    template: '',
})
export class ChatSupportComponent implements OnInit {

    constructor(
        private configService: ConfigService,
    ) {
    }

    ngOnInit() {
        const config = this.configService.snapshot.config;
        this.createChatSupportBox(config);
    }

    createChatSupportBox(config: AppConfig) {
        const user = config.user;
        const customer = config.customer;

        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        Tawk_API.visitor = {
            name: `${user.name} ${user.number}`,
            email: customer.email,
        };
        Tawk_API.onLoad = function(){
            Tawk_API.setAttributes({
                'Namn': user.name,
                'Konsultnummer': user.number,
                'E-post': customer.email,
                'Titel': user.title,
                'Uno': 'http:\/\/mkweb.mk.local\/uno\/index.php?ftgnr=' + user.number,
            });

            const newElement = window['document'].body.lastChild as any;
            newElement.style.setProperty('z-index', '2', 'important');
        };

        // Make object global to make the settings (visitor/attributes) work.
        window.Tawk_API = Tawk_API;

        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.getChatUrl(config.currentMarket);
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1, s0);

        // Hide widget when printing.
        window.addEventListener("beforeprint", () => {
            if (Tawk_API) {
                Tawk_API.hideWidget();
            }
        });

        // Show widget after printing.
        window.addEventListener("afterprint", () => {
            if (Tawk_API) {
                Tawk_API.showWidget();
            }
        });
    }

    public getChatUrl(market: string) {
        const chatUrls = {
            'fi': 'https://embed.tawk.to/56e92949e076c43a261cc9db/1advd16ha',
            'no': 'https://embed.tawk.to/56e928eb50c180882623e741/default',
            'se': 'https://embed.tawk.to/56b092767c11f1ac706118ec/1aah0hgod',
            'sefi': 'https://embed.tawk.to/56b092767c11f1ac706118ec/1aah0hgod',
        };

        return chatUrls[market.toLocaleLowerCase()];
    }
}
