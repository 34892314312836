import { Component } from '@angular/core';
import { ProductsGridComponent } from '../products-grid/products-grid.component';
import { Product } from '../../core/product.service';
import {getMaxQuantity, shouldDisplayCampaignAddButton} from '../../helpers/product-helpers';
import { isRequiredPurchaseFulfilled } from '../../helpers/requirements';

@Component({
    selector: 'nc-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent extends ProductsGridComponent {
    getMaxQuantity = getMaxQuantity;
    isRequiredPurchaseFulfilled = isRequiredPurchaseFulfilled;
    shouldDisplayCampaignAddButton = shouldDisplayCampaignAddButton;

    shouldDisplayQuantitySelector(product: Product) {
        if (product.disableAddToCart) {
            return false;
        }

        if (product.isDiscontinued) {
            return false;
        }

        if (!this.isRequiredPurchaseFulfilled(product)) {
            return false;
        }

        return true;
    }

}
