export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function redirectWithoutReferrer(url) {
    var node = document.createElement("div");

    node.innerHTML = `<a href="${url}" rel="noreferrer" id="autoclick">Link</a>`;
    document.body.appendChild(node);
    document.getElementById('autoclick').click();

    setTimeout(() => {
        document.body.removeChild(node);
    }, 100);
}
