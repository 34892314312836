import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TranslateLoader } from '@ngx-translate/core';

import { BackendService } from './backend.service';

export class LanguageLoader implements TranslateLoader {

    constructor(
        private backendService: BackendService
    ) { }

    public getTranslation(locale: string): Observable<any> {
        return this.backendService.get('strings/' + locale);
    }
}
