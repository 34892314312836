<div [formGroup]="parentForm" *ngIf="displayTicketInformation(product)">
    <div formGroupName="metaData">
        <h4>{{'product.ticket.visitor-information' | translate}}</h4>
        <div *ngIf="!product.hideName" class="form-group">
            <mat-form-field>
                <mat-label>{{'product.ticket.visitor-name' | translate}}</mat-label>
                <input matInput formControlName="name" name="name" [required]="product.requireName">
            </mat-form-field>
        </div>

        <div
            *ngIf="!product.hideEmail || product.requireEmail"
            class="form-group"
            [class.form-group-has-error]="getEmailHasError()"
        >
            <mat-form-field>
                <mat-label>{{'product.ticket.visitor-email' | translate}}</mat-label>
                <input matInput formControlName="email" name="email" [required]="product.requireEmail">
                <mat-error *ngIf="getEmailError('emailIsRegistered')">
                    {{ 'product.requires-unique-email.already-registered' | translate }}
                </mat-error>
                <mat-error *ngIf="getEmailError('emailIsConsultant')">
                    {{ 'product.requires-unique-email.consultant' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!product.hideMobile || product.requireMobile" class="form-group">
            <mat-form-field>
                <mat-label>{{'product.ticket.visitor-mobile' | translate}}</mat-label>
                <input matInput formControlName="mobile" name="mobile" [required]="product.requireMobile">
            </mat-form-field>
        </div>

        <div *ngIf="!product.hideFoodPreferences" class="form-group">
            <mat-form-field>
                <mat-label>{{'product.ticket.visitor-food-preference' | translate}}</mat-label>
                <input matInput formControlName="food" name="food">
            </mat-form-field>
        </div>

        <div formGroupName="days" *ngIf="product.days?.length">
            <h4>{{'product.ticket.days' | translate}}</h4>
            <div *ngFor="let day of product.days">
                <mat-checkbox
                    [formControlName]="day.id"
                >
                    {{day.label}}
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>

