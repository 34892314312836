<p class="sold-out-text" *ngIf="product.isOutOfStock">
    <ng-container [ngSwitch]="product.statusCode">
        <nc-product-error-message *ngSwitchCase="6">
            {{'product.sold-out.limited-edition' | translate}}
        </nc-product-error-message>
        <nc-product-error-message *ngSwitchCase="7">
            {{'product.sold-out.under-transition' | translate}}
        </nc-product-error-message>
        <nc-product-error-message *ngSwitchCase="8">
            {{'product.sold-out.phase-out' | translate}}
        </nc-product-error-message>
        <nc-product-error-message *ngSwitchDefault>
            {{'product.sold-out' | translate}}
        </nc-product-error-message>
    </ng-container>
</p>
