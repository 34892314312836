import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BackendService } from "src/app/core/backend.service";
import { tap } from "rxjs/operators";
import { Challenge, ChallengeService, ChallengeStep } from "src/app/core/challenge.service";
import * as moment from 'moment';
import { ConfigService } from "src/app/core/config.service";
import { ShoppingCartService } from "src/app/shared/shopping-cart/shopping-cart.service";
import { SubSink } from "subsink";

@Component({
    selector: 'nc-challenges',
    templateUrl: './challenges.component.html',
    styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent implements OnInit, OnDestroy {

    @Input() collapse: boolean = false;
    @Input() icons: boolean = true;

    subs = new SubSink();
    challenges: Challenge[] = null;

    constructor(
        private challengeService: ChallengeService,
        private configService: ConfigService,
        private shoppingCartService: ShoppingCartService,
    ) {
    }

    ngOnInit(): void {
        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe((shoppingCartCollection) => {
            let shoppingCart = shoppingCartCollection.getCart('default');
            if (shoppingCart) {
                this.challenges = shoppingCart.challenges;
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    currentStep(challenge: Challenge): ChallengeStep | null {
        return this.challengeService.currentStep(challenge);
    }

    nextStep(challenge: Challenge): ChallengeStep | null {
        return this.challengeService.nextStep(challenge);
    }

    monthKey(date: string) {
        return 'month.' + moment(date).format('M'); // to be translated
    }

    year(date: string) {
        return moment(date).format('Y ');
    }

    formatDate(date: string) {
        return moment(date).format('Y-MM-DD');
    }

    showEndDate(challenge: Challenge) {
        if (!challenge.end_date) {
            return false;
        }

        return moment(challenge.start_date).format('YM') !== moment(challenge.end_date).format('YM');
    }

    hasSumRequirement(challenge: Challenge) {
        for (const step of challenge.steps) {
            if (step.requiredSum || step.requiredSum2) {
                return true;
            }
        }

        return false;
    }

    hasRecruitmentRequirement(challenge: Challenge): boolean {
        for (const step of challenge.steps) {
            if (step.requiredRecruitments || step.requiredRecruitments2) {
                return true;
            }
        }

        return false;
    }

}
