<div class="mobile-header">
    <a [href]="logoUrl" class="logo">MK Intranet</a>
    <button class="toggle-button" (click)="toggleMenu()">{{'mobile.menu' | translate}}</button>
</div>
<div class="container">
    <nav>
        <nc-menu-item *ngFor="let item of menu" [item]="item" (openChange)="childSubmenuIsOpen=$event"></nc-menu-item>
    </nav>
    <nav class="mobile-user-menu">
        <span class="name">{{'mobile.user-name' | translate}} {{config?.user.name}}</span>
        <nc-menu-item *ngIf="markets" [item]="markets" (openChange)="childSubmenuIsOpen=$event"></nc-menu-item>
        <nc-menu-item *ngFor="let item of getAccountMenu()" [item]="item" [translateTitle]="true" (openChange)="childSubmenuIsOpen=$event"></nc-menu-item>
    </nav>
</div>
