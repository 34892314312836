import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from "src/app/shared/dialog/dialog.service";
import {StartKitPreviewDialogComponent} from "src/app/shared/start-kit/start-kit-preview-dialog/start-kit-preview-dialog.component";
import {StartKitService} from "../start-kit.service";

@Component({
    selector: '[nc-start-kit-show-bundle], [nc-startkit-show-bundle]',
    templateUrl: './start-kit-show-bundle.component.html',
    styleUrls: ['./start-kit-show-bundle.component.scss']
})
export class StartKitShowBundleComponent implements OnInit {

    @Input() bundlenumber: string;

    items: { sku: string, increment: number }[] = [];

    constructor(
        private dialogService: DialogService,
        private startKitService: StartKitService,
    ) {
    }

    ngOnInit(): void {
        this.startKitService.getContent(parseInt(this.bundlenumber)).then((content) => {
            this.items = content;
        })
    }

    onClick($event) {
        this.dialogService.open(StartKitPreviewDialogComponent, {products: this.items});
    }

}
