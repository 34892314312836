import { Component, Input } from '@angular/core';
import { Coupon, CouponService } from '../../core/coupon.service';

@Component({
    selector: 'nc-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss']
})
export class CouponComponent {

    @Input() coupon?: Coupon;

    @Input() disabled = false;

    constructor(
        protected couponService: CouponService,
    ) {
    }

    applyCoupon(coupon: Coupon): void {
        this.couponService.applyCoupon(coupon);
    }

    shallShowRequirementAsLabel(shallShow: boolean) {
        return shallShow && this.disabled === true;
    }
}
