<div
    *ngIf="this.coupon"
    class="coupon"
    [class.coupon-disabled]="this.disabled === true"
>
    <div class="coupon-inner">
        <div class="coupon-name">{{coupon.coupon_name}}</div>
        <div *ngIf="coupon.price !== 0" class="coupon-price">{{coupon.price | ncCurrency}}</div>
        <div *ngIf="coupon.product?.name" class="coupon-product-name" title="{{'my-account.coupons.title-product-name' | translate}}">{{coupon.product.name}}</div>
        <div class="flex-row flex-spread">
            <div *ngIf="coupon.quantity" title="{{'my-account.coupons.title-quantity' | translate}}">{{('my-account.coupons.quantity' | translate: {quantity: coupon.quantity})}}</div>
            <div *ngIf="coupon.sku" title="{{'my-account.coupons.title-sku' | translate}}">{{coupon.sku}}</div>
        </div>
    </div>
    <button [ngSwitch]="true" [disabled]="disabled == true" (click)="applyCoupon(coupon)">
        <span *ngSwitchCase="shallShowRequirementAsLabel(coupon.requires_activating_order)">{{'my-account.coupons.requires-activating-order' | translate}}</span>
        <span *ngSwitchCase="shallShowRequirementAsLabel(coupon.minimum_sum > 0)">{{('my-account.coupons.minimum-sum' | translate: { sum: coupon.minimum_sum | ncCurrency })}}</span>
        <span *ngSwitchDefault>{{'shopping-cart.use-coupon' | translate}}</span>
    </button>
    <div class="pending" *ngIf="coupon.pending">
        <mat-spinner diameter="20"></mat-spinner>
    </div>
</div>
