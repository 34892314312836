<div>
    <div *ngIf="item.meta_data?.name" class="field">
        <strong>{{'product.ticket.visitor-name' | translate}}: </strong>
        <span>{{item.meta_data?.name}}</span>
    </div>

    <div *ngIf="item.meta_data?.email" class="field">
        <strong>{{'product.ticket.visitor-email' | translate}}: </strong>
        <span>{{item.meta_data?.email}}</span>
    </div>

    <div *ngIf="item.meta_data?.mobile" class="field">
        <strong>{{'product.ticket.visitor-mobile' | translate}}: </strong>
        <span>{{item.meta_data?.mobile}}</span>
    </div>

    <div *ngIf="item.meta_data?.food" class="field">
        <strong>{{'product.ticket.visitor-food-preference' | translate}}: </strong>
        <span>{{item.meta_data?.food}}</span>
    </div>

    <div *ngIf="item.meta_data?.days" class="field">
        <div *ngFor="let day of getDays(item)" class="day">
            <strong>{{day}}</strong>
        </div>
    </div>
</div>
