<label *ngIf="label">{{label}}</label>
<div class="picker">
    <div class="group" *ngFor="let group of options">
        <span class="group-name" *ngIf="options.length > 1">{{group.groupName}}</span>
        <div class="colors">
            <ul>
                <li *ngFor="let choice of group.choices">
                    <button
                        #buttonElement
                        [matTooltip]="choice.label"
                        type="button"
                        class="color"
                        [ngClass]="{selected: isSelected(choice), 'pink-sale':  choice.product?.pinkSale}"
                        (click)="selectChoice(choice)">
                        <span [ngStyle]="{backgroundColor: choice.color}">{{choice.label}}</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>

<div *ngIf="hasPinkSaleOptions(options)" class="pink-sale-explanation"><img src="/assets/images/pink-sale-top-right-corner.svg" width="10" height="10" alt=""> = Pink sale</div>
