import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductOption } from "src/app/core/product.service";
import { ControlContainer, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { conditionallyRequiredValidator } from "src/app/helpers/validators";
import { SubSink } from "subsink";
import { ConfigService } from "src/app/core/config.service";

@Component({
    selector: 'nc-product-dress-form',
    templateUrl: './product-dress-form.component.html',
    styleUrls: ['./product-dress-form.component.scss']
})
export class ProductDressFormComponent implements OnInit {

    @Input() product: Product;

    subs = new SubSink();
    public parentForm: FormGroup;

    modelOptions: ProductOption[] = [];
    sizeOptions: ProductOption[] = [];

    // Let Angular inject the control container
    constructor(
        private controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        // Set our parentForm property to the parent control
        // (i.e. FormGroup) that was passed to us, so that our
        // view can data bind to it
        this.parentForm = <FormGroup>this.controlContainer.control;

        if (this.product.dressOptions?.models?.length) {
            this.modelOptions = this.product.dressOptions?.models;
        }
        if (this.product.dressOptions?.sizes?.length) {
            this.sizeOptions = this.product.dressOptions?.sizes;
        }

        // make a nested group
        this.parentForm.addControl('metaData', this.formBuilder.group({
            model: ['', this.modelOptions.length ? [Validators.required] : []],
            size: ['', this.sizeOptions.length ? [Validators.required] : []],
        }));

        // set default value
        if (this.modelOptions.length === 1) {
            this.parentForm.get('metaData').get('model').setValue(this.modelOptions[0].id);
        }
        if (this.sizeOptions.length === 1) {
            this.parentForm.get('metaData').get('size').setValue(this.sizeOptions[0].id);
        }
    }

}
