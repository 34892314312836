import { Component, Input } from '@angular/core';
import { ShoppingCartData } from 'src/app/core/shopping-cart-data.service';
import { Coupon } from '../../../core/coupon.service';

@Component({
    selector: 'nc-shopping-cart-coupons',
    templateUrl: './shopping-cart-coupons.component.html',
    styleUrls: ['./shopping-cart-coupons.component.scss']
})
export class ShoppingCartCouponsComponent {

    @Input() coupons: Coupon[] = [];
    @Input() displayAsGrid = false;
    @Input() disabled = false;

    @Input() set cart(cart: ShoppingCartData) {
        this.coupons = cart.coupons
            .filter((coupon) => !coupon.is_in_cart);
    }

    constructor(
    ) {
    }

}
