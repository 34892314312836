import { Component, Input, OnInit } from '@angular/core';

export interface BreadCrumbsLink {
    href: string;
    label: string;
    translate?: boolean;
}

@Component({
    selector: 'nc-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

    @Input() path: BreadCrumbsLink[];

    constructor() {
    }

}
