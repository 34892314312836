import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'nc-confirm-dialog',
    template: `
        <p>{{data}}</p>
        <div class="button-wrapper">
            <button nc-button class="color--white" [buttonText]="'dialog.close' | translate" mat-dialog-close></button>
            <button nc-button class="color--green" [buttonText]="'dialog.ok' | translate" (click)="ok()"></button>
        </div>
    `,
    styles: [`
        :host {
            display: block;
            padding: 30px 0;
            width: 600px;
            max-width: 100%;
            text-align: center;
        }
        p {
            margin: 0 0 30px 0;
        }
        .button-wrapper {
            display: inline-flex;
        }
    `],
})
export class ConfirmDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
    ) {}

    ok() {
        this.dialogRef.close({accept: true});
    }
}
