import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, EMPTY, throwError } from 'rxjs';
import { mergeMap, tap, catchError } from 'rxjs/operators';

import { ProductService } from 'src/app/core/product.service';
import { SnackBarService } from 'src/app/core/snack-bar.service';

export type QueueItem = () => Observable<number[]>;

@Injectable({
    providedIn: 'root'
})
export class FavouritesService {

    private favourites: BehaviorSubject<number[]> = new BehaviorSubject(null);
    private queue: Subject<QueueItem> = new Subject();

    constructor(
        private productService: ProductService,
        private snackBarService: SnackBarService,
    ) {
        this.productService.getFavourites().subscribe((response) => {
            this.favourites.next(response);
        });
        // This queue runs requests one at a time to make sure we don't get responses in the wrong order when sending multiple requests at the same time
        this.queue.pipe(
            mergeMap((queueItem) => {
                return queueItem().pipe(
                    tap((response) => {
                        this.favourites.next(response);
                    }),
                    // Allow user to try again if request fails
                    catchError(() => {
                        this.snackBarService.error();
                        return EMPTY;
                    }),
                );
            }, null, 1),
        ).subscribe();
    }

    public get favouritesChanges() {
        return this.favourites.asObservable();
    }

    public delete(productId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.productService.deleteFavourite(productId).pipe(
                    tap((response) => {
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public add(productId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.productService.addFavourite(productId).pipe(
                    tap((response) => {
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

}
