<div class="row">
    <div class="col-12 col-sm-6">
        <nc-product-gallery [slides]="slides"></nc-product-gallery>
    </div>
    <div class="col-12 col-sm-6">
        <nc-product-view
            [product]="product"
            [coupon]="coupon"
            [compactView]="compactView"
            [showActions]="showActions"
            (addToCart)="onAddToCart($event)"
            (changeVariation)="onChangeVariation($event)"
        ></nc-product-view>
        <a
            *ngIf="showActions"
            routerLink="/product/{{product.id}}"
            nc-button
            class="more-info-button"
            buttonAppearance="outlined"
            buttonColor="black"
            [buttonText]="'product.more-info' | translate"
            (click)="closeDialog()">
        </a>
    </div>
</div>
