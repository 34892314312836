import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from 'src/app/core/backend.service';
import { Coupon } from './coupon.service';
import { Product } from './product.service';
import { Challenge } from 'src/app/core/challenge.service';

export interface ShoppingCartData {
    id: number;
    type: string;
    items: ShoppingCartItem[];
    removed: ShoppingCartItem[];
    totals: {
        rows: {
            label: string;
            value: number;
            class: string;
            type: string;
            translate: boolean;
        }[];
        totalIncVat: number;
        sum: number;
        vat: number;
        resultBased: number;
    };
    quantity_total: number;
    shipping: Shipping;
    bonusCampaigns: BonusProductsCampaignData[];
    bonusCampaignsWithLevels: BonusProductsWithLevelsCampaignData[];
    challenges: Challenge[];
    coupons: Coupon[];
    couponsUnusable: Coupon[];
    discount: Discount;
    buyXPayYCampaigns: BuyXPayYCampaignData[];
    freeShippingCampaigns: FreeShippingCampaigns[];
    deliveryAddress: ShippingAddress;
    resultMonths: ResultMonths;
    payment: PaymentInfo;
    otherInfo?: string;
    disableCheckout: boolean;
    hasCheckedOut?: boolean;
    errors?: {
        translationKey: string;
        message: string;
    }[];
    isActivatingOrder: boolean;
}

export interface PaymentInfo {
    paymentMethod: string;
}

export interface ResultMonths {
    resultMonthSelected?: string;
    resultMonths: string[];
}

export interface FreeShippingCampaigns {
    progress: number;
    left: number;
    isAvailable: boolean;
    campaign: Campaign;
}

export interface BuyXPayYCampaignData {
    progress: number;
    left: number;
    isAvailable: boolean;
    campaign: Campaign;
    selectedProducts: any[];
    products: any[];
    progressType: string;
}

export interface Campaign {
    id: number;
    name: string;
    type: string;
    date_from: string;
    date_to: string;
    min_basket_sum: number;
    unlocked_message_text: string|null;
}

export interface Discount {
    isActivating: boolean;
    sum: number;
    left: number;
    steps: SteppedProgressStep[];
}

export interface SteppedProgressStep {
    completed: boolean;
    name: string;
    requiredSum: number;
    progress: number;
}

export interface BonusProductsCampaignData {
    allowMultiple: boolean;
    perTrigger: boolean;
    maxCount: number;
    progress: number;
    left: number;
    isAvailable: boolean;
    campaign: Campaign;
    selected: number[];
    selectedQuantity: number;
    products: any[];
}

export interface BonusProductsWithLevelsCampaignData {
    maxCount: number;
    progress: number;
    left: number;
    campaign: Campaign;
    selected: number[];
    selectedQuantity: number;
    products: any[];
    levels: SteppedProgressStep[];
}

export interface BonusProductsWithLevelsCampaignData {
    maxCount: number;
    progress: number;
    left: number;
    campaign: Campaign;
    selected: number[];
    selectedQuantity: number;
    products: any[];
}

export interface ShoppingCartItem {
    meta_data: any;
    id: number;
    quantity: number;
    price: number;
    newPrice: number|null;
    product: Product;
    product_id: number;
    child_id: number|null;
    bonus_campaign_id: number|null;
    bonus_with_levels_campaign_id: number|null;
    bonusCampaignProductPerTrigger?: boolean;
    coupon_id: number|null;
    challenge_step_id: number|null;
    isChallengeReward: boolean;
    images: any[];
    campaignName: string|null;
    name: string|null;
    skip_reward: boolean;
    disableCheckout: boolean;
    disableAddToCart: boolean;
    challengeNoCoupon: boolean|null;
    challengeNoCouponWarning: string|null;

    disableCheckoutReasons: {
        isPurchaseRequirementNotMet: boolean,
        isTotalLimitExceeded: boolean,
        isOrderLimitExceeded: boolean,
        isActivatingOrderRequired: boolean,
        isQuantityUnavailable: boolean,
        isOutOfStock: boolean,
        isConfigurationNotSelected: boolean,
        isNotPublished: boolean,
        isBonusCampaignMaxCountExceeded: boolean;
        isEmailRegisteredForEvent: boolean;
        isRequiredEmailNotGivenForEvent: boolean;
    };

    rootProduct: {
        sku: string;
        id: number;
        type: string;
        configurableChildren: Product[];
    };
}

// Structured data to be sent to the backend when updating, deleting or changing the quantity
export interface CartItemModificationData {
    productType: string;
    productId: number;
    quantity?: number;
    increment?: number; // only used on addItem and updateQuantity
    childId?: number;
    bonusCampaignId?: number;
    bonusWithLevelsCampaignId?: number;
    couponId?: number;
    metaData?: any;
}

export interface CartItemsModificationData {
    items: CartItemModificationData[];
}

export interface ShippingAddress {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    street?: string;
    zipCode?: string;
    city?: string;
    country?: string;
}

export interface Shipping {
    shippingOptions: ShippingOptions[];
    shippingOptionId: number;
    shippingClass: string;
    pickupLocation?: PickupLocation;
}

export interface ShippingOptions {
    id: number;
    country_code: string;
    name: string;
    shipping_price: number;
    display_price: string;
    labels?: {
        se: string;
        no: string;
        fi: string;
        sefi: string;
    };
}

export interface PickupLocation {
    locations: PickupLocationItem[];
    chosenLocation: number;
}

export interface PickupLocationItem {
    id: string;
    label: string;
}

type validateUniqueEmailForTicketReturnType = {
    isAllowed: boolean,
    error: string|'consultant'|'already_registered',
};

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartDataService {

    constructor(
        private backendService: BackendService,
    ) {
    }

    public getAllCarts(): Observable<ShoppingCartData[]> {
        return this.backendService.get('cart');
    }

    public addItem(data: CartItemModificationData): Observable<ShoppingCartData> {
        return this.backendService.put('cart/item', {
            product_type: data.productType,
            product_id: data.productId,
            increment: data.quantity,
            child_id: data.childId,
            bonus_campaign_id: data.bonusCampaignId,
            bonus_with_levels_campaign_id: data.bonusWithLevelsCampaignId,
            coupon_id: data.couponId,
            meta_data: data.metaData,
        });
    }

    public addItems(data: CartItemsModificationData): Observable<ShoppingCartData> {
        return this.backendService.put('cart/items', data);
    }

    public updateItem(data: CartItemModificationData): Observable<ShoppingCartData> {
        return this.backendService.put('cart/item', {
            product_type: data.productType,
            product_id: data.productId,
            quantity: data.quantity,
            increment: data.increment,
            child_id: data.childId,
            bonus_campaign_id: data.bonusCampaignId,
            bonus_with_levels_campaign_id: data.bonusWithLevelsCampaignId,
            coupon_id: data.couponId,
            meta_data: data.metaData,
        });
    }

    public deleteItem(data: CartItemModificationData): Observable<ShoppingCartData> {
        return this.backendService.put('cart/item', {
            product_type: data.productType,
            product_id: data.productId,
            quantity: 0,
            child_id: data.childId,
            bonus_campaign_id: data.bonusCampaignId,
            bonus_with_levels_campaign_id: data.bonusWithLevelsCampaignId,
            coupon_id: data.couponId,
            meta_data: data.metaData,
        });
    }

    public updateCheckout(values: object): Observable<ShoppingCartData> {
        return this.backendService.put('cart/state', values);
    }

    public skipChallengeReward(data: { id: number, skip_reward: boolean }) {
        return this.backendService.put('cart/challenge/skip-reward', data);
    }

    public patchItem(param: ShoppingCartItem) {
        return this.backendService.put(`cart/item/${param.id}`, param);
    }

    public addManyBySku(data: { increment: number; sku: string }[]): Observable<ShoppingCartData> {
        return this.backendService.put('cart/items-by-sku', data);
    }

    public validateUniqueEmailForTicket(email: string, productId: number): Observable<validateUniqueEmailForTicketReturnType> {
        return this.backendService.post('cart/validate/ticketUniqueEmail', { email, productId });
    }
}
