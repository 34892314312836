import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BackendService } from './backend.service';

export interface MyCustomerTag {
    label: string,
    value: string,
    color: string,
}

export interface MyCustomerBase {
    city: string;
    country_id: string;
    created_at: string;
    email: string;
    entity_id: number;
    firstname: string;
    is_active: boolean;
    language: string;
    lastname: string;
    postcode: string;
    street: string;
    tags: MyCustomerTag[];
    telephone: string;
    updated_at: string;
}

export type MyCustomerLanguageExtras = {
    is_country_different: boolean;
    is_language_correct_for_country: boolean;
};

export type MyCustomer = MyCustomerBase & MyCustomerLanguageExtras & {
    intranet_id: number;
    vip: boolean;
    selected: boolean;
    is_disabled: boolean;
    missing?: boolean;
    is_in_cart: boolean;
    is_in_previous_order: boolean;
};

interface MyCustomersResponse {
    items: MyCustomer[];
    total: number;
}

interface GetCustomerListResponse {
    customers: (MyCustomerBase & MyCustomerLanguageExtras)[];
}

@Injectable({
    providedIn: 'root'
})
export class MyCustomersService {

    constructor(
        private backendService: BackendService,
    ) { }

    public getCustomers(productId?: number): Observable<MyCustomersResponse> {
        return this.backendService.get(`pcp/customers`, {productId: productId}).pipe(
            map((response) => {
                return {
                    items: response,
                    total: 0,
                };
            }),
        );
    }

    public getCustomerByCustomerIds(customerIds: string[]): Observable<GetCustomerListResponse> {
        return this.backendService.get(`pcp/customers/filtered`, {
            customerIds: customerIds.join(','),
        });
    }

}
