<div class="container">
    <nc-breadcrumbs [path]="breadcrumbs"></nc-breadcrumbs>
    <div class="row">
        <div class="col-12 col-md-3 print-hide">
            <div class="account">
                <div class="heart">
                    <img src="../../../assets/images/heart-2.svg" />
                    <span class="initials">{{getUserInitials()}}</span>
                </div>
                <div class="name">
                    <p>{{'my-account.hi' | translate}} <strong>{{config?.user.name}}</strong></p>
                </div>
            </div>
            <nav class="menu">
                <ul>
                    <li *ngFor="let menuItem of menuItems">
                        <a *ngIf="menuItem.target === 'INTRANET'" href="{{menuItem.path}}">
                            <i [class]="'fal fa-' + menuItem.icon"></i>
                            {{menuItem.title | translate}}
                        </a>
                        <a *ngIf="menuItem.target !== 'INTRANET'" [routerLink]="[menuItem.path]" routerLinkActive="active">
                            <i [class]="'fal fa-' + menuItem.icon"></i>
                            {{menuItem.title | translate}}
                        </a>
                    </li>
                    <li>
                        <a href="{{logoutUrl}}" class="top-spacer">
                            <i class="fal fa-sign-out"></i>
                            {{'my-account.sign-out' | translate}}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-12 col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
