import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SubSink } from 'subsink';
import { filter } from 'rxjs/operators';

import { MyAccountMenuItem, myAccountMenuItems, MyAccountMenuItemTarget } from './my-account-menu-items';
import { ConfigService, AppConfig } from 'src/app/core/config.service';
import { BreadCrumbsLink } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { BackendService } from "src/app/core/backend.service";

@Component({
    selector: 'nc-my-account-menu',
    templateUrl: './my-account-menu.component.html',
    styleUrls: ['./my-account-menu.component.scss'],
})
export class MyAccountMenuComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    menuItems: MyAccountMenuItem[];
    config: AppConfig;
    logoutUrl: string;

    breadcrumbs: BreadCrumbsLink[] = [];

    constructor(
        private configService: ConfigService,
        private backendService: BackendService,
        private router: Router,
    ) {
        this.menuItems = myAccountMenuItems.map((item: MyAccountMenuItem) => {
            if (item.target === MyAccountMenuItemTarget.INTRANET) {
                item.path = this.backendService.getIntranetUrl(item.path);
            }

            return item;
        });
    }

    ngOnInit() {
        this.subs.sink = this.configService.configChanges.subscribe((config) => {
            this.config = config;
        });
        this.subs.sink = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setBreadCrumbs(event.urlAfterRedirects);
        });
        this.setBreadCrumbs(this.router.url);

        this.logoutUrl = this.backendService.getIntranetUrl('logout');
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    setBreadCrumbs(url: string) {
        this.breadcrumbs = [
            {
                label: 'breadcrumbs.start',
                href: '/',
                translate: true,
            },
            {
                label: 'my-account.my-account',
                href: '/my-account',
                translate: true,
            },
        ];
        const menuItem = myAccountMenuItems.find(menuItem => menuItem.path === url);
        if (menuItem) {
            this.breadcrumbs.push({
                label: menuItem.title,
                href: menuItem.path,
                translate: true,
            });
        }
    }

    getUserInitials(): string {
        if (this.config) {
            return this.config.user.name
                .split(' ')
                .map((word) => word[0])
                .join('')
                .toUpperCase();
        }
    }

}
