import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/core/backend.service';
import { Observable } from 'rxjs';
import { Locale } from './config.service';

export interface Product {
    configurableChildren: Product[];
    sku: string;
    shortDescription: string;
    id: number;
    publish: boolean;
    categories: number[];
    name: string;
    description: string;
    price: number;
    minPrice?: number;
    maxPrice?: number;
    newPrice?: number;
    newMaxPrice?: number;
    newMinPrice?: number;
    hideNewPrice: boolean;
    recommendedProducts: number[];
    othersAlsoBought: number[];
    lookProducts: number[];
    images: ProductImage[];
    videos: ProductVideo[];
    documents: ProductDocument[];
    pinkSale: boolean;
    stockQuantity?: number;
    isVirtualStockEnabled?: boolean;
    stockMonitoringIsActive?: boolean;
    disableCheckout?: boolean;
    disableAddToCart?: boolean;
    isDiscontinued?: boolean;
    isOutOfStock?: boolean;
    requiredPurchase?: string;
    requiredPurchaseFulfilledOnPreviousOrder: boolean;
    requiredPurchaseProduct?: Product;
    isActivatingOrderRequired?: boolean;
    color: string;
    colorName: string;
    type:
        | 'simple'
        | 'wrapper'
        | 'look'
        | 'dress'
        | 'ticket'
        | 'pcp'
        | 'webpage'
        | 'prepack'
        | 'configurable';
    cartType: string;
    statusCode: number;
    isDemo: boolean;
    variationLabel: string;
    children: ProductVariationGroup[];
    importantInfo: string;
    bonusCampaignId?: number;
    bonusWithLevelsCampaignId?: number;
    bonusCampaignProductPerTrigger?: boolean;
    bonusProductSelectedQuantity?: number;
    maximumDiscount?: string;
    discountLabel?: string;
    fixedDiscountLabel?: string;
    isStockMonitored: boolean;

    maxItemsOnOrder?: number;
    maxItemsOnOrderCalculated?: number;
    maxItemsTotal?: number;
    maxItemsTotalFrom?: string;
    maxItemsTotalTo?: string;
    maxItemsTotalCalculated?: number;

    // Ticket only
    requireName?: boolean;
    hideName?: boolean;
    requireEmail?: boolean;
    hideEmail?: boolean;
    requireMobile?: boolean;
    hideMobile?: boolean;
    hideFoodPreferences?: boolean;
    days?: ProductOption[];

    volume?: string;
    manufacturer?: string;
    bundledProducts?: {
        sku: string;
        name: string;
        quantity: number;
        isOutOfStock?: boolean;
    }[];
    orderItemData?: {
        quantity: number;
        addedByCustomerSupport: boolean;
        price: number;
        priceAtPlacement?: number;
        priceHasChanged: boolean;
    };

    // Dress only
    dressOptions?: {
        models: ProductOption[];
        sizes: ProductOption[];
    };

    adminFields?: {
        available?: {
            publish: boolean;
            areaNumbers: boolean;
            activeFrom: boolean;
            activeTo: boolean;
            titleId: boolean;
        };
    };
}

export interface ProductDocument {
    url: string;
}

export interface ProductOption {
    id: string;
    label: string;
}

export interface ProductImage {
    url: string;
}

export interface ProductVideo {
    key: string;
    url: string;
    thumbnail: string;
    type: string;
}

export interface ProductVariationGroup {
    groupName: string;
    choices: ProductVariation[];
}

export interface ProductVariation {
    label: string;
    color: string;
    product: Product;
}

export enum ProductTypes {
    SIMPLE = 'simple',
    WRAPPER = 'wrapper',
    LOOK = 'look',
    DRESS = 'dress',
    TICKET = 'ticket',
    PCP = 'pcp',
    WEBPAGE = 'webpage',
    PREPACK = 'prepack',
    CONFIGURABLE = 'configurable',
}

export interface ProductsResult {
    items: Product[];
    aggregations?: ProductsAggregations;
    from: number;
    size: number;
    total: number;
}

export interface ProductsAggregations {
    max_price: { value: number };
    min_price: { value: number };
    colorName: { buckets: Bucket[] };
    productSeries: { buckets: Bucket[] };
    skinType: { buckets: Bucket[] };
    variationColorGroups: { buckets: Bucket[] };
    usageRegion: { buckets: Bucket[] };
    skinTone: { buckets: Bucket[] };
    makeupProperties: { buckets: Bucket[] };
    finish: { buckets: Bucket[] };
    mkProductType: { buckets: Bucket[] };
    fragrance: { buckets: Bucket[] };
    seminarYear: { buckets: Bucket[] };
    // variationColors: { buckets: CompositeBucket[] }; No longer used
}

export interface Bucket {
    key: string | number;
    doc_count: number;
}

export interface CompositeBucket {
    key: { color: string; label: string };
    doc_count: number;
}

export interface Filter {
    property: string;
    equals?: string | number;
    notEquals?: string | number;
    one?: string[] | number[];
    all?: string[] | number[];
}

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private backendService: BackendService) {}

    // Market is not used anymore. Taken from jwt.

    public getAllProducts(market: Locale): Observable<ProductsResult> {
        return this.backendService.get(`products`);
    }

    public getProduct(market: Locale, id: string): Observable<Product> {
        return this.backendService.get(`products/${id}`);
    }

    public getProducts(data: {
        filters?: Filter[];
        from?: number;
        size?: number;
        autocomplete?: string;
        sort?: object;
        skipBasic?: boolean;
    }): Observable<ProductsResult> {
        return this.backendService.post(`products/filtered`, data);
    }

    public getFavourites(): Observable<number[]> {
        return this.backendService.get('products/favourites/list');
    }

    public addFavourite(productId: number): Observable<number[]> {
        return this.backendService.put(`products/favourites/${productId}`);
    }

    public deleteFavourite(productId: number): Observable<number[]> {
        return this.backendService.delete(`products/favourites/${productId}`);
    }

    public getRecommendedProducts(): Observable<ProductsResult> {
        return this.backendService.get(`products/recommended`);
    }

    public getCurrentOffers(): Observable<any> {
        return this.backendService.get(`products/offer/current`);
    }

    public getCurrentDiscounts(): Observable<any> {
        return this.backendService.get(`products/discounts/current`);
    }
}
