import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'nc-product-color-dot',
    templateUrl: './product-color-dot.component.html',
    styleUrls: ['./product-color-dot.component.scss']
})
export class ProductColorDotComponent {
    @Input() color: string;
    @Input() colorName?: string = '';
}
