import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {AppConfig, ConfigService} from './core/config.service';
import { SnowFlakesService } from './core/snow-flakes.service';

@Component({
    selector: 'nc-root',
    template: `
        <nc-layout></nc-layout>
        <nc-chat-support></nc-chat-support>
    `,
})
export class AppComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        public translate: TranslateService,
        public configService: ConfigService,
        protected snowFlakesService: SnowFlakesService,
    ) {
        translate.addLangs(['sv', 'svfi', 'fi', 'no']);
    }

    ngOnInit() {
        this.configService.configChanges.subscribe((config: AppConfig) => {
            const locale = config.currentMarket ? config.currentMarket.toLowerCase() : 'sv';

            this.configService.changeLocale(locale);
        });
        this.configService.localeChanges.subscribe(locale => {
            if (locale) {
                this.translate.use(locale);
            }
        });

        this.snowFlakesService.showSnowFlakesInDecember();
    }

}
