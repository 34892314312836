import { Component } from '@angular/core';
import { BackendService } from 'src/app/core/backend.service';

@Component({
    selector: 'nc-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent {

    constructor(
        private backendService: BackendService,
    ) {}

    copyrightUrl = this.backendService.getIntranetUrl('default/contact');

    socialMedia = [
        {url: 'https://instagram.com/marykaynordic', title: 'Instagram', icon: 'instagram'},
        {url: 'https://www.facebook.com/marykaysverige', title: 'Facebook', icon: 'facebook'},
        {url: 'https://www.youtube.com/user/mklesley', title: 'YouTube', icon: 'youtube'},
    ];

}
