<div *ngIf="challenges !== null">
<h2 *ngIf="challenges.length > 0">{{'shopping-cart.my-challenges' | translate}}</h2>
<div class="challenge" *ngFor="let challenge of challenges">
    <h3 class="fancy-heading">{{challenge.name}}</h3>
    <div class="text-blocks">
        <div *ngIf="currentStep(challenge)">
            <strong>{{'challenge.current-step' | translate}}</strong>
            <span>{{currentStep(challenge)?.name}}</span>
        </div>
        <div *ngIf="nextStep(challenge)">
            <strong>{{'challenge.next-step' | translate}}</strong>
            <span>{{nextStep(challenge)?.name}}</span>
        </div>
        <div class="size-3" *ngIf="nextStep(challenge)">
            <strong>{{'challenge.conditions' | translate}}</strong>
            <ul>
                <li *ngIf="nextStep(challenge)?.requiredSum"
                    [class.checked]="challenge.currentSum >= nextStep(challenge)?.requiredSum">
                    {{'challenge.min-purchases' | translate:({value: nextStep(challenge)?.requiredSum | ncCurrency})}}
                </li>
                <li *ngIf="nextStep(challenge)?.requiredRecruitments"
                    [class.checked]="challenge.currentRecruitments >= nextStep(challenge)?.requiredRecruitments">
                    {{'challenge.req-new-members' | translate:({value: nextStep(challenge)?.requiredRecruitments})}}
                </li>

                <ng-container *ngIf="nextStep(challenge)?.requiredSum2 || nextStep(challenge)?.requiredRecruitments2">
                    <strong>{{'challenge.conditions-secondary' | translate}}</strong>
                    <ul>
                        <li *ngIf="nextStep(challenge)?.requiredSum2"
                            [class.checked]="challenge.currentSum >= nextStep(challenge)?.requiredSum2">
                            {{'challenge.min-purchases' | translate:({value: nextStep(challenge)?.requiredSum2 | ncCurrency})}}
                        </li>
                        <li *ngIf="nextStep(challenge)?.requiredRecruitments2"
                            [class.checked]="challenge.currentRecruitments >= nextStep(challenge)?.requiredRecruitments2">
                            {{'challenge.req-new-members' | translate:({value: nextStep(challenge)?.requiredRecruitments2})}}
                        </li>
                    </ul>
                </ng-container>

            </ul>
        </div>
    </div>
</div>
</div>
<!--
<table>
    <tbody>
    <tr>
        <td>
            <span>Stjärnkonsultprogrammet</span>
            <span>{{'challenges.left-until-next-level' | translate}}</span>
        </td>
        <td>{{2500 | ncCurrency}}</td>
    </tr>
    <tr>
        <td>
            <span>Beelieve in Your Dreams</span>
            <span>{{'challenges.left-until-next-level' | translate}}</span>
        </td>
        <td>{{500 | ncCurrency}}</td>
    </tr>
    <tr>
        <td>
            <span>Köp till grossistpris</span>
            <span>{{'challenges.left-until-next-level' | translate}}</span>
        </td>
        <td>{{3400 | ncCurrency}}</td>
    </tr>
    </tbody>
</table>
 -->
