import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
    ) {}

    public async success(message: string) {
        this.open(message, 'nc-snack-bar-success', 4000);
    }

    public async info(message: string) {
        this.open(message, 'nc-snack-bar-info');
    }

    public async error(message?: string) {
        const defaultErrorMessage = await this.translateService.get('snack-bar.default-error-message').toPromise();
        this.open(message || defaultErrorMessage, 'nc-snack-bar-error');
    }

    private async open(message, panelClass, duration?) {
        const translatedMessage = await this.translateService.get(message).toPromise();
        const action = await this.translateService.get('snack-bar.close').toPromise();
        this.snackBar.open(translatedMessage, action, {panelClass, duration});
    }

}
