import { Injectable } from '@angular/core';

export interface Challenge {
    name: string;
    icon: string;
    start_date: string;
    end_date: string;
    days_since_reg_show_end_date?: boolean;
    days_since_reg_end_date?: string;
    visible_until: string;
    period_text: string;
    currentOrderTotal: number;
    currentCartTotal: number;
    currentSum: number;
    currentInvoicedSum: number;
    currentRecruitments: number;
    hasSkippedBefore: boolean;
    hasSkippedAfter: boolean;
    steps: ChallengeStep[];
    isOpen: boolean; // dynamic
}

export interface ChallengeStep {
    name: string;
    requiredSum: number;
    requiredRecruitments: number;
    sumLeft: number;
    recruitsLeft: number;
    requiredSum2: number;
    requiredRecruitments2: number;
    sumLeft2: number;
    recruitsLeft2: number;
    prevCompleted: boolean;
    completed: boolean;
    progress: number;
}

@Injectable({
    providedIn: 'root'
})
export class ChallengeService {

    currentStepExCart(challenge: Challenge): ChallengeStep | null {
        let prevStep = null;
        for (let step of challenge.steps) {
            if (!step.prevCompleted) {
                return prevStep;
            }
            prevStep = step
        }
        return prevStep;
    }

    nextStepExCart(challenge: Challenge): ChallengeStep | null {
        for (let step of challenge.steps) {
            if (!step.prevCompleted) {
                return step;
            }
        }
        return null;
    }

    currentStep(challenge: Challenge): ChallengeStep | null {
        let prevStep = null;
        for (let step of challenge.steps) {
            if (!step.completed) {
                return prevStep;
            }
            prevStep = step
        }
        return prevStep;
    }

    nextStep(challenge: Challenge): ChallengeStep | null {
        for (let step of challenge.steps) {
            if (!step.completed) {
                return step;
            }
        }
        return null;
    }
}
