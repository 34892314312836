import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { MenuItem } from 'src/app/core/menu.service';
import { AbstractShopMenuComponent } from "src/app/layout/shop-menu/abstract-shop-menu.component";

@Component({
    selector: 'nc-mobile-shop-menu',
    templateUrl: './mobile-shop-menu.component.html',
    styleUrls: ['./mobile-shop-menu.component.scss'],
})
export class MobileShopMenuComponent extends AbstractShopMenuComponent {

    firstLevelIsOpen = false;
    selectedFirstLevelItem: MenuItem = null;

    ngOnInit () {
        super.ngOnInit();
    }

    toggleMenu() {
        if (this.firstLevelIsOpen) {
            this.closeFirstLevel();
        } else if (this.selectedFirstLevelItem) {
            this.closeSecondLevel();
        } else {
            this.openFirstLevel();
        }
    }

    openFirstLevel() {
        this.firstLevelIsOpen = true;
        this.openChange.emit(true);
    }

    closeFirstLevel() {
        this.firstLevelIsOpen = false;
        this.openChange.emit(false);
    }

    openSecondLevel(item: MenuItem) {
        this.selectedFirstLevelItem = item;
        this.openChange.emit(true);
    }

    closeSecondLevel() {
        this.selectedFirstLevelItem = null;
        this.openChange.emit(false);
    }

    selectFirstLevelItem(item: MenuItem) {
        this.closeFirstLevel();
        this.openSecondLevel(item);
    }

    selectSecondLevelItem(item: MenuItem) {
        this.closeSecondLevel();
        this.router.navigate([item.data.url]);
    }
}
