import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'nc-environment-banner',
    templateUrl: './environment-banner.component.html',
    styleUrls: ['./environment-banner.component.scss']
})
export class EnvironmentBannerComponent implements OnInit {
    version: string = '';

    constructor(
        private http: HttpClient,
    ) {
    }

    ngOnInit(): void {
        if (environment.env === 'stage') {
            this.http.get('/version.txt', {responseType: 'text'})
                .subscribe(
                    (result) => this.version = result,
                    () => {
                    }
                )
        }
    }

    environmentNotice() {
        switch (environment.env) {
            case 'stage':
                return 'staging';
            case 'dev':
                return 'development';
            default:
                return null;
        }
    }

    isVisible() {
        if (['stage', 'dev'].indexOf(environment.env) !== -1) {
            return true
        }
    };

}
