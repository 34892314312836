import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {BackendService} from "../../core/backend.service";

interface StartKitItem {
    sku: string,
    increment: number
}

interface StartKitContent {
    start_kit_products1: StartKitItem[],
    start_kit_products2: StartKitItem[],
}

@Injectable({
    providedIn: 'root'
})
export class StartKitService {

    startKitContent = new ReplaySubject<StartKitContent>(1);
    called = false;

    constructor(
        private backendService: BackendService
    ) {
    }

    public getContents() {
        if (!this.called) {
            this.called = true;
            this.backendService.get('block/start-kit').subscribe((content) => {
                this.startKitContent.next(content);
            })
        }

        return this.startKitContent;
    }

    public getContent(bundlenumber: number): Promise<StartKitItem[]> {
        return new Promise((resolve) => {
            this.getContents().subscribe((content) => {
                if (bundlenumber === 1) {
                    resolve(content.start_kit_products1);
                } else {
                    resolve(content.start_kit_products2);
                }
            })
        });
    }
}
