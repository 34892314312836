<h1 *ngIf="displayHeader">{{'my-account.coupons' | translate}}</h1>

<ng-container *ngIf="shoppingCarts && shoppingCarts.isInitialized()">
    <div class="coupons" *ngIf="!countAllCoupons()">
        <h2>{{'my-account.coupons-not-found' | translate}}</h2>
    </div>

    <div class="coupons" *ngIf="countAllCoupons()">
        <ng-container *ngFor="let cart of this.getSortedCarts()">
            <div *ngIf="willShowCouponsForCart(cart.type)" class="coupon-group">
                <!--
                    Hide header for "default" cart. It might be confusing for the enduser
                    Assuming that "default" cart is the first in the list.
                -->
                <h2 *ngIf="cart.type !== 'default'">{{'my-account.cart-type-' + cart.type | translate}}</h2>

                <div class="shopping-cart-coupons">
                    <nc-shopping-cart-coupons
                        [coupons]="getAllCoupons(cart.type)"
                        [disabled]="false"
                        [displayAsGrid]="true"
                    ></nc-shopping-cart-coupons>
                </div>
                <div class="shopping-cart-coupons">
                    <nc-shopping-cart-coupons
                        [coupons]="getCartUnusableCoupons(cart.type)"
                        [disabled]="true"
                        [displayAsGrid]="true"
                    ></nc-shopping-cart-coupons>
                </div>
            </div>
        </ng-container>

        <ng-container *ngFor="let cart of this.getSortedCarts()">
            <div *ngIf="willShowCouponsForCart(cart.type)" class="coupon-group">
                <h3 *ngIf="getAllValueChecks(cart.type).length + getAllUnusableValueChecks(cart.type).length">
                    {{'my-account.coupons.value-checks' | translate}} <a href="javascript:void(0);" (click)="openValueCheckInformation($event)" class="open-value-check-info"><i class="fas fa-info-circle"></i></a>
                </h3>
                <div *ngIf="getAllValueChecks(cart.type).length" class="shopping-cart-coupons">
                    <nc-shopping-cart-coupons
                        [coupons]="getAllValueChecks(cart.type)"
                        [disabled]="false"
                        [displayAsGrid]="true"
                    ></nc-shopping-cart-coupons>
                </div>
                <div *ngIf="getAllUnusableValueChecks(cart.type).length" class="shopping-cart-coupons">
                    <nc-shopping-cart-coupons
                        [coupons]="getAllUnusableValueChecks(cart.type)"
                        [disabled]="true"
                        [displayAsGrid]="true"
                    ></nc-shopping-cart-coupons>
                </div>
            </div>
        </ng-container>

    </div>

</ng-container>
