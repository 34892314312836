<div *ngIf="0 === products?.length && this.noResultText">
    {{this.noResultText}}
</div>
<ul class="list">
    <li *ngFor="let product of products">
        <div class="list-product" [class.unavailable]="isUnavailableForRegularUsers(product)">
            <div class="col-image">
                <div class="image image-shadow">
                    <nc-product-image [product]="product"></nc-product-image>
                    <nc-product-color-dot
                        *ngIf="product.color"
                        [color]="product.color"
                    ></nc-product-color-dot>
                </div>
            </div>
            <div class="col-info">
                <div class="info-top">
                    <a href="{{productUrl(product)}}" (click)="productClick($event, product)">
                        <div class="name">{{product.name}}</div>
                    </a>
                    <nc-product-subtitle [product]="product"></nc-product-subtitle>
                </div>
                <nc-product-image-ribbons
                    [product]="product"
                    [listView]="true"
                ></nc-product-image-ribbons>
                <nc-product-image-list *ngIf="product.type === 'look'"
                                       [images]="product.images"></nc-product-image-list>
            </div>
            <div class="col-price">
                <div class="price" *ngIf="!['look', 'wrapper'].includes(product.type)">
                    <nc-product-price [product]="product"></nc-product-price>
                </div>
                <nc-product-stock-quantity [product]="product"></nc-product-stock-quantity>
            </div>
            <div class="col-favourite">
                <div class="favourite-button" (click)="captureClick($event)">
                    <button
                        type="button"
                        nc-favourite-button
                        [productId]="product.id">
                    </button>
                </div>
            </div>
            <div class="col-shop">
                <nc-product-out-of-stock [product]="product"></nc-product-out-of-stock>

                <div *ngIf="!isRequiredPurchaseFulfilled(product)">
                    <a href="{{productUrl(product.requiredPurchaseProduct)}}">
                        <nc-product-error-message>
                            {{'product.requires-purchase-of-other-product' | translate:({ sku: product.requiredPurchaseProduct?.sku })}}
                        </nc-product-error-message>
                    </a>
                </div>

                <nc-product-dynamic-quantity-selector
                    *ngIf="product.type === 'simple' && shouldDisplayQuantitySelector(product)"
                    [product]="product"
                    [maxQuantity]="this.getMaxQuantity(product)"
                    [disabled]="!isRequiredPurchaseFulfilled(product)"
                ></nc-product-dynamic-quantity-selector>

                <div *ngIf="!['simple', 'wrapper'].includes(product.type)">

                    <div class="shopping-cart-button" (click)="captureClick($event)">
                        <button
                            *ngIf="shouldDisplayAddToShoppingCartButton(product)"
                            nc-button
                            buttonAppearance="outlined"
                            type="button"
                            buttonColor="gray"
                            buttonIcon="shopping-bag"
                            buttonSize="small"
                            stackedIcon="plus"
                            [buttonText]="'product.add' | translate"
                            [buttonPending]="addToShoppingCartPending[product.id]"
                            (click)="addToShoppingCart(product)"
                        ></button>
                        <a
                            *ngIf="!shouldDisplayAddToShoppingCartButton(product)"
                            href="{{productUrl(product)}}"
                            (click)="productClick($event, product)"
                            nc-button
                            buttonAppearance="outlined"
                            [buttonText]="'product.more-info' | translate"
                            buttonColor="gray"
                            buttonSize="small"
                            buttonIcon="arrow-right"
                        ></a>
                    </div>
                </div>
            </div>
            <div class="col-wrapper-children" *ngIf="product.type === 'wrapper'">
                <div *ngFor="let variationGroup of product.children">
                    <div class="child-row" *ngFor="let variation of variationGroup.choices">
                        <div class="col-image">
                            <nc-product-color-dot
                                *ngIf="variation.color"
                                [color]="variation.color"
                            ></nc-product-color-dot>
                        </div>
                        <div class="col-info">
                            <div class="info-top">
                                <a href="{{productUrl(product, variation)}}" (click)="productClick($event, product, variation)">
                                    <div class="name">{{variation.product.name}}</div>
                                </a>
                                <nc-product-subtitle [product]="variation.product"></nc-product-subtitle>
                            </div>
                        </div>
                        <div class="col-price">
                            <div class="price">
                                <nc-product-price [product]="variation.product"></nc-product-price>
                            </div>
                            <nc-product-stock-quantity [product]="variation.product"></nc-product-stock-quantity>
                        </div>
                        <div class="col-favourite"></div>
                        <div class="col-shop">
                            <nc-product-out-of-stock [product]="variation.product"></nc-product-out-of-stock>

                            <div *ngIf="!isRequiredPurchaseFulfilled(variation.product)">
                                <a href="{{productUrl(variation.product.requiredPurchaseProduct)}}">
                                    <nc-product-error-message>
                                        {{'product.requires-purchase-of-other-product' | translate:({ sku: variation.product?.requiredPurchaseProduct?.sku })}}
                                    </nc-product-error-message>
                                </a>
                            </div>

                            <nc-product-dynamic-quantity-selector
                                *ngIf="shouldDisplayQuantitySelector(variation.product)"
                                [product]="product"
                                [childId]="variation.product.id"
                                [maxQuantity]="this.getMaxQuantity(variation.product)"
                                [disabled]="!isRequiredPurchaseFulfilled(variation.product)"
                            ></nc-product-dynamic-quantity-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="product.bonusCampaignId || product.bonusWithLevelsCampaignId" class="bonus-campaign-controls">
            <div *ngIf="showBonusProductQuantitySelector(product)" class="quantity-selector">
                <nc-quantity-selector
                    [maxQuantity]="getBonusProductMaxQuantity(product)"
                    (selectedQuantity)="product.bonusProductSelectedQuantity = $event"
                    [title]="'product.quantity' | translate">
                </nc-quantity-selector>
            </div>
            <div
                class="bonus-add"
                [ngClass]="bonusProductState(product)"
                (click)="addBonusProduct(product)">
                <!-- Only visible in checkout -->
                <span *ngIf="bonusProductState(product) === 'add'">{{'product.add' | translate}}</span>
                <span *ngIf="bonusProductState(product) === 'buy'">{{'product.buy' | translate}}</span>
            </div>
        </div>
    </li>
</ul>
<ul class="list loading" *ngIf="products === null">
    <li *ngFor="let product of [0,0,0,0,0]; let index = index;">
        <div class="list-product">
            <div class="col-image">
                <div class="image image-shadow"><span class="img-placeholder"></span></div>
            </div>
            <div class="col-info">
                <div class="name">███████████ <br> ██████</div>
            </div>
        </div>
    </li>
</ul>
