import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    private openedCart: BehaviorSubject<string | null> = new BehaviorSubject(null);

    public get openedCartChanges() {
        return this.openedCart.asObservable();
    }

    public openShoppingCart(type) {
        if (this.openedCart.getValue() === null) {
            this.openedCart.next(type);
        }
    }

    public closeShoppingCart() {
        if (this.openedCart.getValue() !== null) {
            this.openedCart.next(null);
        }
    }
}

