import {Product} from '../core/product.service';

export function isRequiredPurchaseFulfilled(product: Product): boolean {
    if (product.requiredPurchaseFulfilledOnPreviousOrder) {
        return true;
    }

    if (product.requiredPurchase && this.shoppingCartService.cartCollection.isInitialized()) {
        const cartItem = this.shoppingCartService.cartCollection.findItemBySkuForProduct(
            product.requiredPurchase,
        );

        if (!cartItem) {
            return false;
        }
    }

    return true;
}
