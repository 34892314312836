import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

import { Product, ProductService } from 'src/app/core/product.service';
import { ConfigService } from 'src/app/core/config.service';

@Component({
    selector: 'nc-recommended-products',
    templateUrl: './recommended-products.component.html',
    styleUrls: ['./recommended-products.component.scss']
})
export class RecommendedProductsComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    products: Product[];

    constructor(
        private configService: ConfigService,
        private productService: ProductService,
    ) { }

    ngOnInit() {
        this.subs.sink = this.configService.localeChanges.subscribe((locale) => {
            if (locale) {
                this.productService.getRecommendedProducts().subscribe((response) => {
                    if (response.size > 0) {
                        this.products = response.items;
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}
