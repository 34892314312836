import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/core/backend.service';
import { Observable } from 'rxjs';

export interface MenuResponse {
  intranet: MenuItem[];
  webshop: MenuItem[];
}

export interface MenuItem {
    id?: number;
    data: {
        url: string;
        title: string;
    };
    type?: string;
    slug?: string;
    is_backend?: boolean;
    limit_user_level?: any[];
    limit_is_active?: boolean;
    limit_admin: boolean;
    children: MenuItem[];
    image?: {
        thumbnail: {
            src: string;
            width: number;
            height: number;
        };
    };
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private backendService: BackendService,
    ) { }

    public getAllMenus(market): Observable<MenuResponse> {
        return this.backendService.get('menu/' + market);
    }
}
