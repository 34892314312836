import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    FormControl,
} from '@angular/forms';

@Component({
    selector: 'nc-quantity-selector',
    templateUrl: './quantity-selector.component.html',
    styleUrls: ['./quantity-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuantitySelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => QuantitySelectorComponent),
            multi: true,
        },
    ],
})
export class QuantitySelectorComponent implements ControlValueAccessor {
    @Input() quantity = 1;

    @Output() selectedQuantity = new EventEmitter<number>();

    @Input() minQuantity: number = 1;
    @Input() maxQuantity: number = 1000000;

    @Input() label: string;
    @Input() enableFadeWhenEmpty: boolean = true;

    @Input() disabled = false;

    propagateChange: (value: number) => void = () => {};
    validateFn: (control: FormControl) => void = () => {};
    onTouchedCallback: () => void = () => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }

    validate(control: FormControl) {
        return this.validateFn(control);
    }

    writeValue(value: number | string) {
        // In some cases at runtime quantity can be a numeric string.
        this.quantity = +value;
        this.selectedQuantity.emit(+value);
    }

    increase() {
        this.setQuantity(++this.quantity);
        this.propagateChange(this.quantity);
        this.onTouchedCallback();
    }

    decrease() {
        this.setQuantity(--this.quantity);
        this.propagateChange(this.quantity);
        this.onTouchedCallback();
    }

    setQuantity(quantity: number) {
        if (quantity < this.minQuantity) {
            quantity = this.minQuantity;
        }

        if (quantity > this.maxQuantity) {
            quantity = this.maxQuantity;
        }

        this.writeValue(quantity);
    }

    updateQuantity(quantity: number) {
        this.setQuantity(quantity);
        this.propagateChange(this.quantity);
        this.onTouchedCallback();
    }

    isEmpty() {
        return this.enableFadeWhenEmpty && this.quantity === 0;
    }
}
