import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfigService } from './core/config.service';
import { CurrentDiscountsPageComponent } from './current-discounts-page/current-discounts-page.component';
import { MyAccountMenuComponent } from './layout/my-account-menu/my-account-menu.component';
import { AdminMenuComponent } from './layout/admin-menu/admin-menu.component';
import { CouponsPageComponent } from './my-account/coupons-page/coupons-page.component';

@Injectable()
class AdminGuard implements CanActivate {

    constructor(
        private configService: ConfigService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.configService.snapshot.config.isAdmin;
    }

}

@Injectable()
class StarterKitGuard implements CanActivate {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // TODO: Check if user has a starter kit and do a redirect to starter-kit page if user doesn't have one
        return true;
    }

}

const routes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./front-page/front-page.module').then(module => module.FrontPageModule),
    },
    {
        path: 'cat', // Old and deprecated
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./category-page/category-page.module').then(module => module.CategoryPageModule),
    },
    {
        path: 'category',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./category-page/category-page.module').then(module => module.CategoryPageModule),
    },
    {
        path: 'product',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./product-page/product-page.module').then(module => module.ProductPageModule),
    },
    {
        path: 'favourites',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./favourites-page/favourites-page.module').then(module => module.FavouritesPageModule),
    },
    {
        path: 'current-offers',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./current-offers-page/current-offers-page.module').then(module => module.CurrentOffersPageModule),
    },
    {
        path: 'current-discounts',
        canActivate: [StarterKitGuard],
        component: CurrentDiscountsPageComponent,
    },
    {
        path: 'whats-new',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./whats-new-page/whats-new-page.module').then(module => module.WhatsNewPageModule),
    },
    {
        path: 'pink-sale',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./pink-sale-page/pink-sale-page.module').then(module => module.PinkSalePageModule),
    },
    {
        path: 'search/:query',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./search-result-page/search-result-page.module').then(module => module.SearchResultPageModule),
    },
    {
        path: 'checkout',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./checkout-page/checkout-page.module').then(module => module.CheckoutPageModule),
    },
    {
        path: 'orders/order/done/:id',
        canActivate: [StarterKitGuard],
        loadChildren: () => import('./order-done-page/order-done-page.module').then(module => module.OrderDonePageModule),
    },
    {
        path: 'logout',
        loadChildren: () => import('./logout-page/logout-page.module').then(module => module.LogoutPageModule),
    },
    {
        path: 'print/:printId',
        loadChildren: () => import('./print-page/print-page.module').then(module => module.PrintPageModule),
    },
    {
        path: 'my-account',
        component: MyAccountMenuComponent,
        children: [
            {
                pathMatch: 'full',
                path: '',
                redirectTo: 'orders',
            },
            {
                path: 'orders',
                loadChildren: () => import('./my-account/orders-page/orders-page.module').then(module => module.OrdersPageModule)
            },
            {
                path: 'consumer-orders',
                loadChildren: () => import('./my-account/consumer-orders-page/consumer-orders-page.module').then(module => module.ConsumerOrdersPageModule)
            },
            {
                path: 'coupons',
                component: CouponsPageComponent,
            },
            {
                path: 'shipping-address',
                loadChildren: () => import('./my-account/shipping-address-page/shipping-address-page.module').then(module => module.ShippingAddressPageModule)
            },
        ],
    },
    {
        path: 'administration',
        component: AdminMenuComponent,
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule),
        canActivate: [AdminGuard],
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [AdminGuard, StarterKitGuard],
})
export class AppRoutingModule { }
