import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable({
    providedIn: 'root'
})
export class StockService {

    constructor(
        private backendService: BackendService,
    ) {
    }

    addMonitor(sku: string): Promise<{ success: boolean }> {
        return this.backendService.put(`stock/monitor/${sku}`).toPromise();
    }
}


