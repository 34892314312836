import { Component, Input, OnInit, HostBinding, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SubSink } from 'subsink';

import { FavouritesService } from './favourites.service';

export enum ButtonAppearance {
    STANDARD = 'standard',
    OUTLINED = 'outlined',
}

@Component({
    selector: 'button[nc-favourite-button]',
    templateUrl: './favourite-button.component.html',
    styleUrls: ['./favourite-button.component.scss'],
})
export class FavouriteButtonComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    pending = false;
    favourites: number[] = [];

    constructor(
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef,
        private favouritesService: FavouritesService,
    ) { }

    @Input()
    productId: number;

    @Input()
    buttonAppearance = ButtonAppearance.STANDARD;

    @Input()
    @HostBinding('disabled')
    disabled = false;

    @HostListener('click')
    handleClick() {
        this.toggleFavourite();
    }

    @HostBinding('class.pending') get pendingClass() {
        return this.pending;
    }

    @HostBinding('class.appearance--standard') get standardClass() {
        return this.buttonAppearance === ButtonAppearance.STANDARD;
    }

    @HostBinding('class.appearance--outlined') get outlinedClass() {
        return this.buttonAppearance === ButtonAppearance.OUTLINED;
    }

    @HostBinding('class.toggle-state--true') get toggleStateClass() {
        return this.isFavourite();
    }

    ngOnInit() {
        this.focusMonitor.monitor(this.elementRef);
        this.subs.sink = this.favouritesService.favouritesChanges.subscribe((response) => {
            this.favourites = response;
        });
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.elementRef);
        this.subs.unsubscribe();
    }

    isFavourite(): boolean {
        if (this.favourites) {
            return this.favourites.indexOf(this.productId) !== -1;
        }
        return false;
    }

    toggleFavourite() {
        if (this.isFavourite()) {
            this.deleteFavourite();
        } else {
            this.addFavourite();
        }
    }

    addFavourite() {
        this.pending = true;
        this.disabled = true;
        this.favouritesService.add(this.productId).then(
            () => {
                this.pending = false;
                this.disabled = false;
            },
            () => {
                this.pending = false;
                this.disabled = false;
            },
        );
    }

    deleteFavourite() {
        this.pending = true;
        this.disabled = true;
        this.favouritesService.delete(this.productId).then(
            () => {
                this.pending = false;
                this.disabled = false;
            },
            () => {
                this.pending = false;
                this.disabled = false;
            },
        );
    }

}
