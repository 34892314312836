
type MarketConfigType = {
    code: string;
    country: string; // ISO 3166-1 alpha-2
    currency: string; // ISO 4217
    adminName: string;
};

const marketConfigs = {
    se: {
        code: 'se',
        country: 'SE',
        currency: 'SEK',
        adminName: 'Sverige',
    },
    no: {
        code: 'no',
        country: 'NO',
        currency: 'NOK',
        adminName: 'Norge',
    },
    fi: {
        code: 'fi',
        country: 'FI',
        currency: 'EUR',
        adminName: 'Finland',
    },
    sefi: {
        code: 'sefi',
        country: 'FI',
        currency: 'EUR',
        adminName: 'Finland (svenska)',
    },
    dk: {
        code: 'dk',
        country: 'DK',
        currency: 'DKK',
        adminName: 'Danmark',
    }
} as const;

export function marketToHumanFriendly(marketCode: string): string {
    if (marketCode === 'all') {
        return 'Alla';
    }

    if (marketConfigs[marketCode]?.adminName) {
        return marketConfigs[marketCode].adminName;
    }

    return marketCode;
}

export function marketsAvailable(): string[] {
    return Object.keys(marketConfigs);
}

export function currenciesUniqueAvailable(): string[] {
    return Object.values(marketConfigs)
        .map((marketConfig: MarketConfigType) => marketConfig.currency)
        // Only unique currencies.
        .filter((currencyCode: string, index: number, self: string[]) => self.indexOf(currencyCode) === index);
}

export function marketToCurrency(marketCode: string): string | undefined {
    if (marketConfigs[marketCode]?.currency) {
        return marketConfigs[marketCode].currency;
    }

    // No market found.
    return null;
}

export function marketToCountryCode(marketCode: string): string | undefined {
    if (marketConfigs[marketCode]?.country) {
        return marketConfigs[marketCode].country;
    }

    // No market found.
    return null;
}
