<div class="search-container" #searchContainer>
    <button (click)="close()" class="close-button">
        <i class="fal fa-arrow-left"></i>
    </button>
    <div class="mat-search_field">
        <input #input type="text"
            [(ngModel)]="searchString"
            placeholder="{{'search-bar.placeholder' |  translate}}"
            (blur)="closeIfNoSearch()"
            autocapitalize="none"
            autocomplete="false"
            (ngModelChange)="onSearchChange($event)"
            (keydown.enter)="onSubmit()"
        />
    </div>
    <div class="search-result">
        <div *ngIf="searching" class="loader" [ngClass]="{'content-visible': showResultList}">
            <mat-spinner class="button-spinner" diameter="24"></mat-spinner>
        </div>
        <ng-container *ngIf="showResultList">
            <ng-container *ngIf="searchResults && searchResults.items.length > 0; else noResultFound">
                <div class="autocomplete-hits-container">
                    <a class="result-container"
                       *ngFor="let product of searchResults.items"
                       [routerLink]="productUrl(product)"
                       (click)="close()">
                        <div class="image">
                            <img [src]="getImage(product)" [alt]="product.name">
                        </div>
                        <div class="details">
                            <p class="heading">{{product.name}}</p>
                            <nc-product-subtitle [product]="product"></nc-product-subtitle>
                            <p class="price"><nc-product-price [product]="product"></nc-product-price></p>
                        </div>
                    </a>
                </div>
                <div class="result-button-container">
                    <button
                        nc-button
                        buttonSize="small"
                        buttonAppearance="plain"
                        class="result-button"
                        buttonText="{{'search-bar.go-to-search-page' | translate}}"
                        buttonIcon="chevron-right"
                        routerLink="/search/{{searchString}}"
                        (click)="doClose()"
                    ></button>
                </div>

            </ng-container>
            <ng-template #noResultFound>
                <div class="result-container">
                    <div class="no-results">{{'search-bar.no-result' | translate}}</div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
