import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/product.service';
import {
    ControlContainer,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { SubSink } from 'subsink';
import * as moment from 'moment';
import { MyCustomer, MyCustomersService, MyCustomerTag } from '../../../core/my-customers.service';
import {BackendService} from '../../../core/backend.service';

@Component({
    selector: 'nc-product-pcp-form',
    templateUrl: './product-pcp-form.component.html',
    styleUrls: ['./product-pcp-form.component.scss']
})
export class ProductPcpFormComponent implements OnInit {

    @Input() product: Product;

    subs = new SubSink();
    parentForm: FormGroup;
    metaFG: FormGroup;
    pending = true;
    page = 0;
    pageLength = 40; // Number of items to display
    start = this.page * this.pageLength; // Start index
    end = this.start + this.pageLength; // End index
    total = 0; // Total item count
    pageCount = 0; // Number of pages calculated from total items
    allChecked = false;
    pageCustomers?: MyCustomer[] = null;
    visibleCustomers?: MyCustomer[] = [];
    totalCustomers?: MyCustomer[] = [];
    tags: MyCustomerTag[] = [];
    selectedTag: string = null;

    public selectedCustomers?: MyCustomer[] = [];

    // Let Angular inject the control container
    constructor(
        private controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
        private backendService: BackendService,
        private myCustomersService: MyCustomersService,
    ) {
    }

    ngOnInit() {
        // Set our parentForm property to the parent control
        // (i.e. FormGroup) that was passed to us, so that our
        // view can data bind to it
        this.parentForm = <FormGroup> this.controlContainer.control;

        this.loadMyCustomers();

        // make a nested group
        this.parentForm.addControl('metaData', this.metaFG = this.formBuilder.group({
            customers: ['', [Validators.required]],
            preview: ['', [Validators.required]],
        }));
    }

    reset() {
        this.selectedCustomers = [];
    }

    toggle(item: MyCustomer) {
        this.addOrRemove(this.selectedCustomers, item);
        this.emitChange();
    }

    emitChange() {
        this.parentForm.get('metaData').get('customers').patchValue(this.selectedCustomers.map(customer => {
            return customer.entity_id;
        }));
        this.parentForm.get('metaData').get('preview').patchValue(this.selectedCustomers.slice(0, 3));
        this.parentForm.get('quantity').patchValue(this.selectedCustomers.length);
    }

    addOrRemove(array, value) {
        const index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    addItemToArray(array: any[], value) {
        const index = array.indexOf(value);
        if (index === -1) {
            array.push(value);
        }
    }

    isSelected(item: MyCustomer) {
        return this.selectedCustomers.indexOf(item) !== -1
    }

    isVisible(item: MyCustomer) {
        return this.visibleCustomers.indexOf(item) !== -1
    }

    loadMyCustomers() {
        this.myCustomersService.getCustomers(this.product.id).subscribe((response) => {
            this.totalCustomers = response.items;
            this.setTags(response.items);
            this.populateTable(response.items);

            // Add customers that are already in the cart.
            for (const customer of this.totalCustomers) {
                if (customer.is_in_cart) {
                    this.addItemToArray(this.selectedCustomers, customer);
                }
            }

            this.initPage();
            this.pending = false;
        });
    }

    populateTable(items) {
        this.visibleCustomers = items;
        this.total = this.visibleCustomers.length;
        this.pageCount = Math.ceil(this.total / this.pageLength - 1);
    }

    update() {
        if (this.page === 0) {
            this.start = 0;
        } else {
            this.start = this.page * this.pageLength;
        }

        if (this.page === this.pageCount) {
            this.end = this.total;
        } else {
            this.end = this.start + this.pageLength;
        }
    }

    toggleCheckAll() {
        if (this.allChecked) {
            this.uncheckAll();
        } else {
            this.checkAll();
        }
    }

    uncheckAll() {
        this.allChecked = false;
        this.selectedCustomers = this.selectedCustomers.filter((customer) => {
            return !this.isVisible(customer)
        });
        this.emitChange();
    }

    checkAll() {
        this.allChecked = true;
        this.selectedCustomers = this.totalCustomers.filter((customer) => {
            return !customer.is_disabled
                && !customer.is_in_previous_order
                && (this.isVisible(customer) || this.isSelected(customer));
        });
        this.emitChange();
    }

    nextPage($event) {
        $event.preventDefault();
        this.page += 1;
        this.update();
        this.initPage();
    }

    previousPage($event) {
        $event.preventDefault();
        this.page -= 1;
        this.update();
        this.initPage();
    }

    initPage() {
        this.pageCustomers = this.visibleCustomers.slice(this.start, this.end);
    }

    languageFromCode(language: string) {
        if (!language) {
            return '';
        }

        return language.split('_')[0];
    }

    private setTags(customers: MyCustomer[]) {
        // Take unique tags and put them in the tags array
        customers.forEach((customer) => {
            (customer.tags || []).forEach((newTag) => {
                const exists = this.tags.find((t) => t.value === newTag.value)

                if (!exists) {
                    this.tags.push(newTag);
                }
            });
        });

        // Sort the tags array
        this.tags = this.tags.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            return 0;
        })
    }

    private hasTag(customer: MyCustomer, tagValue: string|null) {
        if (!tagValue) {
            return true;
        }

        return !!customer.tags.find((t) => t.value === tagValue)
    }

    onTagSelect(value: string | null) {
        if (!value) {
            this.populateTable(this.totalCustomers);
        } else {
            this.populateTable(this.totalCustomers.filter((customer) => {
                return !!this.hasTag(customer, value);
            }));
        }

        this.allChecked = false;

        this.update();
        this.initPage();
    }
}
