import { Input, Output, EventEmitter, OnInit, OnDestroy, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { MenuItem } from 'src/app/core/menu.service';
import { LayoutService } from '../layout.service';
import { ShoppingCartService, ShoppingCartCollection } from 'src/app/shared/shopping-cart/shopping-cart.service';

@Directive()
export abstract class AbstractShopMenuComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    searchVisible: boolean;
    carts: ShoppingCartCollection;

    @Input() menu;

    @Output() openChange: EventEmitter<boolean> = new EventEmitter();

    constructor(
        protected router: Router,
        protected layoutService: LayoutService,
        protected shoppingCartService: ShoppingCartService
    ) {
    }

    ngOnInit() {
        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe((cartCollection) => {
            this.carts = cartCollection;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    getUrl(item: MenuItem) {
        if (!item.children.length) {
            return item.data.url;
        }
    }

    shouldDisplayAnchor(item: MenuItem) {
        return item.data.url && !item.children.length;
    }

    openSearch(): void {
        this.searchVisible = true;
    }

    openShoppingCart(type: string) {
        this.layoutService.openShoppingCart(type);
    }

    cartHasItems(type: string): boolean {
        return this.carts.hasItems(type);
    }

    cartQuantity(type: string): number {
        return this.carts.hasItems(type) ? this.carts.getCart(type).quantity_total : 0;
    }

    cartsCouponQuantity(): number {
        let couponCount = 0;

        for (const cart of this.carts.getCarts()) {
            for (const coupon of cart.coupons) {
                // Show only coupons that are not added to cart.
                if (!coupon.is_in_cart) {
                    ++couponCount;
                }
            }
        }

        return couponCount;
    }
}
