<nc-environment-banner></nc-environment-banner>
<div class="header-top">
    <div class="container">
        <div class="content-wrapper">
            <a class="logo" [href]="logoUrl">
                <img src="../../../assets/images/marykay-intranet-white.svg" />
            </a>
            <nc-user-menu class="user-menu" *ngIf="config" [config]="config"></nc-user-menu>
        </div>
    </div>
</div>
<nc-main-menu [menu]="menu" [config]="config"></nc-main-menu>
