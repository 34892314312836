import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ProductVariationGroup, ProductVariation } from 'src/app/core/product.service';

@Component({
    selector: 'nc-product-color-list',
    template: `
        <ul>
            <li *ngFor="let choice of visibleChoices">
                <span [ngStyle]="{backgroundColor: choice.color}">{{choice.label}}</span>
            </li>
        </ul>
        <span *ngIf="hiddenChoices.length" class="remainder">
            {{('product.plus-more' | translate: {value: hiddenChoices.length})}}
        </span>
    `,
    styleUrls: ['./product-color-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductColorListComponent {

    visibleChoices: ProductVariation[] = [];
    hiddenChoices: ProductVariation[] = [];

    @Input() set colors(colors: ProductVariationGroup[]) {
        if (!colors) {
            return;
        }
        const allColors = [];

        for (const group of colors) {
            for (const choice of group.choices) {
                allColors.push(choice);
            }
        }

        // if less than this then show all.
        if (allColors.length < 8) {
            this.visibleChoices = allColors;
            return;
        }

        const visibleChoices = [];
        const hiddenChoices = [];

        // else hide after x amount
        for (const choice of allColors) {
            if (visibleChoices.length < 6) {
                visibleChoices.push(choice);
            } else {
                hiddenChoices.push(choice);
            }
        }

        this.visibleChoices = visibleChoices;
        this.hiddenChoices = hiddenChoices;
    }

}
