<div class="top-section" [class.force-print]="forcePrintView">

    <div class="cart-headline">
        <h1>
            {{'shopping-cart.shopping-cart' | translate}}
            <span class="quantity-total">
                {{('shopping-cart.products' | translate: {value: shoppingCart?.quantity_total || 0})}}
            </span>
        </h1>
        <div class="print-button" *ngIf="showPrintCartButton()">
            <button
                nc-button
                buttonAppearance="outlined"
                buttonColor="black"
                buttonSize="small"
                (click)="printCart()"
                [buttonText]="'cart.print-out' | translate">
            </button>
        </div>
    </div>

    <ul class="items-list cart-section" *ngIf="shoppingCart?.items.length">
        <li *ngFor="let item of shoppingCart?.items; trackBy: trackByItemId;">
            <nc-shopping-cart-item
                [item]="item"
                [isLocked]="allItemsAreLocked()"
                [quantityOptionsMax]="getMaxQuantityForBonusProduct(item)"
            ></nc-shopping-cart-item>
        </li>
    </ul>
    <div class="removed-items cart-section" *ngIf="shoppingCart?.removed.length">
        <h2>{{'shopping-cart.removed-items' | translate}}</h2>
        <ul>
            <li *ngFor="let item of shoppingCart?.removed; trackBy: trackByItemId;">
                {{item.name}}
            </li>
        </ul>
    </div>
    <div class="coupons cart-section" *ngIf="unusedCoupons().length">
        <h2>{{'shopping-cart.my-coupons' | translate}}</h2>
        <p class="instructions">{{'shopping-cart.coupon-instructions' | translate}}</p>
        <nc-shopping-cart-coupons [cart]="shoppingCart" [displayAsGrid]="false"></nc-shopping-cart-coupons>
    </div>
    <div class="challenges cart-section" *ngIf="shoppingCartAppearance == 'standard' && shoppingCart?.type === 'default'">
        <nc-shopping-cart-challenges></nc-shopping-cart-challenges>
    </div>
</div>
<div class="bottom-section" *ngIf="shoppingCart?.items.length">
    <div class="totals">
        <h2>{{'shopping-cart.in-total' | translate}}</h2>
        <table>
            <tbody>
                <tr *ngFor="let row of shoppingCart?.totals.rows" [ngClass]="row.class">
                    <ng-container *ngIf="row.label === 'delimiter'">
                        <td class="delimiter" colspan="2">
                            <mat-divider></mat-divider>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="row.label !== 'delimiter'">
                        <td class="totals-label">
                            <i class="fal fa-award" *ngIf="row.type=='campaign'"></i>
                            <span *ngIf="row.translate">{{row.label | translate}}</span>
                            <span *ngIf="!row.translate">{{row.label}}</span>
                        </td>
                        <td class="totals-value" [ngClass]="{'negative' : row.value < 0}">
                            <span>{{row.value | ncCurrency}}</span>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
            <tfoot *ngIf="shoppingCart?.totals.vat">
                <!-- When using tax -->
                <tr>
                    <td>{{'shopping-cart.detailed-sub-total' | translate}}</td>
                    <td>{{shoppingCart?.totals.sum | ncCurrency}}</td>
                </tr>
                <tr>
                    <td>{{'shopping-cart.detailed-vat' | translate}}</td>
                    <td>{{shoppingCart?.totals.vat | ncCurrency}}</td>
                </tr>
                <tr class="larger">
                    <td>{{'shopping-cart.detailed-total' | translate}}</td>
                    <td>{{shoppingCart?.totals.totalIncVat | ncCurrency}}</td>
                </tr>
                <tr class="result-based-total">
                    <td>{{'shopping-cart.result-based-total' | translate}}</td>
                    <td>{{shoppingCart?.totals.resultBased | ncCurrency}}</td>
                </tr>
            </tfoot>
            <tfoot *ngIf="!shoppingCart?.totals.vat">
                <!-- When not using tax -->
                <tr class="result-based-total">
                    <td>{{'shopping-cart.result-based-total' | translate}}</td>
                    <td>{{shoppingCart?.totals.resultBased | ncCurrency}}</td>
                </tr>
                <tr class="larger">
                    <td>{{'shopping-cart.total' | translate}}</td>
                    <td>{{shoppingCart?.totals.totalIncVat | ncCurrency}}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

<div class="checkout-button-section">
    <a
        *ngIf="displayCheckoutButton"
        class="checkout-button"
        nc-button
        buttonColor="black"
        routerLink="checkout/{{shoppingCart?.id}}"
        [buttonText]="'shopping-cart.to-checkout' | translate"
        (click)="closeShoppingCart()">
    </a>
</div>
