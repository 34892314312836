import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Coupon } from '../../core/coupon.service';
import { SnackBarService } from '../../core/snack-bar.service';
import { ShoppingCartCollection, ShoppingCartService } from '../../shared/shopping-cart/shopping-cart.service';
import { ShoppingCartData } from '../../core/shopping-cart-data.service';
import { SubSink } from 'subsink';
import { DialogService } from '../../shared/dialog/dialog.service';
import { ValueCheckInformationComponent } from '../value-check-information/value-check-information.component';

@Component({
    selector: 'nc-coupons-page',
    templateUrl: './coupons-page.component.html',
    styleUrls: ['./coupons-page.component.scss']
})
export class CouponsPageComponent implements OnInit {

    subs = new SubSink();
    shoppingCarts: ShoppingCartCollection;

    @Input() displayHeader = true;
    @Input() displayMargins = true;

    @Output() couponCount = new EventEmitter<number>();

    constructor(
        private snackBarService: SnackBarService,
        private shoppingCartService: ShoppingCartService,
        private dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe((cartCollection) => {
            this.shoppingCarts = cartCollection;
            this.calculateCouponCount();
        });
    }

    calculateCouponCount() {
        const couponCount = this.shoppingCarts.getCarts()
            .map((cart) => cart.coupons.length)
            .reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
            }, 0);
        this.couponCount.emit(couponCount);
    }

    willShowCouponsForCart(cartType: string): boolean {
        return this.getCartUnusedCoupons(cartType).length > 0 || this.getCartUnusableCoupons(cartType).length > 0;
    }

    getCartUnusedCoupons(cartType: string): Coupon[] {
        const cart = this.shoppingCarts.getCart(cartType);

        return (cart?.coupons ?? [])
            .filter((coupon) => !coupon.is_in_cart);
    }

    countAllCoupons(): number {
        return this.shoppingCarts.getCarts()
            .map((cart) => cart.coupons.length + cart.couponsUnusable.length)
            .reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
            }, 0);
    }

    getAllValueChecks(cartType: string): Coupon[] {
        return this.shoppingCarts.getCart(cartType).coupons
            .filter((coupons) => this.isValueCheck(coupons));
    }

    getAllCoupons(cartType: string): Coupon[] {
        return this.shoppingCarts.getCart(cartType).coupons
            .filter((coupons) => !this.isValueCheck(coupons));
    }

    public getAllUnusableValueChecks(cartType: string) {
        return (this.shoppingCarts.getCart(cartType).couponsUnusable ?? [])
            .filter((coupons) => this.isValueCheck(coupons));
    }

    public openValueCheckInformation($event: MouseEvent) {
        $event.preventDefault();
        this.dialogService.open(ValueCheckInformationComponent).afterClosed();
    }

    isValueCheck(coupon: Coupon): boolean {
        return coupon.sku === '90-202317';
    }

    getCartUnusableCoupons(cartType: string): Coupon[] {
        return (this.shoppingCarts.getCart(cartType).couponsUnusable ?? [])
            .filter((coupons) => !this.isValueCheck(coupons));
    }

    getSortedCarts(): ShoppingCartData[] {
        // Sorting to always have "default" first, else by alphabetical order.
        return this.shoppingCarts.getCarts().sort((a, b) => {
            if (a.type === 'default') {
                return -1;
            }

            if (b.type === 'default') {
                return 1;
            }

            return a.type.localeCompare(b.type);
        });
    }
}
