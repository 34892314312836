import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getJwt, setJwt } from "src/app/helpers/jwt";

@Injectable({
    providedIn: 'root',
})
export class BackendService {

    constructor(
        private http: HttpClient,
    ) { }

    public getBackendRootUrl(uri: string): string {
        if (!uri) {
            return null;
        }
        let url = environment.backendRootUrl + uri.replace(/^\//g, '');
        const jwt = getJwt();
        if (jwt) {
            url = url + '?jwt=' + jwt;
        }
        return url;
    }

    public getBackendUrl(uri: string): string {
        if (!uri) {
            return null;
        }
        return environment.backendUrl + uri.replace(/^\//g, '');
    }

    public getIntranetUrl(uri: string): string {
        if (!uri) {
            return null;
        }
        return environment.intranetUrl + uri.replace(/^\//g, '');
    }

    public get(uri: string, params?): Observable<any> {
        return this.http.get(`${environment.backendUrl}${uri}`, this.getRequestOptions({ params })).pipe(
            map((response) => this.handleSuccess(response)),
        );
    }

    public post(uri: string, data): Observable<any> {
        return this.http.post(`${environment.backendUrl}${uri}`, data, this.getRequestOptions()).pipe(
            map((response) => this.handleSuccess(response)),
        );
    }

    public put(uri: string, data?): Observable<any> {
        return this.http.put(`${environment.backendUrl}${uri}`, data, this.getRequestOptions()).pipe(
            map((response) => this.handleSuccess(response)),
        );
    }

    public delete(uri: string, params?): Observable<any> {
        return this.http.delete(`${environment.backendUrl}${uri}`, this.getRequestOptions({ params })).pipe(
            map((response) => this.handleSuccess(response)),
        );
    }

    private getRequestOptions(args: any = {}) {
        const jwt = getJwt();
        const options = {} as any;
        const headers = {} as any;
        if (args.params) {
            options.params = args.params;
        }
        if (jwt) {
            headers.Authorization = 'Bearer ' + jwt;
        }

        options.headers = new HttpHeaders(headers);
        options.observe = 'response';

        return options;
    }

    private handleSuccess(response) {
        if (response.headers.get('Authorization')) {
            setJwt(response.headers.get('Authorization'));
        }
        return response.body;
    }
}
