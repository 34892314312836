import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

const key = 'productLayout';
const defaultValue = 'grid';

@Injectable({
    providedIn: 'root'
})
export class ProductLayoutService {

    private storageSub = new BehaviorSubject<string>(localStorage.getItem(key) || defaultValue);

    onChange(): Observable<string> {
        return this.storageSub.asObservable();
    }

    setValue(value: string) {
        localStorage.setItem(key, value);
        this.storageSub.next(value);
    }

    currentValue() {
        return this.storageSub.value;
    }
}
