import { Component, Input, OnInit, HostBinding, SimpleChanges, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

export enum ButtonAppearance {
    NONE = 'none',
    STANDARD = 'standard',
    POINTS = 'points',
    CUT_CORNER = 'cut_corner',
    PLAIN = 'plain',
    OUTLINED = 'outlined',
    ROUND = 'round',
}

export enum ButtonSize {
    SMALL = 'small',
}

export enum ButtonColor {
    PINK = 'pink',
    GREEN = 'green',
    WHITE = 'white',
    BLACK = 'black',
    GRAY = 'gray',
    SECONDARY_4 = 'secondary-4',
}

@Component({
    selector: 'button[nc-button], a[nc-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges, OnDestroy {

    constructor(
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef,
    ) {}

    @Input()
    buttonText: string;

    @Input()
    buttonIcon: string;

    @Input()
    stackedIcon: string;

    @Input()
    buttonPending = false;

    @Input()
    buttonAppearance:ButtonAppearance = ButtonAppearance.STANDARD;

    @Input()
    buttonSize: ButtonSize;

    @Input()
    buttonColor:ButtonColor = ButtonColor.PINK;

    @Input()
    @HostBinding('disabled')
    disabled = false;

    buttonPendingLastValue?: boolean;

    disabledLastValue?: boolean;

    @HostBinding('class.pending') get pendingClass() {
        return this.buttonPending;
    }

    @HostBinding('class.appearance--standard') get standardClass() {
        return this.buttonAppearance === ButtonAppearance.STANDARD;
    }

    @HostBinding('class.appearance--cut_corner') get cutCornerClass() {
        return this.buttonAppearance === ButtonAppearance.CUT_CORNER;
    }

    @HostBinding('class.appearance--plain') get plainClass() {
        return this.buttonAppearance === ButtonAppearance.PLAIN;
    }

    @HostBinding('class.appearance--outlined') get outlinedClass() {
        return this.buttonAppearance === ButtonAppearance.OUTLINED;
    }

    @HostBinding('class.appearance--round') get roundClass() {
        return this.buttonAppearance === ButtonAppearance.ROUND;
    }

    @HostBinding('class.color--pink') get pinkClass() {
        return this.buttonColor === ButtonColor.PINK;
    }

    @HostBinding('class.color--green') get greenClass() {
        return this.buttonColor === ButtonColor.GREEN;
    }

    @HostBinding('class.color--white') get whiteClass() {
        return this.buttonColor === ButtonColor.WHITE;
    }

    @HostBinding('class.color--black') get blackClass() {
        return this.buttonColor === ButtonColor.BLACK;
    }

    @HostBinding('class.color--gray') get grayClass() {
        return this.buttonColor === ButtonColor.GRAY;
    }

    @HostBinding('class.color--secondary4') get secondary4Class() {
        return this.buttonColor === ButtonColor.SECONDARY_4;
    }

    @HostBinding('class.size--small') get smallClass() {
        return this.buttonSize === ButtonSize.SMALL;
    }

    ngOnInit() {
        this.focusMonitor.monitor(this.elementRef);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.buttonPending) {
            this.buttonPendingLastValue = changes.buttonPending.currentValue;
        }
        if (changes.disabled) {
            this.disabledLastValue = changes.disabled.currentValue;
        }

        this.disabled = this.disabledLastValue || this.buttonPendingLastValue;
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.elementRef);
    }

}
