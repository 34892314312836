import { Component, Input, EventEmitter, Output } from '@angular/core';
import { trigger, style, animate, transition, query, group } from '@angular/animations';
import { Slide } from 'src/app/shared/product/product-gallery/product-gallery.component';

const animateLeft = [
    group([
        query(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
        ], {
            optional: true,
        }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(100%)' })),
        ], {
            optional: true,
        }),
    ]),
];

const animateRight = [
    group([
        query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(0%)' })),
        ], {
            optional: true,
        }),
        query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(-100%)' })),
        ], {
            optional: true,
        }),
    ]),
];

@Component({
    selector: 'nc-product-gallery-carousel',
    templateUrl: `product-gallery-carousel.component.html`,
    styleUrls: ['./product-gallery-carousel.component.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(':increment', animateRight),
            transition(':decrement', animateLeft),
        ]),
    ]
})
export class ProductGalleryCarouselComponent {

    @Input() slides: Slide[] = [];

    @Input() activeIndex = 0;
    @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

    trackByUrl(index: number, slide: Slide) {
        return slide.url;
    }

    previous() {
        if (this.activeIndex === 0) {
            this.activeIndex = this.slides.length - 1;
        } else {
            this.activeIndex = this.activeIndex - 1;
        }
        this.activeIndexChange.emit(this.activeIndex);
    }

    next() {
        if (this.activeIndex === this.slides.length - 1) {
            this.activeIndex = 0;
        } else {
            this.activeIndex = this.activeIndex + 1;
        }
        this.activeIndexChange.emit(this.activeIndex);
    }

    isActiveIndex(index: number) {
        return index === this.activeIndex;
    }

}
