<div class="filter-group variation-colors-groups" *ngIf="aggregations.variationColorGroups && aggregations.variationColorGroups.buckets.length">
    <span class="heading">{{'category.shade-group' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.variationColorGroups.buckets" [ngClass]="{'active': isSelected('variationColorGroups', bucket.key)}">
            <mat-checkbox [checked]="isSelected('variationColorGroups', bucket.key)" (change)="toggle('variationColorGroups', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>

<!--
<div class="filter-group filter-colors" *ngIf="aggregations.colorName && aggregations.colorName.buckets.length">
    <span class="heading">{{'category.shade' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.colorName.buckets" [ngClass]="{'active': isSelected('colorName', bucket.key)}">
            <mat-checkbox [checked]="isSelected('colorName', bucket.key)" (change)="toggle('colorName', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>
-->

<div class="filter-group" *ngIf="aggregations.productSeries && aggregations.productSeries.buckets.length">
    <span class="heading">{{'category.product-line' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.productSeries.buckets" [ngClass]="{'active': isSelected('productSeries', bucket.key)}">
            <mat-checkbox [checked]="isSelected('productSeries', bucket.key)" (change)="toggle('productSeries', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>

<div class="filter-group" *ngIf="aggregations.skinType && aggregations.skinType.buckets.length">
    <span class="heading">{{'category.skin-type' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.skinType.buckets" [ngClass]="{'active': isSelected('skinType', bucket.key)}">
            <mat-checkbox [checked]="isSelected('skinType', bucket.key)" (change)="toggle('skinType', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>


<div class="filter-group" *ngIf="aggregations.usageRegion && aggregations.usageRegion.buckets.length">
    <span class="heading">{{'category.usage-region' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.usageRegion.buckets" [ngClass]="{'active': isSelected('usageRegion', bucket.key)}">
            <mat-checkbox [checked]="isSelected('usageRegion', bucket.key)" (change)="toggle('usageRegion', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>


<div class="filter-group" *ngIf="aggregations.skinTone && aggregations.skinTone.buckets.length">
    <span class="heading">{{'category.skin-tone' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.skinTone.buckets" [ngClass]="{'active': isSelected('skinTone', bucket.key)}">
            <mat-checkbox [checked]="isSelected('skinTone', bucket.key)" (change)="toggle('skinTone', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>


<div class="filter-group" *ngIf="aggregations.makeupProperties && aggregations.makeupProperties.buckets.length">
    <span class="heading">{{'category.effect' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.makeupProperties.buckets" [ngClass]="{'active': isSelected('makeupProperties', bucket.key)}">
            <mat-checkbox [checked]="isSelected('makeupProperties', bucket.key)" (change)="toggle('makeupProperties', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>

<div class="filter-group" *ngIf="aggregations.mkProductType && aggregations.mkProductType.buckets.length">
    <span class="heading">{{'category.product-type' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.mkProductType.buckets" [ngClass]="{'active': isSelected('mkProductType', bucket.key)}">
            <mat-checkbox [checked]="isSelected('mkProductType', bucket.key)" (change)="toggle('mkProductType', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>

<div class="filter-group" *ngIf="aggregations.fragrance && aggregations.fragrance.buckets.length">
    <span class="heading">{{'category.fragrance' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.fragrance.buckets" [ngClass]="{'active': isSelected('fragrance', bucket.key)}">
            <mat-checkbox [checked]="isSelected('fragrance', bucket.key)" (change)="toggle('fragrance', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>

<div class="filter-group" *ngIf="aggregations.finish && aggregations.finish.buckets.length">
    <span class="heading">{{'category.finish' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of aggregations.finish.buckets" [ngClass]="{'active': isSelected('finish', bucket.key)}">
            <mat-checkbox [checked]="isSelected('finish', bucket.key)" (change)="toggle('finish', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>


<div class="filter-group" *ngIf="aggregations.seminarYear && aggregations.seminarYear.buckets.length">
    <span class="heading">{{'category.seminar-year' | translate}}</span>
    <ul class="filter-list">
        <li *ngFor="let bucket of sortBucketByKey(aggregations.seminarYear.buckets)" [ngClass]="{'active': isSelected('seminarYear', bucket.key)}">
            <mat-checkbox [checked]="isSelected('seminarYear', bucket.key)" (change)="toggle('seminarYear', bucket.key)">
                <span class="key">{{bucket.key}}</span><span class="count">{{bucket.doc_count}}</span>
            </mat-checkbox>
        </li>
    </ul>
</div>


<!--<div class="filter-group">-->
<!--    <span class="heading">{{'category.price-range' | translate}}</span>-->
<!--    <div>{{'category.min-price' | translate}}: {{aggregations.min_price.value | ncCurrency}}</div>-->
<!--    <div>{{'category.max-price' | translate}}: {{aggregations.max_price.value | ncCurrency}}</div>-->
<!--</div>-->

<div class="filter-footer">
    <button
        nc-button
        class="reset-button"
        buttonAppearance="plain"
        [buttonText]="'category.reset-filter' | translate"
        (click)="reset()">
    </button>
</div>
