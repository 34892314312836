<div *ngIf="coupons.length">
    <ul [class.coupon-grid]="displayAsGrid">
        <li *ngFor="let coupon of coupons">
            <nc-coupon
                [coupon]="coupon"
                [disabled]="disabled"
            ></nc-coupon>
        </li>
    </ul>
</div>
