import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'src/app/core/config.service';

@Pipe({
    name: 'ncCurrency'
})
export class CurrencyPipe implements PipeTransform {

    constructor(
        private configService: ConfigService,
    ) { }

    transform(value: number): string {
        const user = this.configService.snapshot.config.user;
        const formatter = new Intl.NumberFormat(user.locale.replace('_', '-'), {
            style: 'currency',
            currency: user.currency,
        });
        return formatter.format(value || 0);
    }

}
