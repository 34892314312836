import { HttpParams } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    Product,
    ProductDocument,
    ProductService,
    ProductVariation,
} from 'src/app/core/product.service';
import { AppConfig, ConfigService } from 'src/app/core/config.service';
import { ShoppingCartService } from 'src/app/shared/shopping-cart/shopping-cart.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
import { conditionallyRequiredValidator, requiredTrueValidator } from 'src/app/helpers/validators';
import { ProductTermsComponent } from 'src/app/shared/product/product-terms/product-terms.component';
import { ShoppingCartItem } from 'src/app/core/shopping-cart-data.service';
import { Coupon } from '../../../core/coupon.service';
import { SnackBarService } from '../../../core/snack-bar.service';
import { ProductDynamicQuantitySelectorComponent } from '../product-dynamic-quantity-selector/product-dynamic-quantity-selector.component';
import { getMaxQuantity, productBuildUrl } from '../../../helpers/product-helpers';
import {isRequiredPurchaseFulfilled} from "../../../helpers/requirements";

@Component({
    selector: 'nc-product-view',
    templateUrl: './product-view.component.html',
    styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit, OnDestroy {
    @Input() product: Product;
    @Input() coupon: Coupon = null;
    @Input() compactView = false;
    @Input() showActions = true;
    @Input() initialSelectedVariant?: ProductVariation;
    @Output() changeVariation: EventEmitter<ProductVariation> = new EventEmitter();
    @Output() addToCart: EventEmitter<{ formValue: any }> = new EventEmitter();

    @ViewChild('QuantitySelector') quantitySelector: ProductDynamicQuantitySelectorComponent;

    subs = new SubSink();
    recommendedProducts?: Product[] = null;
    config: AppConfig;
    addToShoppingCartPending = false;
    slides = [];
    selectedVariation: ProductVariation = null;

    getMaxQuantity = getMaxQuantity;
    isRequiredPurchaseFulfilled = isRequiredPurchaseFulfilled;

    constructor(
        private productService: ProductService,
        private configService: ConfigService,
        private shoppingCartService: ShoppingCartService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private translate: TranslateService,
        private snackBarService: SnackBarService,
        private router: Router,
    ) {}

    form: FormGroup = this.formBuilder.group({
        productType: [],
        // NOTE: Change quantity default value to 0 when Robert is ready. Tasks: MK-1082, MK-1036
        quantity: [0],
        // quantity: [1],
        productId: [''],
        couponId: [null],
        childId: [
            '',
            conditionallyRequiredValidator(() => {
                return this.product && this.product.type === 'wrapper';
            }),
        ],
        // Note: More controls are added by child components
        termsAgreement: [true, requiredTrueValidator],
    });

    ngOnInit() {
        this.subs.sink = this.configService.configChanges.subscribe((response) => {
            this.config = response;
        });

        this.form.get('productType').setValue(this.product.type);
        this.form.get('productId').setValue(this.product.id);

        if (this.coupon) {
            this.form.get('couponId').setValue(this.coupon.id);
        }

        if (this.product.type === 'ticket') {
            this.form.get('quantity').setValue(1);
        }

        if (this.product.type === 'webpage') {
            this.form.get('termsAgreement').setValue(false);
            this.form.get('quantity').setValue(1);
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    shouldDisplayForm() {
        if (this.product.isDiscontinued) {
            return false;
        }

        if (this.product.type === 'look') {
            return false;
        }

        return true;
    }

    public openDocument(doc) {
        window.open(doc.url, '_blank');
    }

    public addToShoppingCart() {
        this.addToShoppingCartPending = true;

        if (this.coupon) {
            this.form.value.couponId = this.coupon.id;
        }

        // NOTE: Change "addItem" to "updateQuantity" when Robert is ready. Tasks: MK-1082, MK-1036
        this.shoppingCartService
            .updateQuantity(this.form.value, this.getProduct().importantInfo)
            .then(() => {
                // this.shoppingCartService.addItem(this.form.value, this.getProduct().importantInfo).then(() => {
                // this.pcpForm.reset(); @todo Reset selected
                this.addToShoppingCartPending = false;
                this.addToCart.emit({ formValue: this.form.value });

                this.cleanupFormOnSubmit();
                this.showProductAddedToCartMessage();
            });
    }

    protected cleanupFormOnSubmit() {
        // Reset the metadata form if it matches the list of product types
        if (['ticket'].includes(this.product.type)) {
            this.form.controls.metaData.reset();
        }
    }

    protected showProductAddedToCartMessage() {
        if (['ticket'].includes(this.product.type)) {
            this.snackBarService.success('shopping-cart.added-product-message').then();
        }
    }

    public onChangeVariation(variation: ProductVariation) {
        this.selectedVariation = variation;
        this.changeVariation.emit(variation);
    }

    showQuantitySelector(): boolean {
        if (this.showActions === false) {
            return false;
        }

        // Is wrapper but has not selected variant
        if (this.product.type === 'wrapper' && !this.selectedVariation) {
            return false;
        }

        // Don't show if 'pcp', 'ticket', 'webpage'.
        if (['pcp', 'ticket', 'webpage'].includes(this.product.type)) {
            return false;
        }

        if (this.getProduct().stockMonitoringIsActive) {
            return false;
        }

        return true;
    }

    public openTerms() {
        this.dialogService.open(ProductTermsComponent, { slug: 'villkor-personlig-hemsida' });
    }

    requiredPurchase() {
        if (this.selectedVariation) {
            return this.selectedVariation?.product.requiredPurchase;
        }

        if (this.product.type !== 'wrapper') {
            // todo This does not return, should it be removed or should it return? Is it a problem?
            this.product.requiredPurchase;
        }

        return null;
    }

    isCompactView() {
        return this.compactView;
    }

    getShoppingItemByProduct(product: Product): ShoppingCartItem | null {
        const cart = this.shoppingCartService.getCartByProductType(product.type);
        const cartItems = cart?.items ?? [];

        // Check child product.
        for (const item of cartItems) {
            if (product.id == item.child_id && !item.coupon_id) {
                return item;
            }
        }

        // Check non-child products
        for (const item of cartItems) {
            if (product.id == item.product_id && !item.coupon_id) {
                return item;
            }
        }

        return null;
    }

    isProductInCart(product: Product): boolean {
        return !!this.getShoppingItemByProduct(product);
    }

    getProductQuantityInCart(product: Product): number {
        const item = this.getShoppingItemByProduct(product);

        return item ? item.quantity : 0;
    }

    // NOTE: Remove this "getProductAddButtonLabel" when Robert is ready. Tasks: MK-1082, MK-1036
    // getProductAddButtonLabel(product: Product): string {
    //     if (product.type === 'pcp' && this.isProductInCart(product)) {
    //         return 'product.pcp.cart-recipients-update';
    //     }
    //
    //     return 'product.add-to-cart';
    // }

    // NOTE: Uncomment this "getProductAddButtonLabel" when Robert is ready. Tasks: MK-1082, MK-1036
    getProductAddButtonLabel(product: Product): string {
        if (product.type === 'pcp' && this.isProductInCart(product)) {
            return 'product.pcp.cart-recipients-update';
        }

        if (product.type === 'ticket') {
            return 'product.add-to-cart';
        }

        if (product.type === 'wrapper' && !this.selectedVariation) {
            return 'product.add-to-cart';
        }
        return this.getProductQuantityInCart(product) > 0
            ? 'product.update-cart'
            : 'product.add-to-cart';
    }

    getProduct(): Product {
        return this.selectedVariation?.product || this.product;
    }

    isProductAddButtonDisabled(): boolean {
        const product = this.getProduct();

        if (!this.isRequiredPurchaseFulfilled(product)) {
            return true;
        }

        switch (product.type) {
            case 'pcp':
                return this.isProductAddButtonDisabledForPcp();

            case 'ticket':
                return this.isProductAddButtonDisabledForTicket();

            case 'webpage':
                return this.isProductAddButtonDisabledForWebpage();
        }

        // This is workaround for a bug in the quantity selector (nc-product-dynamic-quantity-selector).
        // Sometimes the quantity selector doesn't refresh when the value is updated on product page load.
        // This forces the button to always be disabled when user has not changed the quantity yet.
        const isQuantityTouched = this.form.get('quantity').touched;

        return (
            product.disableAddToCart ||
            this.form.invalid ||
            !isQuantityTouched ||
            // Using loose check since quantity can be returned as a numeric string if entered manually.
            this.getProductQuantityInCart(product) == this.form.value.quantity
        );
    }

    isProductAddButtonDisabledForWebpage(): boolean {
        const isTermsAgreementChecked = this.form.get('termsAgreement').value ?? false;

        return !isTermsAgreementChecked;
    }

    isProductAddButtonDisabledForPcp(): boolean {
        const quantity = this.form.get('quantity').value ?? 0;

        return 0 == quantity;
    }

    isProductAddButtonDisabledForTicket(): boolean {
        return this.form.get('metaData').invalid ?? true;
    }

    getProductDocuments(): ProductDocument[] {
        const product = this.getProduct();

        // If selected variant product (simple) has documents.
        if (product.documents.length) {
            return product.documents;
        }

        // If the main product has documents.
        if (this.product.documents.length) {
            return this.product.documents;
        }

        return [];
    }

    getProductUrl(product: Product): string {
        return productBuildUrl(this.configService, product);
    }

    navigateToProduct($event: MouseEvent, product: Product): void {
        this.captureClick($event);

        const url = this.getProductUrl(product);
        this.router.navigateByUrl(url).then();
    }

    captureClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
    }
}
