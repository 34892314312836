import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'src/app/core/menu.service';
import { BackendService } from 'src/app/core/backend.service';

const ALLOWED_LEVELS = 2;

@Component({
    selector: 'nc-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {

    constructor(
        private backendService: BackendService,
    ) {}

    submenuIsOpen = false;

    @Input() item: MenuItem;
    @Input() level = 1;
    @Input() translateTitle = false;

    @Output() openChange: EventEmitter<boolean> = new EventEmitter();

    @HostBinding('class') get levelClass() {
        return `level-${this.level}`;
    }

    @HostBinding('class.has-children') get hasChildrenClass() {
        return this.shouldDisplaySubmenu();
    }

    @HostBinding('class.submenu-is-open') get submenuIsOpenClass() {
        return this.submenuIsOpen;
    }

    @HostBinding('class.admin-item') get adminClass() {
        return this.item.limit_admin;
    }

    shouldDisplaySubmenu(): boolean {
        return this.item.children.length > 0 && this.level < ALLOWED_LEVELS;
    }

    openSubmenu() {
        this.submenuIsOpen = true;
        this.openChange.emit(true);
    }

    closeSubmenu() {
        this.submenuIsOpen = false;
        this.openChange.emit(false);
    }

    convertUrl(url: string, type?: string) {
        if (url.startsWith('http')) {
            return url;
        }

        if (type === 'local') {
            return url;
        }

        // Assume URL is Intranet URL.
        return this.backendService.getIntranetUrl(url);
    }

}
