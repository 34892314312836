<nav class="container">
    <div class="content-wrapper">
        <ul class="menu-root">
            <li
                class="menu-item"
                *ngFor="let item of menu; let index=index"
                (mouseleave)="onMouseleave()"
                (mouseenter)="onMouseenter(item, index)">
                <a
                    class="label"
                    [class.is-admin]="item.limit_admin"
                    *ngIf="shouldDisplayAnchor(item)"
                    [routerLink]="item.data.url"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: item.data.url === '/'}"
                >
                    {{item.data.title}}
                </a>
                <span class="label" *ngIf="!shouldDisplayAnchor(item)"
                      [class.active]="isCurrentPath(item)"
                >{{item.data.title}}</span>
                <nc-mega-menu
                    *ngIf="hoverIndex==index"
                    [menu]="item"
                    (selectItem)="selectSecondLevelItem($event)">
                </nc-mega-menu>
            </li>
        </ul>
        <ul class="icon-menu">
            <li>
                <a class="label icon-label desktop" (click)="openSearch()" title="{{'search-bar.placeholder' | translate}}">
                    <i class="fal fa-search"></i>
                </a>
            </li>
            <li>
                <a routerLink="/my-account/coupons" title="{{'my-account.coupons' | translate}}">
                    <i class="fal fa-tag"></i>
                    <span class="badge badge-animate badge-accentuete" *ngIf="cartsCouponQuantity() > 0">{{cartsCouponQuantity()}}</span>
                </a>
            </li>
            <li>
                <a routerLink="/my-account" title="{{'my-account.my-account' | translate}}">
                    <i class="fal fa-user"></i>
                </a>
            </li>
            <li>
                <a routerLink="favourites" routerLinkActive="active" title="{{'favourites.favourites' | translate}}">
                    <i class="fal fa-heart"></i>
                </a>
            </li>
            <nc-carts-menu></nc-carts-menu>
        </ul>
        <nc-search-bar (closeSearch)="searchVisible = false" *ngIf="searchVisible"></nc-search-bar>
    </div>
</nav>
