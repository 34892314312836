import { Component, Input, OnInit,  Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'nc-product-video',
    templateUrl: './product-video.component.html',
    styleUrls: ['./product-video.component.scss']
})
export class ProductVideoComponent {

    @Input() src: string;
    @Input() type: string;
    @Input() allowfullscreen: boolean;

    constructor(public sanitizer: DomSanitizer) {}

}
