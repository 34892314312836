import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { MainMenuComponent } from './site-header/main-menu/main-menu.component';
import { MenuItemComponent } from './site-header/main-menu/menu-item/menu-item.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { DesktopShopMenuComponent } from './shop-menu/desktop-shop-menu.component';
import { MobileShopMenuComponent } from './shop-menu/mobile-shop-menu.component';
import { MegaMenuComponent } from './shop-menu/mega-menu/mega-menu.component';
import { UserMenuComponent } from './site-header/user-menu/user-menu.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { SearchBarComponent } from './shop-menu/search-bar/search-bar.component';
import { MyAccountMenuComponent } from './my-account-menu/my-account-menu.component';
import { CartsMenuComponent } from './shop-menu/carts-menu/carts-menu.component';
import { ProductAddNotificationComponent } from './notifications/product-add-notification/product-add-notification.component';
import { EnvironmentBannerComponent } from './environment-banner/environment-banner.component';

@NgModule({
    declarations: [
        LayoutComponent,
        SiteHeaderComponent,
        MainMenuComponent,
        MenuItemComponent,
        UserMenuComponent,
        SiteFooterComponent,
        DesktopShopMenuComponent,
        MobileShopMenuComponent,
        MegaMenuComponent,
        AdminMenuComponent,
        MyAccountMenuComponent,
        SearchBarComponent,
        CartsMenuComponent,
        ProductAddNotificationComponent,
        EnvironmentBannerComponent,
    ],
    imports: [
        SharedModule,
        RouterModule,
    ],
    exports: [
        LayoutComponent,
        AdminMenuComponent,
        MyAccountMenuComponent,
    ],
})
export class LayoutModule { }
