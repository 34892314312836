<div class="container">
    <div class="content-wrapper">
        <a class="copyright" [href]="copyrightUrl">© 2020 Lesley Cosmetics Mary Kay</a>
        <nav class="social-media">
            <a
                *ngFor="let item of socialMedia"
                [ngClass]="item.icon"
                [href]="item.url"
                target="_blank"
                [title]="item.title">
            </a>
        </nav>
    </div>
</div>
