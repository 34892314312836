import { Component, ViewEncapsulation } from '@angular/core';
import { ComponentType, ComponentPortal } from '@angular/cdk/portal';

@Component({
    selector: 'nc-dialog',
    template: `
        <nc-cut-corner-dialog>
            <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
        </nc-cut-corner-dialog>
    `,
    styleUrls: ['./dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogComponent {

    componentPortal: ComponentPortal<unknown>;

    set component(component: ComponentType<unknown>) {
        this.componentPortal = new ComponentPortal(component);
    }

}
