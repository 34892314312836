import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from './snack-bar.service';
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private snackBarService: SnackBarService,
        private zone: NgZone,
    ) {}

    handleError(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                window.localStorage.removeItem('jwt');
                if (environment.production) {
                    const pathname = this.trimLeadingSlashes(window.location.pathname);
                    const search = window.location.search;
                    const intranetUrl = this.trimTrailingSlashes(environment.intranetUrl);
                    const redirectUrl = `${intranetUrl}/webshop/${pathname}${search}`;
                    window.location.href = redirectUrl;
                } else {
                    this.snackBarService.error('Du är inte inloggad');
                }
            } else if (error.status >= 500) {
                this.zone.run(() => {
                    this.snackBarService.error();
                });
            }
        } else {
            console.error(error);
        }
    }

    protected trimLeadingSlashes (url: string) {
        return url.replace(/^\/+/, '');
    }

    protected trimTrailingSlashes (url: string) {
        return url.replace(/\/+$/, '');
    }

}
