<label *ngIf="label">{{label}}</label>
<div class="picker">
    <ul>
        <li *ngFor="let option of options">
            <button
                #buttonElement
                type="button"
                class="option"
                [ngClass]="{selected: isSelected(option)}"
                (click)="selectOption(option)">
                <i class="fal fa-check"></i>
                <span>{{option.label}}</span>
            </button>
        </li>
    </ul>
</div>
