<div class="user-col-1">
    <span class="title">{{config.user.title}}</span>
    <span class="name">{{config.user.name}}</span>
</div>
<div class="user-col-2">
    <div class="hover-menu market-menu" *ngIf="config.markets.length > 1">
        <span>{{selectedMarketLabel | translate}}</span>
        <div class="dropdown">
            <div class="box">
                <button (click)="changeMarket(market)" *ngFor="let market of config.markets">
                    {{market.label | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="hover-menu status-menu">
        <span>{{'profile.status.label' | translate}}: <i class="dot" [ngClass]="config.profileStatus.code.toLowerCase()"></i></span>
        <div class="dropdown">
            <div class="box">
                <p class="text">{{config.profileStatus.label | translate}}</p>
            </div>
        </div>
    </div>
</div>
<div class="user-col-3">
    <div class="hover-menu account-menu">
        <img src="../../../assets/images/{{profileImg}}" />
        <div class="dropdown">
            <div class="box">
                <ng-container *ngFor="let item of accountMenu">
                    <ng-container *ngIf="(config.isAdmin && item.limit_admin) || !item.limit_admin" [ngSwitch]="item.type">
                        <ng-container *ngSwitchCase="'local'">
                            <a
                                [class.is-admin]="item.limit_admin"
                                [routerLink]="item.data.url">
                                {{item.data.title | translate}}
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <a
                                [class.is-admin]="item.limit_admin"
                                [href]="convertUrl(item.data.url, item.type)">
                                {{item.data.title | translate}}
                            </a>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
