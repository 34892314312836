import { Component, forwardRef, Input, ElementRef, ViewChildren, QueryList, OnDestroy, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ProductOption } from "src/app/core/product.service";

@Component({
    selector: 'nc-model-picker',
    templateUrl: './model-picker.component.html',
    styleUrls: ['./model-picker.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ModelPickerComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ModelPickerComponent), multi: true }
    ]
})
export class ModelPickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

    constructor(
        private focusMonitor: FocusMonitor,
    ) {}

    selectedValue: string;

    @Input() options: ProductOption[] = [];
    @Input() label: string;

    @ViewChildren('buttonElement') buttonElements: QueryList<ElementRef>;

    propagateChange: (value: string) => void = () => { };
    validateFn: (control: FormControl) => void = () => { };
    onTouchedCallback: () => void = () => { };

    ngAfterViewInit() {
        this.buttonElements.forEach((element) => {
            this.focusMonitor.monitor(element);
        });
    }

    ngOnDestroy() {
        this.buttonElements.forEach((element) => {
            this.focusMonitor.stopMonitoring(element);
        });
    }

    selectOption(option: ProductOption) {
        this.selectedValue = option.id;
        this.propagateChange(option.id);
    }

    isSelected(option: ProductOption) {
        return this.selectedValue === option.id;
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }

    validate(control: FormControl) {
        return this.validateFn(control);
    }

    writeValue(value: string) {
        this.selectedValue = value;
    }

}
