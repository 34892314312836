<div *ngIf="challenges == null" class="loading">
    <mat-spinner class="button-spinner" diameter="24"></mat-spinner>
</div>
<div *ngIf="challenges !== null && challenges.length > 0" [class.icons]="icons">

    <div #ref><ng-content></ng-content></div>
    <div *ngIf="ref.childNodes.length == 0">
        <!-- Default header -->
        <h2>{{'challenge.my-current-challenges' | translate}}</h2>
    </div>


    <div class="section" *ngFor="let challenge of challenges">
        <div *ngIf="collapse"
             class="expand-toggle-button"
             [class.open]="challenge.isOpen"
             (click)="challenge.isOpen = !challenge.isOpen"
        >
            <span *ngIf="!challenge.isOpen">{{'toggle.show' | translate}}</span>
            <span *ngIf="challenge.isOpen">{{'toggle.hide' | translate}}</span>
        </div>
        <div class="icon" *ngIf="icons">
            <nc-mk-icon [icon]="challenge.icon"></nc-mk-icon>
        </div>
        <h3 class="fancy-heading">{{challenge.name}}</h3>
        <div class="toggle-area" [class.open]="challenge.isOpen || !collapse">
            <div class="text-blocks">
                <div *ngIf="challenge.end_date && challenge.start_date">
                    <strong class="heading">{{'challenge.qualifying-period' | translate}}</strong>
                    <span *ngIf="!challenge.period_text">
                        {{monthKey(challenge.start_date) | translate}} {{year(challenge.start_date)}}
                        <span *ngIf="showEndDate(challenge)">
                        → {{monthKey(challenge.end_date) | translate}} {{year(challenge.end_date)}}
                        </span>
                    </span>
                    <span *ngIf="challenge.period_text">
                        <span>{{challenge.period_text}}</span>
                        <span *ngIf="challenge.days_since_reg_show_end_date" class="period-text-end-date">
                            {{('challenge.period-text-end-date' | translate: {endDate: formatDate(challenge.days_since_reg_end_date)})}}
                        </span>
                    </span>
                </div>
                <div *ngIf="currentStep(challenge)">
                    <strong class="heading">{{'challenge.current-step' | translate}}</strong>
                    <span>{{currentStep(challenge)?.name}}</span>
                </div>
                <div *ngIf="nextStep(challenge)">
                    <strong class="heading">{{'challenge.next-step' | translate}}</strong>
                    <span>{{nextStep(challenge)?.name}}</span>
                </div>
            </div>
            <div class="text-blocks">
                <div *ngIf="hasSumRequirement(challenge)">
                    <strong class="heading">{{'challenge.purchases' | translate}}</strong>
                    <span>{{challenge.currentSum | ncCurrency}}</span>
                </div>
                <div *ngIf="hasSumRequirement(challenge)">
                    <strong class="heading">{{'challenge.purchases.invoiced' | translate}}</strong>
                    <span>{{challenge.currentInvoicedSum | ncCurrency}}</span>
                </div>
                <div *ngIf="hasRecruitmentRequirement(challenge)">
                    <strong class="heading">{{'challenge.new-members' | translate}}</strong>
                    <span>{{challenge.currentRecruitments}}</span>
                </div>
                <div class="size-3" *ngIf="nextStep(challenge)">
                    <strong class="heading">{{'challenge.conditions' | translate}}</strong>
                    <ul>
                        <li *ngIf="nextStep(challenge)?.requiredSum"
                            [class.checked]="challenge.currentSum >= nextStep(challenge)?.requiredSum">
                            {{'challenge.min-purchases' | translate:({value: nextStep(challenge)?.requiredSum | ncCurrency})}}
                        </li>
                        <li *ngIf="nextStep(challenge)?.requiredRecruitments"
                            [class.checked]="challenge.currentRecruitments >= nextStep(challenge)?.requiredRecruitments">
                            {{'challenge.req-new-members' | translate:({value: nextStep(challenge)?.requiredRecruitments})}}
                        </li>
                    </ul>
                    <ng-container *ngIf="nextStep(challenge)?.requiredSum2 || nextStep(challenge)?.requiredRecruitments2">
                        <strong class="heading">{{'challenge.conditions-secondary' | translate}}</strong>
                        <ul>
                            <li *ngIf="nextStep(challenge)?.requiredSum2"
                                [class.checked]="challenge.currentSum >= nextStep(challenge)?.requiredSum2">
                                {{'challenge.min-purchases' | translate:({value: nextStep(challenge)?.requiredSum2 | ncCurrency})}}
                            </li>
                            <li *ngIf="nextStep(challenge)?.requiredRecruitments2"
                                [class.checked]="challenge.currentRecruitments >= nextStep(challenge)?.requiredRecruitments2">
                                {{'challenge.req-new-members' | translate:({value: nextStep(challenge)?.requiredRecruitments2})}}
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="text-blocks">
            <div class="challenge-step-remaining size-3" *ngIf="nextStep(challenge) as nextStep;">
                <ng-container *ngIf="nextStep">{{'challenge.progress-next-step.steps-left-label' | translate}}</ng-container>

                <strong *ngIf="nextStep.sumLeft && nextStep.recruitsLeft">
                    {{'challenge.progress-next-step.sum-and-recruits-left' | translate:({
                        sum: nextStep.sumLeft | ncCurrency,
                        recruits: nextStep.recruitsLeft
                    })}}
                </strong>
                <strong *ngIf="nextStep.sumLeft && (!nextStep.recruitsLeft)">
                    {{nextStep.sumLeft | ncCurrency}}
                </strong>
                <strong *ngIf="!nextStep.sumLeft && nextStep.recruitsLeft">
                    {{'challenge.progress-next-step.recruits-left' | translate:({recruits: nextStep.recruitsLeft})}}
                </strong>

                <span *ngIf="(nextStep.sumLeft || nextStep.recruitsLeft) && (nextStep.sumLeft2 || nextStep.recruitsLeft2)">
                    {{'challenge.conditions-secondary' | translate}}
                </span>

                <ng-container *ngIf="nextStep.sumLeft2 || nextStep.recruitsLeft2">
                    <strong *ngIf="nextStep.sumLeft2 && nextStep.recruitsLeft2">
                        {{'challenge.progress-next-step.sum-and-recruits-left' | translate:({
                            sum: nextStep.sumLeft2 | ncCurrency,
                            recruits: nextStep.recruitsLeft2
                        })}}
                    </strong>
                    <strong *ngIf="nextStep.sumLeft2 && !nextStep.recruitsLeft2">
                        {{nextStep.sumLeft2 | ncCurrency}}
                    </strong>
                    <strong *ngIf="!nextStep.sumLeft2 && nextStep.recruitsLeft2">
                        {{'challenge.progress-next-step.recruits-left' | translate:({recruits: nextStep.recruitsLeft2})}}
                    </strong>
                </ng-container>
            </div>
        </div>

        <nc-stepped-progress-bar
            [steps]="challenge.steps"
        ></nc-stepped-progress-bar>
        <!--
        <a
            class="read-more"
            nc-button
            buttonAppearance="cut_corner"
            buttonIcon="download"
            [buttonText]="'product.read-more' | translate">
        </a>
         -->
    </div>
</div>
