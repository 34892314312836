export const adminMenuItems = [
    {
        path: '/',
        title: '‹ Tillbaka till startsidan',
    },
    {
        path: '/administration',
        title: 'Order',
    },
    {
        path: '/administration/order/confirmation-emails',
        title: 'Orderbekräftelser',
    },
    {
        path: '/administration/order/pcp-order',
        title: 'PCP-order',
    },
    {
        path: '/administration/result-months',
        title: 'Resultatmånader',
    },
    {
        path: '/administration/categories',
        title: 'Categories',
    },
    {
        path: '/administration/shopping-carts',
        title: 'Varukorgar',
    },
    {
        path: '/administration/coupons',
        title: 'Kuponger',
    },
    {
        path: '/administration/campaigns',
        title: 'Kampanjer/Bonus',
    },
    {
        path: '/administration/campaign-rewards',
        title: 'Kampanjer/Bonus-produkter',
    },
    {
        path: '/administration/challenges',
        title: 'Utmaningar',
    },
    {
        path: '/administration/challenge-rewards',
        title: 'Utmaningsbelöningar',
    },
    {
        path: '/administration/shipping-options',
        title: 'Fraktsätt',
    },
    {
        path: '/administration/consultant-contract/new',
        title: 'Avtal - Nya kunder',
    },
    {
        path: '/administration/consultant-contract/approved',
        title: 'Avtal - Godkända kunder',
    },
    {
        path: '/administration/consultant-contract/removed',
        title: 'Avtal - Raderade kunder',
    },
    {
        path: '/administration/stock-monitoring',
        title: 'Lagerbevakning',
    },
    {
        path: '/administration/partial-payment',
        title: 'Slutbetalningar för Dräkter',
    },
    {
        path: '/administration/settings-start-kit',
        title: 'Produktförslag första ordern',
    },
    {
        path: '/administration/activation-order-and-discounts',
        title: 'Rabattsatser',
    },
    {
        path: '/administration/settings',
        title: 'Inställningar',
    },
    {
        path: '/administration/settings/mail-and-sms',
        title: 'Mail- och SMS-inställningar',
    },
    {
        path: '/administration/settings/product-sync',
        title: 'Produktsynk-inställningar',
    },
    {
        path: '/administration/actions',
        title: 'Extra',
    },
];
