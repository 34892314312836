import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nc-value-check-information',
  templateUrl: './value-check-information.component.html',
  styleUrls: ['./value-check-information.component.scss']
})
export class ValueCheckInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
