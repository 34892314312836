<div *ngIf="loaded">
    <nc-quantity-selector
        [label]="this.label"
        [quantity]="quantity"
        (selectedQuantity)="onSelectedQuantityChange($event)"
        minQuantity="0"
        [maxQuantity]="this.maxQuantity"
        [enableFadeWhenEmpty]="this.enableFadeWhenEmpty"
        [disabled]="this.disabled"
    ></nc-quantity-selector>
</div>
