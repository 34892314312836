import {
    Component,
    forwardRef,
    Input,
    ElementRef,
    ViewChildren,
    QueryList,
    Output,
    EventEmitter,
    OnDestroy,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';

import { ProductVariation, ProductVariationGroup } from 'src/app/core/product.service';

@Component({
    selector: 'nc-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorPickerComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ColorPickerComponent), multi: true }
    ]
})
export class ColorPickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit {

    constructor(
        private focusMonitor: FocusMonitor,
    ) {

    }

    selectedValue: number;

    @Input() options: ProductVariationGroup[] = [];
    @Input() label: string;
    @Input() initialSelectedVariant?: ProductVariation;

    @Output() changeVariation: EventEmitter<ProductVariation> = new EventEmitter();

    @ViewChildren('buttonElement') buttonElements: QueryList<ElementRef>;

    propagateChange: (value: number) => void = () => { };
    validateFn: (control: FormControl) => void = () => { };
    onTouchedCallback: () => void = () => { };

    ngOnInit() {
        if (this.initialSelectedVariant) {
            // Selecting a choice/variant will emit changes to parent components and throw ExpressionChangedAfterItHasBeenCheckedError.
            // Delaying selection with setTimeout(...) will not trigger error.
            // @link: https://angular.io/errors/NG0100
            setTimeout(() => {
                this.selectChoice(this.initialSelectedVariant);
            }, 0);
        }
    }

    ngAfterViewInit() {
        this.buttonElements.forEach((element) => {
            this.focusMonitor.monitor(element);
        });
    }

    ngOnDestroy() {
        this.buttonElements.forEach((element) => {
            this.focusMonitor.stopMonitoring(element);
        });
    }

    selectChoice(choice: ProductVariation) {
        this.selectedValue = choice.product.id;
        this.propagateChange(choice.product.id);
        this.changeVariation.emit(choice);
    }

    isSelected(choice) {
        return this.selectedValue === choice.product.id;
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }

    validate(control: FormControl) {
        return this.validateFn(control);
    }

    writeValue(value: number) {
        this.selectedValue = value;
    }

    hasPinkSaleOptions(options: ProductVariationGroup[]): boolean    {
        for (const option of options) {
            for (const choice of (option.choices || [])) {
                if (choice.product.pinkSale) {
                    return true;
                }
            }
        }

        return false;
    }
}
