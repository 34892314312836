import { Component, Input, OnInit } from '@angular/core';
import { marketToCountryCode, marketToCurrency } from "../../helpers/market";

@Component({
    selector: 'nc-flag-language-image',
    templateUrl: './flag-language-image.component.html',
    styleUrls: ['./flag-language-image.component.scss']
})
export class FlagLanguageImage implements OnInit {

    @Input() languageCode: string;

    constructor() {
    }

    ngOnInit(): void {
    }
}
