<div class="container">
    <div class="columns">
        <div class="level-1">
            <span
                class="show-all"
                (click)="selectItem.emit(menu)">
                {{'products.show-all' | translate}} {{menu.data.title}}<i class="fal fa-chevron-right"></i>
            </span>
            <ul class="category-grid">
                <li
                    *ngFor="let child of menu.children; let index=index"
                    [class.active]="activeIndex==index"
                    [class.current]="isCurrentPath(child)"
                    [class.is-admin]="child.limit_admin"
                    (click)="onClickItem(child, index)">
                    <div class="image-wrapper">
                        <div class="image" [ngStyle]="{'background-image': getBackgroundImage(child)}"></div>
                    </div>
                    <span>{{child.data.title}}</span>
                </li>
            </ul>
        </div>
        <div class="level-2" *ngIf="menu.children[activeIndex]">
            <span
                class="show-all"
                (click)="selectItem.emit(menu.children[activeIndex])">
                {{'products.show-all' | translate}} {{menu.children[activeIndex].data.title}}<i class="fal fa-chevron-right"></i>
            </span>
            <ul class="category-grid">
                <li
                    *ngFor="let child of menu.children[activeIndex].children; let index=index"
                    [class.current]="isCurrentPath(child)"
                    [class.is-admin]="child.limit_admin"
                    (click)="onClickItem(child, index)">
                    <div class="image-wrapper">
                        <div class="image" [ngStyle]="{'background-image': getBackgroundImage(child)}"></div>
                    </div>
                    <span>{{child.data.title}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
