import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartService } from "src/app/shared/shopping-cart/shopping-cart.service";
import { SnackBarService } from "src/app/core/snack-bar.service";
import { SubSink } from "subsink";

@Component({
    selector: 'nc-product-add-notification',
    templateUrl: './product-add-notification.component.html',
    styleUrls: ['./product-add-notification.component.scss']
})
export class ProductAddNotificationComponent implements OnInit, OnDestroy {

    subs = new SubSink();

    constructor(
        protected shoppingCartService: ShoppingCartService,
        protected snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.subs.sink = this.shoppingCartService.onProductAdd.subscribe(() => {
            this.snackBarService.success('shopping-cart.added-product-message');
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

}
