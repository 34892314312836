import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nc-mk-icon',
    templateUrl: './mk-icon.component.html',
    styleUrls: ['./mk-icon.component.scss']
})
export class MkIconComponent implements OnInit {


    @Input()
    icon = '';

    constructor() {
    }

    ngOnInit(): void {
    }

}
