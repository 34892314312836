import { Component, Input } from '@angular/core';
import { AppConfig } from 'src/app/core/config.service';
import { BackendService } from 'src/app/core/backend.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'nc-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent {

    constructor(
        private backendService: BackendService,
    ) {}

    @Input() menu;
    @Input() config: AppConfig;

    logoUrl = this.backendService.getIntranetUrl('/');

}
