import { Injectable } from "@angular/core";

/**
 * Show snow flakes falling down on the page.
 */

@Injectable({
    providedIn: 'root',
})
export class SnowFlakesService {

    showSnowFlakesInDecember() {
        // Show only snowflakes in December.
        if ((new Date()).getMonth() === 11) {
            setTimeout(() => this.showSnowFlakes(), 0);
        }
    }

    protected showSnowFlakes() {
        const flurryLib = document.createElement('script');
        flurryLib.async = true;
        flurryLib.src = 'assets/jquery.flurry.js';
        flurryLib.onload = () => {
            const flurryInit = document.createElement('script');
            flurryInit.innerHTML = `
                const $el = jQuery('mat-sidenav-content');
                $el.flurry({
                    character: "❄",
                    color: "white",
                    frequency: 100,
                    speed: 3000,
                    small: 8,
                    large: 28,
                    wind: 40,
                    windVariance: 20,
                    rotation: 90,
                    rotationVariance: 180,
                    startOpacity: 1,
                    endOpacity: 0,
                    opacityEasing: "cubic-bezier(1,.3,.6,.74)",
                    blur: true,
                    overflow: "hidden",
                    zIndex: 9999
                });
                $el.flurry({
                    onInit: function() {},
                    onDestroy: function() {},
                });
            `;
            document.head.appendChild(flurryInit);
        };
        document.head.appendChild(flurryLib);
    }

}
