<button class="toggle-button" (click)="toggleMenu()"><i class="fal fa-list"></i></button>
<ul class="right-menu">
    <li>
        <a (click)="openSearch()" title="{{'search-bar.placeholder' | translate}}">
            <i class="fal fa-search"></i>
        </a>
    </li>
    <li>
        <a routerLink="/my-account" title="{{'my-account.my-account' | translate}}">
            <i class="fal fa-user"></i>
        </a>
    </li>
    <li>
        <a routerLink="/my-account/coupons" title="{{'my-account.coupons' | translate}}">
            <i class="fal fa-tag"></i>
            <span class="badge badge-animate badge-accentuete" *ngIf="cartsCouponQuantity() > 0">{{cartsCouponQuantity()}}</span>
        </a>
    </li>
    <li>
        <a routerLink="favourites" routerLinkActive="active">
            <i class="fal fa-heart" title="{{'favourites.favourites' | translate}}"></i>
        </a>
    </li>
    <nc-carts-menu></nc-carts-menu>
</ul>
<nc-search-bar (closeSearch)="searchVisible = false" *ngIf="searchVisible"></nc-search-bar>
<nav class="menu-root" *ngIf="firstLevelIsOpen">
    <ul>
        <li class="menu-item" *ngFor="let item of menu">
            <a
                class="label"
                *ngIf="shouldDisplayAnchor(item)"
                (click)="closeFirstLevel()"
                [routerLink]="item.data.url"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: item.data.url === '/'}"
            >
                {{item.data.title}}
            </a>
            <span
                class="label"
                *ngIf="!shouldDisplayAnchor(item)"
                (click)="selectFirstLevelItem(item)">
                {{item.data.title}}
            </span>
        </li>
    </ul>
</nav>
<nc-mega-menu
    *ngIf="selectedFirstLevelItem"
    [menu]="selectedFirstLevelItem"
    (selectItem)="selectSecondLevelItem($event)">
</nc-mega-menu>
