
export interface MyAccountMenuItem {
    path: string;
    target?: MyAccountMenuItemTarget;
    title: string;
    icon: 'box'|'tag';
}

export enum MyAccountMenuItemTarget {
    INTRANET = 'INTRANET',
}

export const myAccountMenuItems: MyAccountMenuItem[] = [
    {
        path: '/my-account/orders',
        title: 'my-account.my-orders',
        icon: 'box',
    },
    {
        path: '/my-account/consumer-orders',
        title: 'my-account.customer-orders',
        icon: 'box',
    },
    {
        path: '/my-account/coupons',
        title: 'my-account.coupons',
        icon: 'tag',
    },
    {
        path: '/my-account/shipping-address',
        title: 'my-account.shipping-address',
        icon: 'tag',
    },
    {
        path: '/tools/order-confirmations',
        target: MyAccountMenuItemTarget.INTRANET,
        title: 'my-account.customer-orders.old-orders',
        icon: 'box',
    },
];
