import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { AppConfig, ConfigService } from 'src/app/core/config.service';
import { ACCOUNT_MENU } from '../account-menu-items';
import { BackendService } from 'src/app/core/backend.service';
import { MenuItem } from 'src/app/core/menu.service';

@Component({
    selector: 'nc-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})

export class UserMenuComponent implements OnInit, OnDestroy {
    constructor(
        private backendService: BackendService,
        private configService: ConfigService,
    ) { }

    @Input() config: AppConfig;
    subs = new SubSink();
    accountMenu: MenuItem[] = ACCOUNT_MENU;
    selectedMarketLabel;
    profileImg?: string = null;

    ngOnInit() {
        this.profileImg = this.getProfileImg(this.config.customer.title_id);
        this.subs.sink = this.configService.localeChanges.subscribe((market) => {
            this.selectedMarketLabel = 'language.' + market.toLowerCase();
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    public changeMarket(market) {
        this.backendService.post('user/market/set', {market: market.code}).subscribe((result) => {
            this.configService.updateConfig(result.config);

            // Removed for now. Add again if things are not refreshing properly
            // location.reload();
        });
    }

    convertUrl(url, type?: string) {
        switch (type) {
            case 'backend':
                return this.backendService.getBackendRootUrl(url);
            default:
                return this.backendService.getIntranetUrl(url);
        }
    }

    getProfileImg(titleId) {
        if      (titleId >= 120) { return 'profile-level-120.png'; }
        else if (titleId >= 60)  { return 'profile-level-60.png';  }
        else if (titleId >= 50)  { return 'profile-level-50.png';  }
        else if (titleId >= 40)  { return 'profile-level-40.png';  }
        else                     { return 'profile-level-10.png';  }
    }
}
