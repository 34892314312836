import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
    selector: 'nc-cms-coupons',
    templateUrl: './coupons.component.html',
    styleUrls: ['./coupons.component.scss']
})
export class CmsCouponsComponent implements OnInit, OnDestroy {

    couponCount?: number;

    constructor(
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    onNewCouponCountEvent(couponCount: number) {
        this.couponCount = couponCount;
    }
}
