<mat-form-field appearance="fill" *ngIf="!pending">
    <mat-label>{{'product-configurable.select-product' | translate}}</mat-label>
    <mat-select (selectionChange)="onChange($event.value)" [(value)]="value">
        <mat-option *ngFor="let child of item.rootProduct.configurableChildren" [value]="child.id">
            <div class="img-wrapper">
                <nc-product-image [product]="child"></nc-product-image>
            </div>
            <div class="text">
                <div class="name">
                    {{child.name}}
                </div>
                <div class="price">
                    <nc-product-price [product]="child"></nc-product-price>
                </div>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>

<div *ngIf="pending" class="loader">
    <mat-spinner class="quantity-spinner" diameter="16"></mat-spinner>
</div>
