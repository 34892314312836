<button type="button" (click)="scrollBy(-200)" class="up-btn" [ngClass]="{hidden: atTop}">
    <span><i class="fas fa-chevron-up"></i></span>
</button>
<div class="scroll-area" (scroll)="onScroll()" #navigationScroll>
    <ul class="thumbnails">
        <li *ngFor="let slide of slides; let slideIndex = index; trackBy: trackByUrl;" [class.is-active]="slideIndex === activeIndex" (click)="select(slideIndex)">
            <img *ngIf="slide.type === 'image'" [src]="slide.url" />
            <video *ngIf="slide.type === 'video'" [src]="slide.url"></video>
            <img *ngIf="slide.type === 'youtube'" [src]="'https://img.youtube.com/vi/' + slide.key + '/maxresdefault.jpg'" />
        </li>
    </ul>
</div>
<button type="button" (click)="scrollBy(200)" class="down-btn" [ngClass]="{hidden: atBottom}">
    <span><i class="fas fa-chevron-down"></i></span>
</button>
