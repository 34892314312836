<div [formGroup]="parentForm">
    <div formGroupName="metaData">
        <nc-model-picker
            *ngIf="modelOptions.length > 0"
            class="model"
            [label]="'product.model' | translate"
            [options]="modelOptions"
            formControlName="model">
        </nc-model-picker>
        <mat-form-field class="size" *ngIf="sizeOptions.length > 0">
            <mat-label>{{'product.size' | translate}}</mat-label>
            <mat-select formControlName="size">
                <mat-option *ngFor="let option of sizeOptions" [value]="option.id">{{option.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

