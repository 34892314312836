import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ProductImage } from 'src/app/core/product.service';

@Component({
    selector: 'nc-product-image-list',
    template: `
        <ul>
            <li
                *ngFor="let image of visibleImages; let imageIndex = index;"
                [ngClass]="{'plus-more': imageIndex === imageLimit - 1 && hiddenImages.length}">
                <img [src]="image.url" />
                <span *ngIf="imageIndex === imageLimit - 1 && hiddenImages.length">+ {{hiddenImages.length}}</span>
            </li>
        </ul>
    `,
    styleUrls: ['./product-image-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductImageListComponent {

    visibleImages: ProductImage[] = [];
    hiddenImages: ProductImage[] = [];
    imageLimit = 4;

    @Input() set images(images: ProductImage[]) {
        this.visibleImages = images.slice(0, this.imageLimit);
        this.hiddenImages = images.slice(this.imageLimit);
    }

}
