import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Product } from '../../../core/product.service';
import {
    ShoppingCartCollection,
    ShoppingCartService,
} from '../../shopping-cart/shopping-cart.service';
import { SubSink } from 'subsink';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'nc-product-dynamic-quantity-selector',
    templateUrl: './product-dynamic-quantity-selector.component.html',
    styleUrls: ['./product-dynamic-quantity-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ProductDynamicQuantitySelectorComponent,
        },
    ],
})
export class ProductDynamicQuantitySelectorComponent
    implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
    @Input() product: Product;
    @Input() childId: number = null;
    @Input() label: string;
    @Input() autoSubmit = true;
    @Input() enableFadeWhenEmpty: boolean = true;
    @Input() maxQuantity: number = 1000000;
    @Input() disabled = false;

    touched = false;

    quantity;
    loaded = false;

    subs = new SubSink();

    newQuantity = new Subject<number>();
    private cartCollection: ShoppingCartCollection;

    onChange = (quantity) => {};
    onTouched = () => {};

    constructor(private cartService: ShoppingCartService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.childId) {
            this.cartsOrChildIdChanged();
        }
    }

    ngOnInit(): void {
        this.subs.sink = this.cartService.shoppingCartChanges.subscribe((cartCollection) => {
            this.cartCollection = cartCollection;
            this.cartsOrChildIdChanged();
        });

        this.newQuantity.pipe(debounceTime(400)).subscribe((quantity) => {
            if (this.autoSubmit) {
                this.cartService.updateQuantity({
                    productType: this.product.type,
                    productId: this.product.id,
                    childId: this.childId,
                    quantity,
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onSelectedQuantityChange(quantity: number) {
        this.markAsTouched();
        this.setQuantity(quantity);
        this.newQuantity.next(quantity);
    }

    cartsOrChildIdChanged() {
        if (!this.cartCollection?.isInitialized()) {
            return;
        }

        const item = this.cartCollection.findItemForProduct(this.product, this.childId);
        this.setQuantity(item?.quantity || 0);
        this.loaded = true;
    }

    setQuantity(quantity: number) {
        this.quantity = quantity;
        this.onChange(this.quantity);
    }

    writeValue(quantity: number) {
        // Exists to fulfill ControlValueAccessor.
        // We are getting the value from the shopping cart item's product.
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
