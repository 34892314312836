<div class="ribbons"
    *ngIf="product.discountLabel || product.fixedDiscountLabel || product.pinkSale"
    [class.ribbons]="!listView"
    [class.ribbons-list-view]="listView"
>
    <div class="discount-label" *ngIf="product.discountLabel">
        <span>{{product.discountLabel}}</span>
    </div>
    <div class="fixed-discount-label" *ngIf="product.fixedDiscountLabel">
        <span>{{product.fixedDiscountLabel}}</span>
    </div>
    <div class="pink-sale" *ngIf="product.pinkSale">
        <span>{{'category.pink-sale' | translate}}</span>
    </div>
</div>
