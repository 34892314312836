<div>
    <h1 class="heading">{{getProduct().name}}</h1>
    <nc-product-subtitle [product]="getProduct()" *ngIf="!isCompactView()"></nc-product-subtitle>
    <p class="price" *ngIf="this.product.type !== 'look'"><nc-product-price [product]="getProduct()"></nc-product-price></p>

    <div *ngIf="!isCompactView() && getProduct().description && getProduct().description !== '-'">
        <p class="description-heading">{{'product.description' | translate}}</p>
        <div class="description" [innerHTML]="getProduct().description"></div>
    </div>
    <div *ngIf="!isCompactView() && getProduct().volume">
        <p class="description-heading">{{'product.volume' | translate}}</p>
        <div class="description" [innerHTML]="getProduct().volume"></div>
    </div>
    <div *ngIf="!isCompactView() && getProduct().bundledProducts && getProduct().bundledProducts.length > 0" class="bundled-products">
        <h3>{{'product.bundled-products' | translate}}</h3>
        <ul>
            <li *ngFor="let bundledProduct of getProduct().bundledProducts">
                {{bundledProduct.name}}
                <span class="bundled-product-quantity">x {{bundledProduct.quantity}}</span>
            </li>
        </ul>
    </div>
    <div  class="document-wrapper" *ngIf="!isCompactView() && getProductDocuments().length">
        <button
            *ngFor="let doc of getProductDocuments()"
            nc-button
            buttonAppearance="cut_corner"
            buttonIcon="download"
            (click)="openDocument(doc)"
            [buttonText]="'product.read-more' | translate">
        </button>
    </div>
    <p class="sold-out-text" *ngIf="getProduct().isDiscontinued">
        <i class="fal fa-exclamation-circle"></i>{{'product.discontinued' | translate}}
    </p>
    <form [formGroup]="form" (ngSubmit)="addToShoppingCart()" *ngIf="shouldDisplayForm()">
        <nc-product-dress-form
            *ngIf="product.type=='dress'"
            [product]="product"
        ></nc-product-dress-form>
        <nc-product-ticket-form
            *ngIf="product.type=='ticket'"
            [product]="product"
        ></nc-product-ticket-form>
        <nc-product-pcp-form
            *ngIf="product.type=='pcp'"
            [product]="product"
        ></nc-product-pcp-form>

        <nc-color-picker
            class="variation"
            *ngIf="product.type=='wrapper'"
            [label]="product.variationLabel"
            [options]="product.children"
            formControlName="childId"
            [initialSelectedVariant]="this.initialSelectedVariant"
            (changeVariation)="onChangeVariation($event)">
        </nc-color-picker>

        <!-- NOTE: remove "nc-quantity-selector" when Robert is ready. Tasks: MK-1082, MK-1036 -->
<!--        <nc-quantity-selector-->
<!--            *ngIf="showQuantitySelector()"-->
<!--            class="quantity"-->
<!--            [label]="'product.quantity' | translate"-->
<!--            formControlName="quantity">&lt;!&ndash; @todo Change simple to PCP &ndash;&gt;-->
<!--        </nc-quantity-selector>-->


        <!-- NOTE: Uncomment "nc-product-dynamic-quantity-selector" when Robert is ready. Tasks: MK-1082, MK-1036 -->
        <nc-product-dynamic-quantity-selector
            #QuantitySelector
            *ngIf="showQuantitySelector()"
            formControlName="quantity"
            [label]="'product.quantity' | translate"
            [product]="this.product"
            [childId]="this.selectedVariation?.product.id || null"
            [maxQuantity]="getMaxQuantity(getProduct())"
            [autoSubmit]="false"
            [enableFadeWhenEmpty]="false"
            class="quantity"><!-- @todo Change simple to PCP -->
        </nc-product-dynamic-quantity-selector>

        <div class="terms-checkbox-wrapper" *ngIf="product.type=='webpage'">
            <mat-checkbox formControlName="termsAgreement">
                {{'product.i-agree-to-the-terms' | translate}}
            </mat-checkbox>
            <span class="terms-button"><a (click)="openTerms()">{{'product.read-terms' | translate}}</a></span>
        </div>
        <nc-product-out-of-stock [product]="getProduct()"></nc-product-out-of-stock>

        <p class="requirement-not-fulfilled" *ngIf="getProduct().isActivatingOrderRequired">
            <i class="fal fa-exclamation-circle"></i>{{'product.requires-activating-order' | translate}}
        </p>

        <p class="requirement-not-fulfilled" *ngIf="!isRequiredPurchaseFulfilled(getProduct())">
            <nc-product-error-message>
                <a href="{{getProductUrl(product.requiredPurchaseProduct)}}">{{'product.requires-purchase-of-other-product' | translate:({ sku: getProduct().requiredPurchaseProduct?.sku })}}</a>
            </nc-product-error-message>
        </p>

        <div class="button-wrapper" *ngIf="showActions">
            <button
                *ngIf="isCompactView()"
                type="button"
                nc-favourite-button
                buttonAppearance="outlined"
                [productId]="product.id"
            ></button>

            <nc-stock-monitor-button
                *ngIf="getProduct().stockMonitoringIsActive"
                [product]="getProduct()"
            ></nc-stock-monitor-button>

            <div *ngIf="!getProduct().stockMonitoringIsActive">
                <button
                    nc-button
                    class="add-to-cart-button"
                    [disabled]="isProductAddButtonDisabled()"
                    [buttonPending]="addToShoppingCartPending"
                    [buttonText]="getProductAddButtonLabel(getProduct()) | translate"
                ></button>
            </div>
        </div>
    </form>
</div>
