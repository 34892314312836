import { Component, Input, OnInit } from '@angular/core';
import { Challenge, ChallengeStep } from "src/app/core/challenge.service";

interface StepData {
    name: string;
    completed?: boolean;
    prevCompleted?: boolean;
    visible?: boolean;
    progress?: number; // Value between 0 and 1
}

@Component({
    selector: 'nc-stepped-progress-bar',
    templateUrl: './stepped-progress-bar.component.html',
    styleUrls: ['./stepped-progress-bar.component.scss']
})
export class SteppedProgressBarComponent implements OnInit {

    _steps: StepData[];
    get steps(): StepData[] {
        return this._steps;
    }

    @Input() set steps(steps: StepData[]) {
        this._steps = steps.filter((s) => s.visible !== false);
        this.gradientBefore = steps[0].visible === false
        this.gradientAfter = steps[steps.length - 1].visible === false
    }

    gradientBefore: boolean;
    gradientAfter: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    completedSteps() {
        let count = 0;
        for (let step of this.steps) {
            if (step.completed) {
                count++;

            } else {
                return count;
            }
        }

        return count;
    }

    previouslyCompletedSteps() {
        let count = 0;
        for (let step of this.steps) {
            if (step.prevCompleted) {
                count++;

            } else {
                return count;
            }
        }

        return count;
    }

    progress() {
        const barSize = this.completedSteps() + this.progressOfCurrentStep();
        const length = (this.steps.length) || 1;

        return (barSize / length) * 100;
    }

    private progressOfCurrentStep() {
        for (let step of this.steps) {
            if (!step.completed && step.progress > 0) {
                return step.progress;
            }
        }

        return 0;
    }

    progressPrevious() {
        const barSize = this.previouslyCompletedSteps();
        const length = (this.steps.length) || 1;

        return (barSize / length) * 100;
    }

    stepStyles(index: number, step: {name: string; completed: boolean}) {
        const barSize = this.completedSteps();
        const length = (this.steps.length) || 1;

        return {
            left: (index / length * 100) + '%',
            width: (1 / length * 100) + '%',
            color: barSize > index ? '#fff' : '#000',
        }
    }
}
