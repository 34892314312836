import { Component, Input } from '@angular/core';
import { Product } from "src/app/core/product.service";

@Component({
    selector: 'nc-product-image-ribbons',
    templateUrl: './product-image-ribbons.component.html',
    styleUrls: ['./product-image-ribbons.component.scss']
})
export class ProductImageRibbonsComponent {
    @Input() product: Product;
    @Input() listView: boolean = false;
}
