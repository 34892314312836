import { Component, OnInit, OnDestroy, Input, HostBinding, AfterViewInit, AfterViewChecked } from '@angular/core';
import { SubSink } from 'subsink';

import { ShoppingCartService } from './shopping-cart.service';
import { BonusProductsCampaignData, ShoppingCartData, ShoppingCartItem } from 'src/app/core/shopping-cart-data.service';
import { SnackBarService } from 'src/app/core/snack-bar.service';
import { LayoutService } from 'src/app/layout/layout.service';

enum ShoppingCartAppearance {
    STANDARD = 'standard',
    SIMPLE = 'simple',
}

@Component({
    selector: 'nc-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    shoppingCart: ShoppingCartData;

    @Input() displayCheckoutButton = false;
    @Input() shoppingCartAppearance = ShoppingCartAppearance.STANDARD;

    @Input() forcePrintView = false;


    @HostBinding('class.appearance--standard') get standardClass() {
        return this.shoppingCartAppearance === ShoppingCartAppearance.STANDARD;
    }

    constructor(
        private shoppingCartService: ShoppingCartService,
        private snackBarService: SnackBarService,
        private layoutService: LayoutService,
    ) { }

    ngOnInit() {
        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe((cartCollection) => {
            this.shoppingCart = cartCollection.getSelectedCart();

            let defaultCart = cartCollection.getCart('default');
            if (defaultCart) {
                if (defaultCart.removed.length) {
                    this.snackBarService.info('shopping-cart.items-has-been-removed');
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    trackByItemId(index, item) {
        return item.id;
    }

    closeShoppingCart() {
        this.layoutService.closeShoppingCart();
    }

    unusedCoupons() {
        return this.shoppingCart?.coupons.filter((coupon) => !coupon.is_in_cart) || []
    }

    getMaxQuantityForBonusProduct(item: ShoppingCartItem): number|null {
        const bonusProductMaxQuantity = this.getBonusProductMaxQuantity(item);
        if (bonusProductMaxQuantity) {
            return bonusProductMaxQuantity;
        }

        const bonusProductWithLevelsMaxQuantity = this.getBonusProductWithLevelsMaxQuantity(item);
        if (bonusProductWithLevelsMaxQuantity) {
            return bonusProductWithLevelsMaxQuantity;
        }

        return null;
    }

    getBonusProductMaxQuantity(item: ShoppingCartItem): number|null {
        const bonusCampaigns = this.shoppingCart.bonusCampaigns;

        if (item.bonus_campaign_id && bonusCampaigns.length > 0) {
            const bonusCampaign = bonusCampaigns.find((campaign) => campaign.campaign.id == item.bonus_campaign_id);
            if (bonusCampaign?.perTrigger) {
                // To get max available/selectable quantity for current bonus product:
                // Take maxCount, deduct combined quantity of all selected products, then re-add current item quantity.
                return bonusCampaign.maxCount - bonusCampaign.selectedQuantity + item.quantity;
            }
        }

        return null;
    }

    getBonusProductWithLevelsMaxQuantity(item: ShoppingCartItem): number|null {
        const bonusWithLevelsCampaigns = this.shoppingCart.bonusCampaignsWithLevels;

        if (item.bonus_with_levels_campaign_id && bonusWithLevelsCampaigns.length > 0) {
            const bonusWithLevelsCampaign = bonusWithLevelsCampaigns.find((campaign) => campaign.campaign.id === item.bonus_with_levels_campaign_id);
            if (bonusWithLevelsCampaign) {
                // To get max available/selectable quantity for current bonus product:
                // Take maxCount, deduct combined quantity of all selected products, then re-add current item quantity.
                return bonusWithLevelsCampaign.maxCount - bonusWithLevelsCampaign.selectedQuantity + item.quantity;
            }
        }

        return null;
    }

    allItemsAreLocked() {
        return this.shoppingCart?.type === 'final-payment';
    }

    showPrintCartButton(): boolean {
        return Boolean(window.print) || false;
    }

    printCart() {
        const width = screen.width * 0.7;
        const height = screen.height * 0.9;

        const WinPrint = window.open(`/print/shopping-cart?cartId=${this.shoppingCart.id}`, 'print', `width=${width},height=${height}`);
        WinPrint.focus();
    }
}
