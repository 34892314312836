import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartItem } from "src/app/core/shopping-cart-data.service";
import { ShoppingCartService } from "src/app/shared/shopping-cart/shopping-cart.service";

@Component({
    selector: 'nc-product-configurable-selector',
    templateUrl: './product-configurable-selector.component.html',
    styleUrls: ['./product-configurable-selector.component.scss']
})
export class ProductConfigurableSelectorComponent implements OnInit {

    @Input() item: ShoppingCartItem;

    public value;
    public pending = false;

    constructor(
        private shoppingCartService: ShoppingCartService
    ) {
    }

    ngOnInit(): void {
        this.value = this.item.child_id;
    }

    onChange(value: number) {
        this.pending = true;
        this.shoppingCartService.patchItem({
            id: this.item.id,
            child_id: value,
        }).then(
            () => {
                this.pending = false;
            },
            () => {
                this.pending = false;
            },
        );
    }


}
