import { Component, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';

import { Product, ProductService, ProductsResult } from 'src/app/core/product.service';
import { ConfigService } from 'src/app/core/config.service';
import { productImage } from 'src/app/helpers/product-helpers';

@Component({
    selector: 'nc-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnDestroy, AfterViewInit {

    showResultList: boolean;
    searching: boolean;
    searchResults: ProductsResult = null;
    searchString = '';
    navigating = false;
    subs = new SubSink();

    @ViewChild('searchContainer') searchContainer: ElementRef;
    @ViewChild('input') inputElement: ElementRef;
    @Output() closeSearch = new EventEmitter<any>();
    txtQueryChanged: Subject<string> = new Subject<string>();

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private configService: ConfigService,
        private productService: ProductService,
    ) {
        this.txtQueryChanged
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(text => {
                if (text && !this.navigating) {
                    this.queryServer(text);
                } else {
                    this.searchResults = null;
                    this.showResultList = false;
                    this.navigating = false;
                }
            });
        this.renderer.listen('window', 'click', (e: Event) => {
            if (!this.searchContainer.nativeElement.contains(e.target)) {
                this.doClose();
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => this.inputElement.nativeElement.focus());
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public onSearchChange(value: string) {
        this.navigating = false;
        this.txtQueryChanged.next(value);
    }

    public onSubmit(): void {
        this.navigating = true;
        this.close();
        this.searching = false;
        this.router.navigateByUrl(`/search/${this.searchString}`);

        return;
    }

    private queryServer(value) {
        this.searching = true;
        this.subs.sink = this.configService.localeChanges.subscribe((locale) => {
            if (locale) {
                this.productService.getProducts({autocomplete: value, filters: [], from: 0, size: 10})
                    .subscribe((result) => {
                        this.searchResults = result;
                        this.showResultList = true;
                        this.searching = false;
                    });
            }
        });
    }

    public getImage(product: Product): string {
        return productImage(product);
    }

    public doClose() {
        if (this.showResultList) {
            this.close();
            return;
        }
    }

    public closeIfNoSearch() {
        if (this.searchString.length < 1) {
            this.close();
        }
    }

    public close(): void {
        this.closeSearch.next(undefined);
    }

    public productUrl(product: Product) {
        let path = '';
        if (product.categories.length) {
            path = this.configService.getCategoryPath(product.categories[0]) + '/';
        }

        return `/product/${path}${product.id}`;
    }

}
