import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'nc-alert-dialog',
    template: `
        <p>{{data}}</p>
        <button nc-button [buttonText]="'dialog.ok' | translate" (click)="closeDialog()"></button>
    `,
    styles: [`
        :host {
            display: block;
            padding: 30px 0;
            width: 600px;
            max-width: 100%;
            text-align: center;
        }
        p {
            margin: 0 0 30px 0;
        }
    `],
})
export class AlertDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
    ) {}

    closeDialog() {
        this.dialogRef.close();
    }

}
