import { Injectable } from '@angular/core';
import { getCartTypeFromProductType } from '../helpers/shopping-car-helpers';
import { DialogService } from '../shared/dialog/dialog.service';
import { ProductDialogComponent } from '../shared/products-grid/product-dialog.component';
import { ShoppingCartCollection, ShoppingCartService } from '../shared/shopping-cart/shopping-cart.service';
import { Product } from './product.service';

export interface Coupon {
    pending?: boolean;
    id: number;
    status: string;
    price: number;
    coupon_name: string;
    sku: string;
    quantity: number;
    is_in_cart: string;
    product_id: number;
    product_type: string;
    product?: Product;
    requires_activating_order: boolean;
    minimum_sum: number;
}

@Injectable({
    providedIn: 'root'
})
export class CouponService {

    constructor(
        private shoppingCartService: ShoppingCartService,
        private dialogService: DialogService,
    ) {
    }

    getAvailableCouponFromProductInShoppingCart(
        product: Product,
        cartCollection: ShoppingCartCollection,
    ): Coupon[] {
        const cartType = getCartTypeFromProductType(product.type);
        const cart = cartCollection.getCart(cartType);
        const shoppingCartCoupons = cart?.coupons || [];

        return shoppingCartCoupons.filter((c) => c.sku === product.sku);
    }

    applyCoupon(coupon: Coupon): void {
        coupon.pending = true;

        switch (coupon.product.type) {
            case 'wrapper':
            case 'dress':
            case 'ticket':
            case 'webpage':
            case 'pcp':
                // Show popup product dialog when product type has options to set.
                this.dialogService.open(ProductDialogComponent, {
                    product: coupon.product,
                    coupon,
                });
                break;
            default:
                this.shoppingCartService.updateQuantity({
                    productType: coupon.product.type,
                    productId: coupon.product_id,
                    quantity: coupon.quantity,
                    couponId: coupon.id,
                }).then();
                break;
        }
    }

}
