import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, throwError, EMPTY } from 'rxjs';
import { mergeMap, tap, catchError } from 'rxjs/operators';

import {
    ShoppingCartDataService,
    ShoppingCartData,
    CartItemModificationData,
    CartItemsModificationData,
    ShoppingCartItem,
} from 'src/app/core/shopping-cart-data.service';
import { getCartTypeFromProductType } from '../../helpers/shopping-car-helpers';
import { DialogService } from '../dialog/dialog.service';
import { SnackBarService } from 'src/app/core/snack-bar.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { Product } from '../../core/product.service';

export type QueueItem = () => Observable<ShoppingCartData>;

export interface ProductAddedEvent {
    cartType: string;
}

export class ShoppingCartCollection {
    private carts: { [type: string]: ShoppingCartData } = {};
    private cartsById: { [id: number]: ShoppingCartData } = {};
    private selectedCart: number | null = null;
    private initialized = false;

    putCart(response: ShoppingCartData) {
        this.initialized = true;
        this.carts[response.type] = response;
        this.cartsById[response.id] = response;
    }

    getCart(type: string): ShoppingCartData | null {
        return this.carts[type] || null;
    }

    getSelectedCart(): ShoppingCartData | null {
        return this.selectedCart !== null ? this.getCartById(this.selectedCart) : null;
    }

    selectCart(id: number) {
        this.selectedCart = id;
    }

    getCartById(id: number): ShoppingCartData | null {
        return this.cartsById[id] || null;
    }

    hasItems(type: string): boolean {
        return !!this.carts[type] && this.carts[type].quantity_total > 0;
    }

    isInitialized(): boolean {
        return this.initialized;
    }

    reset() {
        this.carts = {};
        this.cartsById = {};
    }

    getCarts(): ShoppingCartData[] {
        return Object.values(this.carts);
    }

    getAllCartsOfType(type: string): ShoppingCartData[] {
        return Object.values(this.cartsById).filter((cart) => cart.type === type);
    }

    findItemForProduct(product: Product, childId = null) {
        const cart = this.getCart(product.cartType);

        if (!cart) {
            return null;
        }

        return cart.items.find((item) => {
            return (
                item.product_id === product.id &&
                item.child_id === childId &&
                item.bonus_campaign_id === (product.bonusCampaignId ?? null) &&
                item.bonus_with_levels_campaign_id === (product.bonusWithLevelsCampaignId ?? null) &&
                item.challenge_step_id === null &&
                item.coupon_id === null &&
                item.meta_data === null
            );
        });
    }

    findItemBySkuForProduct(sku: string) {
        const items = Object.values(this.carts)
            .map((cart) => cart.items)
            // Flatten arrays
            .reduce((itemsResult, currentItems) => itemsResult.concat(currentItems), []);

        return items.find((item) => item.product.sku === sku);
    }
}

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService {
    private shoppingCart: BehaviorSubject<ShoppingCartCollection> = new BehaviorSubject(null);
    private queue: Subject<QueueItem> = new Subject();
    private productAddedSubject: Subject<ProductAddedEvent> = new Subject();
    public cartCollection: ShoppingCartCollection = new ShoppingCartCollection();

    constructor(
        private shoppingCartDataService: ShoppingCartDataService,
        private dialogService: DialogService,
        private snackBarService: SnackBarService,
        private layoutService: LayoutService,
    ) {
        // This queue runs requests one at a time to make sure we don't get responses in the wrong order when sending multiple requests at the same time
        this.queue
            .pipe(
                mergeMap((queueItem) => {
                    return queueItem().pipe(
                        tap((response) => {
                            this.cartCollection.putCart(response);

                            this.shoppingCart.next(this.cartCollection);
                        }),
                        // Allow user to try again if request fails
                        catchError(() => {
                            this.snackBarService.error();
                            return EMPTY;
                        }),
                    );
                }, 1),
            )
            .subscribe();

        // Propagate changes of selecting cart
        this.layoutService.openedCartChanges.subscribe((type) => {
            if (type !== null) {
                this.selectCartOfType(type);
            } else {
                this.deselectCart();
            }
        });

        this.forceRefresh();
    }

    public get onProductAdd() {
        return this.productAddedSubject.asObservable();
    }

    public get shoppingCartChanges() {
        return this.shoppingCart.asObservable();
    }

    public deleteItem(data: CartItemModificationData): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.deleteItem(data).pipe(
                    tap((response) => {
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public updateQuantity(
        data: CartItemModificationData,
        importantInformation?: string,
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.updateItem(data).pipe(
                    tap((response) => {
                        this.productAddedSubject.next({ cartType: response.type });
                        if (importantInformation) {
                            this.dialogService.alert(importantInformation);
                        }
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public addItem(data: CartItemModificationData, importantInformation: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.addItem(data).pipe(
                    tap((response) => {
                        this.productAddedSubject.next({ cartType: response.type });
                        if (importantInformation) {
                            this.dialogService.alert(importantInformation);
                        }
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public addItems(data: CartItemsModificationData): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.addItems(data).pipe(
                    tap((response) => {
                        this.productAddedSubject.next({ cartType: response.type });
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    addManyBySku(data: { increment: number; sku: string }[]) {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.addManyBySku(data).pipe(
                    tap((response) => {
                        this.productAddedSubject.next({ cartType: response.type });
                        resolve(undefined);
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public updateCheckout(cartId: number, values: object): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.updateCheckout({ cartId, ...values }).pipe(
                    tap((response) => {
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    public skipChallengeReward(cartItemId: number, skipReward: boolean = true): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService
                    .skipChallengeReward({ id: cartItemId, skip_reward: skipReward })
                    .pipe(
                        tap((response) => {
                            resolve();
                        }),
                        catchError((error) => {
                            reject();
                            return throwError(error);
                        }),
                    );
            });
        });
    }

    public patchItem(param: ShoppingCartItem | any): Promise<void> {
        return new Promise((resolve, reject) => {
            this.queue.next(() => {
                return this.shoppingCartDataService.patchItem(param).pipe(
                    tap((response) => {
                        resolve();
                    }),
                    catchError((error) => {
                        reject();
                        return throwError(error);
                    }),
                );
            });
        });
    }

    forceRefresh() {
        this.shoppingCartDataService.getAllCarts().subscribe((response) => {
            this.cartCollection.reset();

            for (const cart of response.reverse()) {
                this.cartCollection.putCart(cart);
            }

            this.shoppingCart.next(this.cartCollection);
        });
    }

    deselectCart() {
        this.cartCollection.selectCart(null);
        this.shoppingCart.next(this.cartCollection);
    }

    selectCartOfType(type: string) {
        const cart = this.cartCollection.getCart(type);

        if (!cart) {
            return;
        }

        this.cartCollection.selectCart(cart.id);
        this.shoppingCart.next(this.cartCollection);
    }

    selectCartOfId(id: number) {
        this.cartCollection.selectCart(id);
        this.shoppingCart.next(this.cartCollection);
    }

    /**
     * Warning! This seems incorrect in some cases.
     * cartCollection.getCart(this.product.cartType) or cartCollection.findItemForProduct
     * should probably be used instead
     * @deprecated
     */
    getCartByProductType(productType: string) {
        const cartType = getCartTypeFromProductType(productType);

        return this.cartCollection.getCart(cartType);
    }
}
