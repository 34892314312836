import { getUrlParameter } from "src/app/helpers/url";

export function getJwt() {
    return window.localStorage.getItem('jwt');
}

export function setJwt(token: string) {
    return window.localStorage.setItem('jwt', token);
}

export function removeJwt() {
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('jwtData');
}

export function setupInitialJwt() {
    const jwt = getUrlParameter('jwt');
    if (!jwt) {
        return;
    }
    const jwtData = parseJwt(jwt);
    if (!jwtData) {
        return;
    }
    const oldJwt = window.localStorage.getItem('jwt');
    if (jwt !== oldJwt) {
        window.localStorage.removeItem('currentMarket'); // Is this still used?
    }
    window.localStorage.setItem('jwt', jwt);
    window.localStorage.setItem('jwtData', JSON.stringify(jwtData.data)); // Is this still used?
}

function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

