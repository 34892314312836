import { Component, Input, HostBinding, SimpleChanges, OnChanges } from '@angular/core';

export interface Slide {
    key: string;
    type: 'image' | 'video' | 'youtube';
    url: string;
    thumbnail: string;
}

@Component({
    selector: 'nc-product-gallery',
    templateUrl: 'product-gallery.component.html',
    styleUrls: ['./product-gallery.component.scss'],
})

export class ProductGalleryComponent implements OnChanges {

    @Input() slides: Slide[] = [];

    activeSlideIndex = 0;

    @HostBinding('class.has-navigation') get hasNavigationClass() {
        return this.slides.length > 1;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.slides && changes.slides.currentValue.length === 0) {
            this.slides.push({
                key: '',
                url: '/assets/images/placeholder.jpg',
                thumbnail: '/assets/images/placeholder.jpg',
                type: 'image',
            });
        }
    }

}
