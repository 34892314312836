import { Component, OnInit } from '@angular/core';
import { BreadCrumbsLink } from '../shared/breadcrumbs/breadcrumbs.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../core/product.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'nc-current-discounts-page',
    templateUrl: './current-discounts-page.component.html',
    styleUrls: ['./current-discounts-page.component.scss']
})
export class CurrentDiscountsPageComponent implements OnInit {
    public categoryName: string;
    public breadcrumbs: BreadCrumbsLink[] = [];
    public path = 'current-discounts';
    public baseFilterObservable: Observable<any>;
    public loading = true;
    public empty = false;

    constructor(
        private productService: ProductService,
        private translate: TranslateService,
    ) {
        this.baseFilterObservable = this.productService.getCurrentDiscounts();

        this.baseFilterObservable.subscribe((result) => {
            this.loading = false;
            this.empty = result.length === 0;
        });

        this.translate.get('page.title.current-discounts').subscribe((translated: string) => {
            this.categoryName = translated;
            this.setBreadCrumbs();
        });
    }

    ngOnInit(): void {
    }

    private setBreadCrumbs() {
        this.breadcrumbs.push({
            label: this.categoryName,
            href: '/' + this.path + '/',
        });
    }
}
