import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'nc-product-terms',
    templateUrl: './product-terms.component.html',
    styleUrls: ['./product-terms.component.scss']
})
export class ProductTermsComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
    ) {
    }
}
