import { Component, Input, OnInit } from '@angular/core';
import { Product } from "src/app/core/product.service";

@Component({
    selector: 'nc-product-subtitle',
    templateUrl: './product-subtitle.component.html',
    styleUrls: ['./product-subtitle.component.scss']
})
export class ProductSubtitleComponent {

    @Input() product: Product;

}
