import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { Product, ProductVariation } from 'src/app/core/product.service';
import {productImage, isUnavailableForRegularUsers, shouldDisplayCampaignAddButton} from 'src/app/helpers/product-helpers';
import { SnackBarService } from '../../core/snack-bar.service';
import { DialogService } from '../dialog/dialog.service';
import { ProductDialogComponent } from './product-dialog.component';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { ConfigService } from 'src/app/core/config.service';
import { Router } from '@angular/router';
import {
    BonusProductsCampaignData, BonusProductsWithLevelsCampaignData,
    CartItemModificationData,
    ShoppingCartData,
} from '../../core/shopping-cart-data.service';
import { SubSink } from 'subsink';
import { HttpParams } from '@angular/common/http';
import { isRequiredPurchaseFulfilled } from '../../helpers/requirements';

type AllowedSize = 1 | 2 | 3 | 4 | 5;

interface ProductsGridConfig {
    size: {
        xs: AllowedSize;
        sm: AllowedSize;
        md: AllowedSize;
        lg: AllowedSize;
    };
}

@Component({
    selector: 'nc-products-grid',
    templateUrl: './products-grid.component.html',
    styleUrls: ['./products-grid.component.scss'],
})
export class ProductsGridComponent implements OnInit {
    defaultSize = {
        xs: 2,
        sm: 2,
        md: 3,
        lg: 4,
    };

    addToShoppingCartPending: { [productId: number]: boolean } = {};

    @Input() products: Product[];
    @Input() bonusCampaignData?: BonusProductsCampaignData;
    @Input() bonusWithLevelsCampaignData?: BonusProductsWithLevelsCampaignData;
    @Input() config: ProductsGridConfig;
    @Input() categoryPath = '';
    @Input() selected: number[]; // Ids of products chosen for bonus campaign
    @Input() noResultText?: string;
    @Input() compactView = true;

    subs = new SubSink();
    shoppingCart: ShoppingCartData;

    isUnavailableForRegularUsers = isUnavailableForRegularUsers;
    isRequiredPurchaseFulfilled = isRequiredPurchaseFulfilled;
    shouldDisplayCampaignAddButton = shouldDisplayCampaignAddButton;

    constructor(
        protected dialogService: DialogService,
        protected router: Router,
        protected shoppingCartService: ShoppingCartService,
        protected configService: ConfigService,
        protected snackBarService: SnackBarService,
        protected translationService: TranslateService,
    ) {}

    @HostBinding('class') class: string;

    ngOnInit() {
        this.class = Object.keys(this.defaultSize)
            .map((breakpoint) => {
                const size = get(this.config, ['size', breakpoint]) || this.defaultSize[breakpoint];
                return `size-${breakpoint}--${size}`;
            })
            .join(' ');

        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe(
            (cartCollection) => {
                this.shoppingCart = cartCollection.getSelectedCart();
            },
        );
    }

    public image(product: Product): string {
        return productImage(product);
    }

    async addToShoppingCart(product: Product) {
        switch (product.type) {
            case 'wrapper':
            case 'dress':
            case 'ticket':
            case 'webpage':
            case 'pcp':
                this.dialogService.open(ProductDialogComponent, {
                    product,
                    compactView: true,
                });
                break;
            default:
                if (!this.isRequiredPurchaseFulfilled(product)) {
                    const message = await this.translationService
                        .get('product.requires-purchase-of-other-product', {
                            sku: product.requiredPurchaseProduct.sku,
                        })
                        .toPromise();
                    this.snackBarService.error(message).then();

                    return;
                }
                this.addToShoppingCartPending[product.id] = true;
                this.shoppingCartService
                    .addItem({
                            productType: product.type,
                            productId: product.id,
                            quantity: 1,
                        }, product.importantInfo)
                    .then(() => {
                        this.addToShoppingCartPending[product.id] = false;
                    });
                break;
        }
    }

    captureClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    public productUrl(product: Product, variation?: ProductVariation): string {
        if (!product) {
            return '';
        }

        if (product.bonusCampaignId || product.bonusWithLevelsCampaignId) {
            return ''; // Bonus products should not link to the product page.
        }

        let path = '';
        if (this.categoryPath) {
            path = this.categoryPath + '/';
        } else if (product.categories.length) {
            path = this.configService.getCategoryPath(product.categories[0]) + '/';
        }

        let url = `/product/${path}${product.id}`;

        // Add Variant ID as a query parameter.
        if (variation?.product?.id) {
            const params = new HttpParams().set('variantId', variation.product.id.toString());
            url = url.concat(`?${params.toString()}`);
        }

        return url;
    }

    shouldDisplayAddToShoppingCartButton(product: Product): boolean {
        if (product.bonusCampaignId) {
            return false;
        }

        if (product.bonusWithLevelsCampaignId) {
            return false;
        }

        if (product.disableAddToCart) {
            return false;
        }

        if (product.isDiscontinued) {
            return false;
        }

        if (product.type === 'look') {
            return false;
        }

        if (product.type === 'pcp') {
            return false;
        }

        if (!this.isRequiredPurchaseFulfilled(product)) {
            return false;
        }

        return true;
    }

    addBonusProduct(product: Product) {
        const data: CartItemModificationData = {
            productType: product.type,
            productId: product.id,
            bonusCampaignId: product.bonusCampaignId,
            bonusWithLevelsCampaignId: product.bonusWithLevelsCampaignId,
        };

        const quantity = product.bonusProductSelectedQuantity || 1;
        if (product.bonusCampaignId || product.bonusWithLevelsCampaignId) {
            data.increment = quantity;
        } else {
            data.quantity = quantity;
        }

        this.shoppingCartService.updateQuantity(data).then(() => {
            this.addToShoppingCartPending[product.id] = false;
        });
    }

    productClick($event: MouseEvent, product: Product, variation?: ProductVariation) {
        this.captureClick($event);

        if (product.bonusCampaignId) {
            this.dialogService.open(ProductDialogComponent, {
                product,
                showActions: false,
                compactView: this.compactView,
            });

            return;
        }

        this.router.navigateByUrl(this.productUrl(product, variation));
    }

    bonusProductState(product: Product): 'add'|'buy' {
        if (product.newPrice === 0 || product.price === 0) {
            return 'add';
        }

        return 'buy';
    }

    getBonusProductMaxQuantity(product: Product) {
        const bonusCampaign = this.getBonusCampaignForProduct(product);
        if (bonusCampaign?.perTrigger) {
            // To get max available/selectable quantity for current bonus product:
            // Take maxCount, deduct combined quantity of all selected products
            return bonusCampaign.maxCount - bonusCampaign.selectedQuantity;
        }

        const bonusWithLevelsCampaign = this.getBonusWithLevelsCampaignForProduct(product);
        if (bonusWithLevelsCampaign) {
            // To get max available/selectable quantity for current bonus product:
            // Take maxCount, deduct combined quantity of all selected products
            return bonusWithLevelsCampaign.maxCount - bonusWithLevelsCampaign.selectedQuantity;
        }

        return 1;
    }

    showBonusProductQuantitySelector(product: Product): boolean {
        const bonusCampaign = this.getBonusCampaignForProduct(product);
        if (bonusCampaign?.perTrigger) {
            return true;
        }

        const bonusWithLevelsCampaign = this.getBonusWithLevelsCampaignForProduct(product);
        if (bonusWithLevelsCampaign) {
            return true;
        }

        return false;
    }

    getBonusCampaignForProduct(product: Product): BonusProductsCampaignData | null | undefined {
        const bonusCampaigns = this.shoppingCart?.bonusCampaigns ?? [];

        if (product.bonusCampaignId && bonusCampaigns.length) {
            return bonusCampaigns.find(
                (campaign) => campaign.campaign.id == product.bonusCampaignId,
            );
        }

        return null;
    }

    getBonusWithLevelsCampaignForProduct(product: Product): BonusProductsWithLevelsCampaignData | null | undefined {
        const bonusWithLevelsCampaigns = this.shoppingCart?.bonusCampaignsWithLevels ?? [];
        if (product.bonusWithLevelsCampaignId && bonusWithLevelsCampaigns.length) {
            return bonusWithLevelsCampaigns.find(
                (campaign) => campaign.campaign.id === product.bonusWithLevelsCampaignId,
            );
        }

        return null;
    }
}
