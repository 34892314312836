import { Component, Input, OnInit } from '@angular/core';

import { cartItemImage, productImage } from "../../../helpers/product-helpers";
import { Product } from "src/app/core/product.service";
import { ShoppingCartItem } from "src/app/core/shopping-cart-data.service";

@Component({
    selector: 'nc-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent {

    public imgSrc: string;

    @Input() set product(product: Product) {
        this.imgSrc = productImage(product);
    }

    @Input() set item(item: ShoppingCartItem) {
        this.imgSrc = cartItemImage(item);
    }
}
