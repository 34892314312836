<h2 class="header">{{'start-kit.your-product-package' | translate}}</h2>
<div class="loader" *ngIf="this.products === null">
    <mat-spinner diameter="30"></mat-spinner>
</div>

<div class="grid row" *ngIf="this.products !== null">
    <div *ngFor="let product of products" class="col-12 col-lg-6 product">
        <div class="row">
            <div class="col-3 col-image">
                <div class="image image-shadow">
                    <nc-product-image [product]="product"></nc-product-image>
                </div>
            </div>
            <div class="info col-9">
                <div class="info-top">
                    <div class="name">{{product.name}}</div>
                    <!-- <nc-product-subtitle [product]="product"></nc-product-subtitle>-->
                </div>
                <div class="price" *ngIf="product.type !== 'look'">
                    <nc-product-price [product]="product"></nc-product-price>
                </div>
                <div *ngIf="product.description && product.description !== '-'" class="description">
                    <div [innerHTML]="firstLine(product.description)"></div>
                </div>
                <div class="quantity">
                    <span>{{'product.quantity' | translate}}:</span>&nbsp;<span>{{quantity(product.sku)}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
