<div>
    <h2>{{'shopping-cart.pcp.header' | translate}}</h2>
    <div *ngIf="this.loading">
        <mat-spinner diameter="48"></mat-spinner>
    </div>
    <div *ngIf="!this.loading">
        <div *ngIf="!this.pcpCustomers">
            {{'shopping-cart.pcp.no-recipients' | translate}}
        </div>
        <nc-pcp-recipients-table
            *ngIf="this.pcpCustomers"
            [header]="'shopping-cart.pcp.recipients' | translate"
            [customers]="this.pcpCustomers"
        ></nc-pcp-recipients-table>
    </div>
</div>
