import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../../core/product.service';
import { SnackBarService } from '../../../core/snack-bar.service';
import { StockService } from '../../../core/stock.service';

@Component({
    selector: 'nc-stock-monitor-button',
    templateUrl: './stock-monitor-button.component.html',
    styleUrls: ['./stock-monitor-button.component.scss']
})
export class StockMonitorButtonComponent implements OnInit {
    @Input() product: Product;

    constructor(
        protected stockService: StockService,
        protected snackBarService: SnackBarService,
        protected translationService: TranslateService,
    ) {
    }

    ngOnInit(): void {
    }

    async toggleMonitoring(event) {
        // Prevent parent form to submit when clicked.
        event.preventDefault();

        const result = await this.stockService.addMonitor(this.product.sku);

        if (!result.success) {
            const errorMessage = await this.translate('stock-monitor.product-is-monitored-error-message');
            await this.snackBarService.error(errorMessage);

            return;
        }

        this.product.isStockMonitored = true;

        const message = await this.translate('stock-monitor.product-is-monitored-message');
        await this.snackBarService.success(message);
    }

    protected translate(translationKey: string): Promise<string|undefined> {
        return this.translationService
            .get(translationKey)
            .toPromise();
    }
}
