import { Component, Input, OnInit } from '@angular/core';
import { marketsAvailable, marketToCountryCode } from '../../helpers/market';

@Component({
    selector: 'nc-flag-country-image',
    templateUrl: './flag-country-image.component.html',
    styleUrls: ['./flag-country-image.component.scss']
})
export class FlagCountryImage implements OnInit {

    @Input() countryCode: string;
    @Input() market: string;

    public path: string;

    ngOnInit(): void {
        if (this.countryCode) {
            this.setImagePathWithCountryCode(this.countryCode);
        }

        if (this.market) {
            this.countryCode = marketToCountryCode(this.market)?.toUpperCase();
            this.setImagePathWithCountryCode(this.countryCode);
        }
    }

    protected setImagePathWithCountryCode(countryCode: string): void {
        this.path = `/assets/images/flags/${countryCode.toLowerCase()}.svg`;
    }

}
