<span>
    <span *ngIf="isMinPrice" class="from-price">{{ 'product.from-price' | translate }}</span>
    <span>
        <span *ngIf="!price && !bonusPrice">
            <span class="price price-free">{{ price | ncCurrency }}</span>
        </span>
        <span *ngIf="price && newPrice === null">
            <span class="price price-normal">{{ price | ncCurrency }}</span>
        </span>
        <span *ngIf="price && newPrice !== null">
            <span class="price price-old">{{ price | ncCurrency }}</span>
            <span class="price price-new">{{ newPrice | ncCurrency }}</span>
        </span>
        <span *ngIf="bonusPrice !== null">
            <span class="price price-bonus">{{ price | ncCurrency }}</span>
            <span class="price price-new">{{ newPrice | ncCurrency }}</span>
        </span>
    </span>
</span>
