import { Component, EventEmitter, Input, Output, HostBinding, OnInit } from '@angular/core';
import { Bucket, ProductsAggregations } from 'src/app/core/product.service';
import { PersistedFilterService } from '../../core/persisted-filter.service';

@Component({
    selector: 'nc-products-filter',
    templateUrl: './products-filter.component.html',
    styleUrls: ['./products-filter.component.scss']
})
export class ProductsFilterComponent implements OnInit {

    @Input() aggregations: ProductsAggregations;
    @Input() mode: 'mobile' | 'desktop' = 'desktop';
    @Output() changeFilter: EventEmitter<any> = new EventEmitter();

    public selected: { [aggKey: string]: string[]; } = {};

    @HostBinding('class.mobile-mode') get mobileModeClass() {
        return this.mode === 'mobile';
    }

    constructor(
        private persistedFilterService: PersistedFilterService
    ) {}

    ngOnInit(): void {
        this.setupFilters();
    }

    toggle(agg: string, key) {
        this.addOrRemove(this.selected[agg], key);
        this.emitChange();
    }

    addOrRemove(array, value) {
        const index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    private setupEmptyFilters() {
        for (let agg in this.aggregations) {
            if (this.aggregations.hasOwnProperty(agg)) {
                this.selected[agg] = [];
            }
        }
    }

    private setupFilters() {
        for (const agg in this.aggregations) {
            if (this.aggregations.hasOwnProperty(agg)) {
                this.selected[agg] = [];

                const data = this.persistedFilterService.findFilterData(agg);

                if (data) {
                    this.selected[agg] = data.one;
                }
            }
        }
    }

    reset() {
        this.setupEmptyFilters();
        this.emitChange();
    }

    private emitChange() {
        this.changeFilter.emit([
            {property: 'variationColorGroups', one: this.selected['variationColorGroups']},
            {property: 'productSeries', one: this.selected['productSeries']},
            {property: 'skinType', one: this.selected['skinType']},
            {property: 'colorName', one: this.selected['colorName']},
            {property: 'usageRegion', one: this.selected['usageRegion']},
            {property: 'skinTone', one: this.selected['skinTone']},
            {property: 'makeupProperties', one: this.selected['makeupProperties']},
            {property: 'finish', one: this.selected['finish']},
            {property: 'fragrance', one: this.selected['fragrance']},
            {property: 'mkProductType', one: this.selected['mkProductType']},

            // Add new filters this way so we don't have to update the schema
            {property: 'seminarYear.keyword', one: this.selected['seminarYear']},
        ]);
    }

    isSelected(agg: string, key: string) {
        return this.selected[agg].indexOf(key) !== -1;
    }

    sortBucketByKey(agg: Bucket[]): Bucket[] {
        return agg.sort((a, b) => {
            if (a.key < b.key) {
                return -1;
            }
            if (a.key > b.key) {
                return 1;
            }

            return 0;
        });
    }
}
