import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BackendService} from './core/backend.service';
import {Router} from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

    protected readonly intranetLoginUrl: string;

    constructor(
        protected backendService: BackendService,
    ) {
        this.intranetLoginUrl = this.backendService.getIntranetUrl('login');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (error) => {
                    if (error.status === 401 && environment.env !== 'dev') {
                        window.location.href = this.intranetLoginUrl;
                    }
                },
            })
        );
    }
}
