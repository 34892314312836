<div class="container breadcrumbs-container">
    <nc-breadcrumbs
        [path]="breadcrumbs">
    </nc-breadcrumbs>
</div>
<mat-drawer-container>
    <mat-drawer class="mobile-filters" mode="over" [(opened)]="mobileFilterIsOpen">
        <button
            nc-button
            class="close-button"
            buttonAppearance="plain"
            buttonIcon="times"
            (click)="mobileFilterIsOpen=false">
        </button>
        <nc-product-count [total]="total" [loading]="products === null"></nc-product-count>
        <div *ngIf="aggregations">
            <nc-products-filter mode="mobile" [aggregations]="aggregations" (changeFilter)="filtersChanged($event)"></nc-products-filter>
        </div>

        <!-- NOTE: Enable this Robert when is ready. Tasks: MK-1082, MK-1036 -->
        <div>
            <nc-products-layout-switch></nc-products-layout-switch>
        </div>

    </mat-drawer>
    <mat-drawer-content class="page-content">
        <div class="container">
            <div class="page-top">
                <h2>{{categoryName}}</h2>
                <div class="page-top-right">
                    <button
                        nc-button
                        class="mobile-filters-button"
                        buttonAppearance="plain"
                        buttonIcon="filter"
                        (click)="mobileFilterIsOpen=true">
                    </button>

                    <!-- NOTE: Enable this Robert when is ready. Tasks: MK-1082, MK-1036 -->
                    <div>
                        <nc-products-layout-switch></nc-products-layout-switch>
                    </div>

                    <mat-form-field class="sort-field">
                        <mat-select [(value)]="activeSort" (selectionChange)="sortChange($event.value)">
                            <mat-option *ngFor="let option of sortableOrder" [value]="option">
                                {{option.viewValue | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="columns">
                <div class="filters">
                    <nc-product-count [total]="total" [loading]="products === null"></nc-product-count>
                    <div *ngIf="aggregations">
                        <nc-products-filter [aggregations]="aggregations" (changeFilter)="filtersChanged($event)"></nc-products-filter>
                    </div>
                </div>
                <div class="grid">
                    <ng-content></ng-content>
                    <nc-products-grid
                        *ngIf="layout==='grid'"
                        [products]="products"
                        [categoryPath]="path"
                        [noResultText]="noResultText"
                    ></nc-products-grid>
                    <nc-products-list
                        *ngIf="layout==='list'"
                        [products]="products"
                        [categoryPath]="path"
                        [noResultText]="noResultText"
                    ></nc-products-list>
                    <div class="button-container" *ngIf="products && products.length < total">
                        <button
                            nc-button
                            buttonAppearance="outlined"
                            [buttonPending]="loadMorePending"
                            [buttonText]="'category.load-more' | translate"
                            (click)="loadMoreProducts()">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
