<div>
    <div class="loading-customers" *ngIf="pending">
        <mat-spinner class="button-spinner" diameter="24"></mat-spinner>
    </div>
    <div *ngIf="!pending" class="pcp-recipients">
        <div class="table-controls">
            <h4>{{header}}</h4>
            <div class="pagination" *ngIf="pageCustomers">
                <span>{{('product.pcp.recipient-form.pagination' | translate: {start: start + 1, end: end, total: total})}}</span>
                <span>
                <button
                    nc-button
                    buttonAppearance="plain"
                    buttonIcon="chevron-left"
                    [disabled]="page === 0"
                    (click)="previousPage($event)"
                ></button>
                <button
                    nc-button
                    buttonAppearance="plain"
                    buttonIcon="chevron-right"
                    [disabled]="page === pageCount"
                    (click)="nextPage($event)"
                ></button>
            </span>
            </div>
        </div>
        <div class="table-container">
            <table>
                <tr>
                    <th>{{'consumer.first-name' | translate}}</th>
                    <th>{{'consumer.last-name' | translate}}</th>
                    <th>{{'consumer.phone' | translate}}</th>
                    <th>{{'consumer.address' | translate}}</th>
                    <th>{{'consumer.zip-code' | translate}}</th>
                    <th>{{'consumer.city' | translate}}</th>
                    <th>{{'consumer.email' | translate}}</th>
                    <th class="text-center">{{'consumer.language' | translate}}</th>
                    <th class="text-center">{{'consumer.country' | translate}}</th>
                </tr>
                <tr *ngFor="let customer of pageCustomers" class="{{customer.is_disabled ? 'disabled' : ''}}">
                    <ng-container *ngIf="customer.missing !== true">
                        <td>{{customer.firstname}}</td>
                        <td>{{customer.lastname}}</td>
                        <td>{{customer.telephone}}</td>
                        <td>{{customer.street}}</td>
                        <td>{{customer.postcode}}</td>
                        <td>{{customer.city}}</td>
                        <td class="consumer-email" title="{{customer.email}}">{{customer.email}}</td>
                        <td class="text-center">
                            <nc-flag-language-image languageCode="{{languageFromCode(customer.language)}}"></nc-flag-language-image>
                            <div *ngIf="!customer.is_language_correct_for_country" class="language-not-correct-for-country">
                                {{'product.pcp.recipient-form.language-not-correct-for-country' | translate}}
                            </div>
                        </td>
                        <td class="text-center">
                            <nc-flag-country-image countryCode="{{customer.country_id}}"></nc-flag-country-image>
                            <div *ngIf="customer.is_country_different" class="different-country">
                                {{'product.pcp.recipient-form.different-country' | translate}}
                            </div>

                        </td>
                    </ng-container>
                    <ng-container *ngIf="customer.missing === true">
                        <td colspan="9">{{'my-account.pcp.customer.data-missing' | translate}}: {{customer.entity_id}}</td>
                    </ng-container>
                </tr>
            </table>
        </div>
        <div class="table-controls">
            <div class="pagination" *ngIf="pageCustomers">
                <span>{{('product.pcp.recipient-form.pagination' | translate: {start: start + 1, end: end, total: total})}}</span>
                <span>
                    <button
                        nc-button
                        buttonAppearance="plain"
                        buttonIcon="chevron-left"
                        [disabled]="page === 0"
                        (click)="previousPage($event)"
                    ></button>
                    <button
                        nc-button
                        buttonAppearance="plain"
                        buttonIcon="chevron-right"
                        [disabled]="page === pageCount"
                        (click)="nextPage($event)"
                    ></button>
                </span>
            </div>
        </div>
    </div>
</div>

