import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { DialogComponent } from './dialog.component';
import { AlertDialogComponent } from './alert-dialog.component';
import { ConfirmDialogComponent } from "src/app/shared/dialog/confirm-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private dialog: MatDialog,
    ) { }

    public open(component: ComponentType<unknown>, data?): MatDialogRef<DialogComponent> {
        const dialog = this.dialog.open(DialogComponent, {
            data,
            panelClass: 'nc-dialog',
            maxWidth: '90vw',
        });
        dialog.componentInstance.component = component;
        return dialog;
    }

    public alert(message: string): MatDialogRef<DialogComponent> {
        return this.open(AlertDialogComponent, message);
    }

    public confirm(message: string): MatDialogRef<DialogComponent> {
        return this.open(ConfirmDialogComponent, message);
    }
}

