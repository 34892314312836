import { Component } from '@angular/core';
import { adminMenuItems } from './admin-menu-items';

@Component({
    selector: 'nc-admin-menu',
    template: `
        <div class="menu">
            <h2>Admin</h2>
            <ul>
                <li *ngFor="let item of menuItems">
                    <a [routerLink]="item.path"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    >{{item.title}}</a>
                </li>
            </ul>
        </div>
        <div class="main">
            <router-outlet></router-outlet>
        </div>
    `,
    styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent {

    menuItems = adminMenuItems;

}
