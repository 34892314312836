import {Component, Input} from '@angular/core';

@Component({
  selector: 'nc-product-count',
  templateUrl: './product-count.component.html',
  styleUrls: ['./product-count.component.scss']
})
export class ProductCountComponent {

    @Input() total;
    @Input() loading;


}
