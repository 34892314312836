import { Injectable } from '@angular/core';
import {Validators, FormControl, AsyncValidator, AbstractControl, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ShoppingCartDataService} from '../core/shopping-cart-data.service';

const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const resultMonthFormatRegexp = /^\d{4}-\d{2}$/;

export function conditionallyRequiredValidator(condition: () => boolean) {
    return (formControl: FormControl) => {
        if (condition()) {
            return Validators.required(formControl);
        }
        return null;
    };
}

export function requiredTrueValidator(formControl: FormControl) {
    if (formControl.value !== true) {
        return {requiredTrue: {valid: false}};
    }
    return null;
}

export function emailValidator(formControl: FormControl) {
    if (formControl.value && !emailRegexp.test(formControl.value)) {
        return {email: {valid: false}};
    }
    return null;
}

export function shippingMethodMustBePositiveNumberValidator(formControl: FormControl) {
    if (formControl?.value <= 0) {
        return {shippingMethod: {valid: false}};
    }
    return null;
}

export function resultMonthFormatValidator(formControl: FormControl) {
    if (formControl.value && !resultMonthFormatRegexp.test(formControl.value)) {
        return {resultMonthFormat: {valid: false}};
    }
    return null;
}

@Injectable({ providedIn: 'root' })
export class UniqueEmailForTicketEventValidator implements AsyncValidator {

    public productId?: number = undefined;

    constructor(
        protected shoppingCartDataService: ShoppingCartDataService,
    ) {}

    validate(
        control: AbstractControl,
    ): Observable<ValidationErrors | null> {
        if (!this.productId) {
            return of(null);
        }

        return this.shoppingCartDataService.validateUniqueEmailForTicket(control.value, this.productId)
            .pipe(
                map((response) => {
                    switch (response.error) {
                        case 'already_registered':
                            return {emailIsRegistered: true};

                        case 'consultant':
                            return {emailIsConsultant: true};
                    }

                    return (!response.isAllowed) ? {emailHasErrors: true} : null;
                }),
                catchError(() => of(null))
            );
    }
}
