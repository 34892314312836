import {environment} from '../environments/environment';
import {BrowserTracing} from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import {BrowserOptions} from '@sentry/browser';
import gitInfo from '../environments/git-info.json';

const SentryConfig: BrowserOptions = {
    dsn: 'https://49170dd2da274fa190dede3b1b529064@o1240364.ingest.sentry.io/6543114',
    integrations: [
        new BrowserTracing({
            tracePropagationTargets: [
                'webshop.intranet.marykay.se', // Prod
                'webshop.stage.lesley.client.netconsult.cloud', // Stage
                'localhost', // Development, local machine
            ],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    sampleRate: getSampleRate(environment.env),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: getTracesSampleRate(environment.env),
    environment: environment.env,

    // Make sure this value is identical to the name you give the release that you
    // create below using Sentry CLI
    release: gitInfo.revision,

    ignoreErrors: [
        'BufferLoader: XHR error for undefined',
        'Non-Error exception captured with keys: error, headers, message, name, ok',
    ],
};

export default SentryConfig;

function getSampleRate(env: string): number {
    return ('prod' === env || 'stage' === env) ? 0.3 : 0;
}

function getTracesSampleRate(env: string): number {
    return ('prod' === env || 'stage' === env) ? 0.02 : 0;
}
