<li *ngIf="cartHasItems('ticket')">
    <a
        (click)="openShoppingCart('ticket')"
        [ngClass]="{'shake': cartShake['ticket']}"
        title="{{'my-account.cart-type-ticket' | translate}}"
    >
        <i class="fal fa-ticket"></i>
        <span class="badge">{{cartQuantity('ticket')}}</span>
    </a>
</li>
<li *ngIf="cartHasItems('pcp')">
    <a
        (click)="openShoppingCart('pcp')"
        [ngClass]="{'shake': cartShake['pcp']}"
        title="{{'my-account.cart-type-pcp' | translate}}"
    >
        <i class="fal fa-book-heart"></i>
        <span class="badge">{{cartQuantity('pcp')}}</span>
    </a>
</li>
<li *ngIf="cartHasItems('prepack')">
    <a
        (click)="openShoppingCart('prepack')"
        [ngClass]="{'shake': cartShake['prepack']}"
        title="{{'my-account.cart-type-prepack' | translate}}"
    >
        <i class="fal fa-archive"></i>
        <span class="badge">{{cartQuantity('prepack')}}</span>
    </a>
</li>
<li *ngIf="cartHasItems('webpage')">
    <a
        (click)="openShoppingCart('webpage')"
        [ngClass]="{'shake': cartShake['webpage']}"
        title="{{'my-account.cart-type-webpage' | translate}}"
    >
        <i class="fal fa-home"></i>
        <span class="badge">{{cartQuantity('webpage')}}</span>
    </a>
</li>
<li *ngIf="cartHasItems('dress')">
    <a
        (click)="openShoppingCart('dress')"
        [ngClass]="{'shake': cartShake['dress']}"
        title="{{'my-account.cart-type-dress' | translate}}"
    >
        <i class="fal fa-tshirt"></i>
        <span class="badge">{{cartQuantity('dress')}}</span>
    </a>
</li>
<li>
    <a
        (click)="openShoppingCart('default')"
        [ngClass]="{'shake': cartShake['default']}"
        title="{{'shopping-cart.shopping-cart' | translate}}"
    >
        <i class="fal fa-shopping-bag"></i>
        <span class="badge" *ngIf="cartHasItems('default')">{{cartQuantity('default')}}</span>
    </a>
</li>
