<label *ngIf="label">{{label}}</label>
<div class="selector" [class.empty]="isEmpty()">
    <button
        type="button"
        nc-button
        class="minus-button"
        buttonAppearance="none"
        buttonIcon="minus"
        [disabled]="quantity <= minQuantity || this.disabled"
        (click)="decrease()">
    </button>
    <input
        type="number"
        name="quantity"
        class="value"
        min="{{minQuantity}}"
        max="{{maxQuantity}}"
        value="{{quantity}}"
        [disabled]="this.disabled"
        (input)="updateQuantity($event.target.value)"
    />
    <button
        type="button"
        nc-button
        class="plus-button"
        buttonAppearance="none"
        buttonIcon="plus"
        [disabled]="quantity >= maxQuantity || this.disabled"
        (click)="increase()">
    </button>
</div>
