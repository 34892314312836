<button *ngIf="slides.length > 1" type="button" class="left-btn" (click)="previous()">
    <i class="fas fa-chevron-left"></i>
</button>
<button *ngIf="slides.length > 1" type="button" class="right-btn" (click)="next()">
    <i class="fas fa-chevron-right"></i>
</button>
<div class="slides" [@slideAnimation]="activeIndex">
    <ng-container *ngFor="let slide of slides; let slideIndex = index; trackBy: trackByUrl;">
        <div class="slide" *ngIf="isActiveIndex(slideIndex)">
            <div class="shadow-box" [ngSwitch]="slide.type">
                <img *ngSwitchCase="'image'" [src]="slide.url" />
                <nc-product-video *ngSwitchCase="'video'" [src]="slide.url" [type]="slide.type"></nc-product-video>
                <nc-product-video *ngSwitchCase="'youtube'" [src]="'https://www.youtube.com/embed/' + slide.key" [type]="slide.type"></nc-product-video>
            </div>
        </div>
    </ng-container>
</div>
