import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BackendService } from './backend.service';
import { map, tap, filter } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import {environment} from "../../environments/environment";

export type Locale = 'sv' | 'fi' | 'no' | 'svfi';

interface User {
    title: string;
    name: string;
    number: string;
    market: string;
    currency: string;
    locale: string;
    countryCode: string;
    languageCode: string;
    customer: {
        startKit?: string;
    };
}

export interface Customer {
    jeeves_company_code: number;
    consultant_number: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    date_of_birth?: string;
    country_code: string;
    language_code: string;
    locale: string;
    shipping_name: string;
    shipping_co: string;
    shipping_address: string;
    shipping_postal_code: string;
    shipping_city: string;
    mobile: string;
    email: string;
    area_number: string;
    area_name: string;
    unit_number: string;
    register_date: string;
    unregistered: boolean;
    active: boolean;
    vat: number;
    director: string;
    recruited_by_number: string;
    first_active_order_date: string;
    personal_webshop_url: string;
    checksum: string;
    title_id: number;
    title_name_short: string;
    title_name_long: string;
    gender: string;
}

export interface AppConfig {
    user: User;
    customer?: Customer;
    markets: {
        code: string;
        label: string;
    }[];
    currentMarket: string;
    isAdmin: boolean;
    profileStatus: {
        code: string;
        label: string;
    };
}

interface Category {
    pim_id: number;
    level: string;
    name: string;
    path: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private config = new BehaviorSubject<AppConfig>(null);
    private locale = new BehaviorSubject<Locale>(null);
    private categories = new BehaviorSubject<Category[]>(null);

    constructor(
        private backendService: BackendService,
    ) { }

    public getConfig(): Observable<AppConfig> {
        return this.backendService.get('config').pipe(
            map(response => response.config),
            tap((config) => {
                this.config.next(config);
                Sentry.setContext('user', {
                    market: config.currentMarket,
                    language: config.user.countryCode,
                });
            }),
        );
    }

    public updateConfig(config: AppConfig) {
        this.config.next(config);
    }

    public getCategories(): Observable<Category[]> {
        return this.backendService.get('categories').pipe(
            tap((response: Category[]) => {
                this.categories.next(response);
            })
        );
    }

    public getCategory(id: number): Category {
        return this.snapshot.categories.find(category => category.pim_id === id) || null;
    }

    public getCategoryName(id: number|string): string {
        const category = this.getCategory(Number(id));

        return category ? category.name : '';
    }

    public getCategoryPath(id: number|string): string {
        const category = this.getCategory(Number(id));

        return category ? category.path : '';
    }

    public changeLocale(locale) {
        this.locale.next(locale);
    }

    public get configChanges(): Observable<AppConfig> {
        return this.config.asObservable().pipe(filter((response) => !!response));
    }

    public get localeChanges(): Observable<Locale> {
        return this.locale.asObservable();
    }

    public get categoriesChanges(): Observable<Category[]> {
        return this.categories.asObservable();
    }

    public get snapshot() {
        return {
            config: this.config.getValue(),
            locale: this.locale.getValue(),
            categories: this.categories.getValue(),
        };
    }

}
