<div *ngIf="0 === products?.length && this.noResultText">
    {{this.noResultText}}
</div>
<ul class="grid">
    <li *ngFor="let product of products" [class.unavailable]="isUnavailableForRegularUsers(product)">
        <a href="{{productUrl(product)}}" (click)="productClick($event, product)">
            <div class="image image-shadow">
                <nc-product-image [product]="product"></nc-product-image>
                <nc-product-color-dot
                    *ngIf="product.color"
                    [color]="product.color"
                    [colorName]="product.colorName"
                ></nc-product-color-dot>
            </div>
            <nc-product-image-ribbons [product]="product"></nc-product-image-ribbons>
            <div class="favourite-button" (click)="captureClick($event)">
                <button
                    type="button"
                    nc-favourite-button
                    [productId]="product.id">
                </button>
            </div>
            <div class="info">
                <div class="info-top">
                    <div class="name">{{product.name}}</div>
                    <nc-product-subtitle [product]="product"></nc-product-subtitle>
                    <nc-product-out-of-stock [product]="product"></nc-product-out-of-stock>
                    <div *ngIf="product && !isRequiredPurchaseFulfilled(product)" class="product-error-message">
                        <a href="{{productUrl(product.requiredPurchaseProduct)}}" (click)="productClick($event, product.requiredPurchaseProduct)">
                            <nc-product-error-message>
                                {{'product.requires-purchase-of-other-product' | translate:({ sku: product?.requiredPurchaseProduct?.sku })}}
                            </nc-product-error-message>
                        </a>
                    </div>
                </div>
                <nc-product-color-list *ngIf="product.type === 'wrapper'" [colors]="product.children"></nc-product-color-list>
                <nc-product-image-list *ngIf="product.type === 'look'" [images]="product.images"></nc-product-image-list>

                <div class="price" *ngIf="product.type !== 'look'"><nc-product-price [product]="product"></nc-product-price></div>
                <nc-product-stock-quantity [product]="product"></nc-product-stock-quantity>
                <div class="shopping-cart-button" (click)="captureClick($event)">
                    <button
                        nc-button
                        type="button"
                        *ngIf="shouldDisplayAddToShoppingCartButton(product)"
                        buttonAppearance="plain"
                        buttonIcon="shopping-bag"
                        stackedIcon="plus"
                        [buttonPending]="addToShoppingCartPending[product.id]"
                        (click)="addToShoppingCart(product)">
                    </button>
                </div>
            </div>
        </a>

        <div *ngIf="shouldDisplayCampaignAddButton(product)" class="bonus-campaign-controls">
            <div *ngIf="showBonusProductQuantitySelector(product)" class="quantity-selector">
                <nc-quantity-selector
                    [maxQuantity]="getBonusProductMaxQuantity(product)"
                    (selectedQuantity)="product.bonusProductSelectedQuantity = $event"
                    [title]="'product.quantity' | translate">
                </nc-quantity-selector>
            </div>
            <div
                class="bonus-add"
                [ngClass]="bonusProductState(product)"
                (click)="addBonusProduct(product)">
                <!-- Only visible in checkout -->
                <span *ngIf="bonusProductState(product) === 'add'">{{'product.add' | translate}}</span>
                <span *ngIf="bonusProductState(product) === 'buy'">{{'product.buy' | translate}}</span>
            </div>
        </div>
    </li>
</ul>
<ul class="grid loading" *ngIf="products === null">
    <li
        *ngFor="let p of [0,0,0,0,0]; let index = index;"
        [class.hidden-xs]="index >= (config?.size.xs || defaultSize['xs'])"
        [class.hidden-sm]="index >= (config?.size.sm || defaultSize['sm'])"
        [class.hidden-md]="index >= (config?.size.md || defaultSize['md'])"
        [class.hidden-lg]="index >= (config?.size.lg || defaultSize['lg'])">
        <a>
            <div class="image image-shadow"><span class="img-placeholder"></span></div>
            <div class="info">
                <div class="info-top">
                    <div class="name">█████████████████████</div>
                    <div class="type">██████</div>
                </div>
                <div class="price">███</div>
            </div>
        </a>
    </li>
</ul>
