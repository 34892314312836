import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductVariation, Product, ProductImage, ProductVideo } from 'src/app/core/product.service';
import { conditionallyRequiredValidator, requiredTrueValidator } from 'src/app/helpers/validators';
import { Coupon } from '../../core/coupon.service';

interface ProductDialogComponentInputData {
    product: Product;
    coupon?: Coupon;
    showActions?: boolean;
    compactView?: boolean;
}

@Component({
    selector: 'nc-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inputData: ProductDialogComponentInputData,
    ) {
        this.product = inputData.product;

        if (inputData.coupon) {
            this.coupon = inputData.coupon;
            dialogRef.afterClosed().subscribe(() => {
                this.coupon.pending = false;
            });
        }

        if (typeof inputData.showActions === 'boolean') {
            this.showActions = inputData.showActions;
        }

        if (typeof inputData.compactView === 'boolean') {
            this.compactView = inputData.compactView;
        }
    }

    form: FormGroup = this.formBuilder.group({
        quantity: [1],
        productId: [''],
        childId: ['', conditionallyRequiredValidator(() => {
            return this.product && this.product.type === 'wrapper';
        })],
        termsAgreement: [true, requiredTrueValidator],
    });

    product: Product;
    coupon?: Coupon;

    showActions: boolean = true;
    compactView: boolean = false;

    selectedVariation: ProductVariation;
    slides = [];

    ngOnInit() {
        this.form.get('productId').setValue(this.product.id);

        if (this.product.type === 'webpage') {
            this.form.get('termsAgreement').setValue(false);
        }

        this.loadSlides();
    }

    onChangeVariation(variation: ProductVariation) {
        this.selectedVariation = variation;
        this.loadSlides();
    }

    onAddToCart($event: { formValue: any }) {
        if (this.coupon) {
            this.closeDialog();
        }
    }

    loadSlides() {
        let slides = [];
        if (this.selectedVariation) {
            slides = slides.concat(this.loadSlideImages(this.selectedVariation.product.images));
            slides = slides.concat(this.loadSlideVideos(this.selectedVariation.product.videos));
        }
        slides = slides.concat(this.loadSlideImages(this.product.images));
        slides = slides.concat(this.loadSlideVideos(this.product.videos));
        this.slides = slides;
    }

    private loadSlideImages(productImages: ProductImage[]) {
        const slides = [];
        for (const image of productImages) {
            slides.push({
                key: '',
                url: image.url,
                thumbnail: image.url,
                type: 'image',
            });
        }

        return slides;
    }

    private loadSlideVideos(productVideos: ProductVideo[]) {
        const slides = [];
        for (const video of productVideos) {
            slides.push({
                key: video.key ?? '',
                url: video.url,
                thumbnail: video.thumbnail,
                type: video.type ?? 'video',
            });
        }

        return slides;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
