<div class="button-wrapper" mat-dialog-close>
    <button
        class="close-button"
        nc-button
        buttonAppearance="plain"
        buttonIcon="times"
        [buttonText]="'dialog.close' | translate">
    </button>
</div>
<div class="content" mat-dialog-content>
    <ng-content></ng-content>
</div>

