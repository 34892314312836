import { Component, Injector, Input, OnInit } from '@angular/core';
import { BackendService } from 'src/app/core/backend.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { WelcomeMessageComponent } from 'src/app/shared/cms/blocks/welcome-message/welcome-message.component';
import { RecommendedProductsComponent } from 'src/app/shared/cms/blocks/recommended-products/recommended-products.component';
import { ChallengesComponent } from 'src/app/shared/cms/blocks/challenges/challenges.component';
import { StartKitAddBundleComponent } from 'src/app/shared/start-kit/start-kit-add-bundle/start-kit-add-bundle.component';
import { StartKitShowBundleComponent } from 'src/app/shared/start-kit/start-kit-show-bundle/start-kit-show-bundle.component';
import { CmsCouponsComponent } from '../blocks/coupons/coupons.component';

@Component({
    selector: 'nc-cms-page-area',
    templateUrl: './cms-page-area.component.html',
    styleUrls: ['./cms-page-area.component.scss']
})
export class CmsPageAreaComponent implements OnInit {

    @Input() slug: string;
    @Input() isPopupPage = false;

    public html: string | SafeHtml = '';
    public error = '';
    public classes = '';

    public request: Subscription = null;

    // Blocks to inject on the page
    private blocks = [
        {
            selector: 'cms-welcome-message',
            replaces: '<!-- Webshop Welcome -->',
            component: WelcomeMessageComponent,
        },
        {
            selector: 'cms-challenges',
            replaces: '<!-- Webshop Challenges -->',
            component: ChallengesComponent,
        },
        {
            selector: 'cms-coupons',
            replaces: '<!-- Webshop Coupons -->',
            component: CmsCouponsComponent,
        },
        {
            selector: 'cms-recommended-products',
            replaces: '<!-- Webshop Recommended Products -->',
            component: RecommendedProductsComponent,
        },
        {
            selector: 'nc-start-kit-add-bundle',
            replaces: null,
            component: StartKitAddBundleComponent,
        },
        {
            selector: 'nc-start-kit-show-bundle',
            replaces: null,
            component: StartKitShowBundleComponent,
        },
        {
            // alias, this is acually used in wordpress. Can be changed in wordpress later.
            selector: 'nc-startkit-show-bundle',
            replaces: null,
            component: StartKitShowBundleComponent,
        },
    ];

    // Scripts to be loaded after loading the html
    private dynamicScripts = [
        '//mkcms.lesley.se/app/themes/netconsult-marykay/dist/scripts.js',
    ];

    constructor(
        private backendService: BackendService,
        private injector: Injector,
        private domSanitizer: DomSanitizer,
    ) {
    }

    ngOnInit(): void {
        this.prepareDynamicElements();
        this.loadPage();
    }

    private loadPage() {
        this.request = this.backendService.get('page/' + this.slug).subscribe((result) => {
            // this.html = result.content;
            this.injectContent(result.content);
            this.classes = result.wrapperClasses;
            this.loadScripts();
        }, (error) => {
            this.onError(error);
        });
    }

    private onError(error: HttpErrorResponse) {
        if (error.status === 404) {
            this.error = 'Page not found';
            return;
        }

        if (error.error && error.error.message) {
            this.error = error.message + '\n' + error.error.message;
            return;
        }

        this.error = 'An unknown error occurred';
    }

    loadScripts() {
        for (let i = 0; i < this.dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = this.dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = true;
            document.body.appendChild(node);
        }
    }

    private injectContent(content: string) {
        for (const block of this.blocks) {
            // Replace the comment with an element
            if (block.replaces) {
                content = content.replace(block.replaces, '<' + block.selector + '></' + block.selector + '>');
            }
        }

        // Inject the html
        this.html = this.domSanitizer.bypassSecurityTrustHtml(content);
    }

    private prepareDynamicElements() {
        if (window['cmsPageAreaPrepared']) {
            return;
        }
        window['cmsPageAreaPrepared'] = true;

        for (const block of this.blocks) {
            // Create an element dynamically
            const element = createCustomElement(block.component, {injector: this.injector});

            // Bind the element to the selector
            customElements.define(block.selector, element);
        }
    }
}
