import { Component, Input } from '@angular/core';
import { ShoppingCartItem } from 'src/app/core/shopping-cart-data.service';
import {
    PcpTableByCustomerIdsDialogComponent
} from '../../../pcp/pcp-table-by-customer-ids-dialog/pcp-table-by-customer-ids-dialog.component';
import { DialogService } from '../../dialog/dialog.service';

type PcpCustomerId = string;

@Component({
    selector: 'nc-item-metadata-pcp',
    templateUrl: './item-metadata-pcp.component.html',
    styleUrls: ['./item-metadata-pcp.component.scss']
})
export class ItemMetadataPcpComponent {

    @Input() item: ShoppingCartItem;
    Object = Object;

    constructor(
        protected dialogService: DialogService,
    ) {
    }

    getHowManyMoreCount(item: ShoppingCartItem) {
        const customersTotal = item.meta_data?.customers?.length;

        if (customersTotal > 3) {
            return customersTotal - 3;
        }

        return 0;
    }

    getPreviewCustomers(item: ShoppingCartItem) {
        return item.meta_data?.preview;
    }

    getCustomers(item: ShoppingCartItem): PcpCustomerId[] {
        return item.meta_data?.customers ?? [];
    }

    popupPcpCustomerList(customerIds: PcpCustomerId[]) {
        this.dialogService.open(PcpTableByCustomerIdsDialogComponent, { customerIds });
    }

}
