import {Component, Input, OnInit} from '@angular/core';
import {ShoppingCartService} from "src/app/shared/shopping-cart/shopping-cart.service";
import {SnackBarService} from "src/app/core/snack-bar.service";
import {StartKitService} from "../start-kit.service";

@Component({
    selector: '[nc-start-kit-add-bundle], [nc-startkit-add-bundle]',
    templateUrl: './start-kit-add-bundle.component.html',
    styleUrls: ['./start-kit-add-bundle.component.scss']
})
export class StartKitAddBundleComponent implements OnInit {

    @Input() bundlenumber: string;

    added = false;
    items: { increment: number; sku: string }[];

    constructor(
        private cs: ShoppingCartService,
        private sb: SnackBarService,
        private startKitService: StartKitService,
    ) {
    }

    ngOnInit(): void {
        this.startKitService.getContent(parseInt(this.bundlenumber)).then((content) => {
            this.items = content;
        })
    }

    onClick($event) {
        this.added = true;

        this.cs.addManyBySku(this.items);

        this.sb.success('shopping-cart.added-products-message');
    }

}
