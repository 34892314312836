import { Component, Input } from '@angular/core';

type MessageType = 'error'|'warning';

@Component({
    selector: 'form-error-message',
    templateUrl: './form-error-message.component.html',
    styleUrls: ['./form-error-message.component.scss']
})
export class FormErrorMessageComponent {

    @Input()
    type: MessageType = 'error';

    constructor() {
    }

    ngOnInit(): void {
    }

}
