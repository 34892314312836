export const ACCOUNT_MENU = [
    {
        data: { title: 'account-menu.my-profile', url: '/default/myProfile' },
        limit_admin: false,
        children: [],
    },
    {
        data: { title: 'account-menu.terms-and-policies', url: '/policies' },
        limit_admin: false,
        children: [],
    },
    {
        data: { title: 'account-menu.logout', url: '/logout' },
        type: 'local',
        limit_admin: false,
        children: [],
    },
    {
        data: { title: 'Administration Webshop', url: '/administration' },
        type: 'local',
        limit_admin: true,
        children: [],
    },
    {
        data: { title: 'Bakgrundsjobb Webshop', url: '/totem' },
        type: 'backend',
        limit_admin: true,
        children: [],
    },
    {
        data: { title: 'Administration', url: '/administration' },
        limit_admin: true,
        children: [],
    },
    {
        data: { title: 'Filer', url: '/default/files' },
        limit_admin: true,
        children: [],
    },
];
