import { Component, Input, ViewChild, ElementRef, EventEmitter, Output, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Slide } from 'src/app/shared/product/product-gallery/product-gallery.component';

@Component({
    selector: 'nc-product-gallery-navigation',
    templateUrl: 'product-gallery-navigation.component.html',
    styleUrls: ['./product-gallery-navigation.component.scss'],
})

export class ProductGalleryNavigationComponent implements OnChanges {

    atTop = true;
    atBottom = true;

    @Input() slides: Slide[] = [];

    @Input() activeIndex;
    @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

    @ViewChild('navigationScroll') navigationScroll: ElementRef;

    @HostListener('window:resize') onResize() {
        setTimeout(() => {
            this.onScroll();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.slides) {
            setTimeout(() => {
                this.onScroll();
            });
            if (this.activeIndex >= this.slides.length) {
                this.select(0);
            }
        }
        if (changes.activeIndex) {
            setTimeout(() => {
                this.scrollToActive();
            });
        }
    }

    select(index: number) {
        this.activeIndexChange.emit(index);
    }

    onScroll() {
        const el = this.navigationScroll.nativeElement;

        if (el.scrollHeight <= el.clientHeight) {
            this.atTop = true;
            this.atBottom = true;
        } else {
            this.atTop = el.scrollTop === 0;
            this.atBottom = el.scrollTop >= (el.scrollHeight - el.offsetHeight);
        }
    }

    scrollBy(amount: number) {
        const el = this.navigationScroll.nativeElement;
        let target = el.scrollTop + amount;

        if (target < 0) {
            target = 0;
        }

        if (target > el.scrollHeight - el.offsetHeight) {
            target = el.scrollHeight - el.offsetHeight;
        }

        const scrollInterval = setInterval(() => {
            if (el.scrollTop > target && amount < 0) {
                el.scrollTop -= 10;
            } else if (el.scrollTop < target && amount > 0) {
                el.scrollTop += 10;
            } else {
                clearInterval(scrollInterval);
            }
        }, 5);
    }

    scrollToActive() {
        const el = this.navigationScroll.nativeElement as HTMLElement;
        const activeEl = el.querySelector('.is-active') as HTMLElement;
        const isBelow = activeEl.offsetTop > (el.scrollTop + el.clientHeight);
        const isAbove = (activeEl.offsetTop + activeEl.offsetHeight) < el.scrollTop;
        if (isBelow || isAbove) {
            this.scrollBy(activeEl.offsetTop - el.scrollTop);
        }
    }

    trackByUrl(index: number, slide: Slide) {
        return slide.url;
    }

}
