import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartCollection, ShoppingCartService
} from "src/app/shared/shopping-cart/shopping-cart.service";
import { LayoutService } from "src/app/layout/layout.service";
import { SubSink } from "subsink";

@Component({
    selector: 'nc-carts-menu',
    templateUrl: './carts-menu.component.html',
    styleUrls: ['./carts-menu.component.scss']
})
export class CartsMenuComponent implements OnInit, OnDestroy {

    subs = new SubSink();
    carts: ShoppingCartCollection;
    cartShake: { [type: string]: boolean; } = {};

    constructor(
        protected layoutService: LayoutService,
        protected shoppingCartService: ShoppingCartService,
    ) {

    }

    ngOnInit(): void {
        this.subs.sink = this.shoppingCartService.shoppingCartChanges.subscribe((cartCollection) => {
            this.carts = cartCollection;
        });

        this.subs.sink = this.shoppingCartService.onProductAdd.subscribe((event) => {
            this.cartShake[event.cartType] = true;
            setTimeout(() => {
                this.cartShake[event.cartType] = false;
            }, 820);
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    openShoppingCart(type: string) {
        this.layoutService.openShoppingCart(type);
    }

    cartHasItems(type: string): boolean {
        return this.carts.hasItems(type);
    }

    cartQuantity(type: string): number {
        return this.carts.hasItems(type) ? this.carts.getCart(type).quantity_total : 0;
    }

}
