import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'nc-progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {

    @Input() value = 0;
    @Input() isSecondary = false;

}
