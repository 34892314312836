<div>
    <div *ngIf="item.meta_data.model">
        <b>{{'shopping-cart.dress-model' | translate}}:</b>
        {{ modelLabel() }}
    </div>
    <div *ngIf="item.meta_data.size">
        <b>{{'shopping-cart.dress-size' | translate}}:</b>
        {{ sizeLabel() }}
    </div>
</div>
