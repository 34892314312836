import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { MenuItem } from 'src/app/core/menu.service';
import { AbstractShopMenuComponent } from "src/app/layout/shop-menu/abstract-shop-menu.component";

@Component({
    selector: 'nc-desktop-shop-menu',
    templateUrl: './desktop-shop-menu.component.html',
    styleUrls: ['./desktop-shop-menu.component.scss'],
})
export class DesktopShopMenuComponent extends AbstractShopMenuComponent {

    hoverIndex = -1;
    isHovering = false;
    mouseenterTimeout;
    mouseleaveTimeout;

    onMouseenter(item: MenuItem, index: number) {
        if (this.shouldDisplayAnchor(item)) {
            return;
        }
        this.isHovering = true;
        if (this.mouseenterTimeout) {
            clearTimeout(this.mouseenterTimeout);
        }
        this.mouseenterTimeout = setTimeout(() => {
            if (this.isHovering) {
                this.openMenu(index);
            }
        }, 100);
    }

    onMouseleave() {
        this.isHovering = false;
        if (this.mouseleaveTimeout) {
            clearTimeout(this.mouseleaveTimeout);
        }
        this.mouseleaveTimeout = setTimeout(() => {
            if (!this.isHovering) {
                this.closeMenu();
            }
        }, 100);
    }

    openMenu(index: number) {
        this.hoverIndex = index;
        this.openChange.emit(true);
    }

    closeMenu() {
        this.hoverIndex = -1;
        this.openChange.emit(false);
    }

    selectSecondLevelItem(item: MenuItem) {
        this.closeMenu();
        this.router.navigate([item.data.url]);
    }

    isCurrentPath(item: any) {
        return item.data.url === window.location.pathname
            || (window.location.pathname).startsWith(item.data.url + '/');
    }
}
