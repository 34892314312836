import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Product, ProductService } from "src/app/core/product.service";
import { Locale } from "src/app/core/config.service";

@Component({
    selector: 'nc-start-kit-preview-dialog',
    templateUrl: './start-kit-preview-dialog.component.html',
    styleUrls: ['./start-kit-preview-dialog.component.scss']
})
export class StartKitPreviewDialogComponent implements OnInit {

    products: Product[] = null;

    constructor(
        private dialogRef: MatDialogRef<StartKitPreviewDialogComponent>,
        private productService: ProductService,
        @Inject(MAT_DIALOG_DATA) public data: { products: { sku: string, increment: number }[] },
    ) {
    }

    ngOnInit(): void {

        let skus = [];

        for (let product of this.data.products) {
            skus.push(product.sku);
        }

        const filters = [
            {property: 'sku.keyword', one: skus},
        ];

        this.productService.getProducts({filters, from: 0, skipBasic: true})
            .subscribe((result) => {
                this.products = result.items;
            });
    }

    quantity(sku: string): number {
        let product = this.data.products.find((product) => {
            return product.sku === sku
        });

        return product ? product.increment : 0;
    }

    firstLine(description: string|null) {
        return description ? description.split("\n")[0] : '';
    }
}
