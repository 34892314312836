import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    OnChanges,
} from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

import { LayoutService } from 'src/app/layout/layout.service';
import { ConfigService } from '../../core/config.service';
import { Product } from '../../core/product.service';
import { productBuildUrl } from '../../helpers/product-helpers';
import { ShoppingCartService } from './shopping-cart.service';
import { ShoppingCartItem } from 'src/app/core/shopping-cart-data.service';
import { ProductDialogComponent } from '../products-grid/product-dialog.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
    selector: 'nc-shopping-cart-item',
    templateUrl: './shopping-cart-item.component.html',
    styleUrls: ['./shopping-cart-item.component.scss'],
})
export class ShoppingCartItemComponent implements AfterViewInit, OnChanges, OnDestroy {
    constructor(
        private layoutService: LayoutService,
        private shoppingCartService: ShoppingCartService,
        private focusMonitor: FocusMonitor,
        private dialogService: DialogService,
        private configService: ConfigService,
    ) {}

    removePending = false;
    quantityPending = false;
    quantityOptions = new Array(100);

    @Input() item: ShoppingCartItem;
    @Input() quantityOptionsMax?: number;
    @Input() readonly = false;
    @Input() isLocked = false;

    @ViewChild('imageLink') imageLink: ElementRef;

    ngOnChanges() {
        if (typeof this.quantityOptionsMax === 'number') {
            this.quantityOptions = new Array(this.quantityOptionsMax);
        }
    }

    ngAfterViewInit() {
        this.focusMonitor.monitor(this.imageLink);
    }

    ngOnDestroy() {
        this.focusMonitor.stopMonitoring(this.imageLink);
    }

    removeProduct() {
        if (this.item.isChallengeReward) {
            return this.skipChallengeReward();
        }

        this.removePending = true;
        this.shoppingCartService
            .deleteItem({
                productType: this.item.product.type,
                productId: this.item.product_id,
                childId: this.item.child_id,
                bonusCampaignId: this.item.bonus_campaign_id || null,
                bonusWithLevelsCampaignId: this.item.bonus_with_levels_campaign_id || null,
                couponId: this.item.coupon_id || null,
                metaData: this.item.meta_data || null,
            })
            .then(
                () => {
                    this.removePending = false;
                },
                () => {
                    this.removePending = false;
                },
            );
    }

    selectQuantity(quantity) {
        this.quantityPending = true;
        this.shoppingCartService
            .updateQuantity({
                productType: this.item.product.type,
                productId: this.item.product_id,
                childId: this.item.child_id,
                bonusCampaignId: this.item.bonus_campaign_id || null,
                bonusWithLevelsCampaignId: this.item.bonus_with_levels_campaign_id || null,
                metaData: this.item.meta_data || null,
                quantity,
            })
            .then(
                () => {
                    this.quantityPending = false;
                },
                () => {
                    this.quantityPending = false;
                },
            );
    }

    private skipChallengeReward() {
        this.removePending = true;
        this.shoppingCartService.skipChallengeReward(this.item.id).then(
            () => {
                this.removePending = false;
            },
            () => {
                this.removePending = false;
            },
        );
    }

    addSkippedChallengeReward() {
        this.removePending = true;
        this.shoppingCartService.skipChallengeReward(this.item.id, false).then(
            () => {
                this.removePending = false;
            },
            () => {
                this.removePending = false;
            },
        );
    }

    closeShoppingCart() {
        this.layoutService.closeShoppingCart();
    }

    popupProductInfo(shoppingCartItem: ShoppingCartItem) {
        const product = shoppingCartItem.product;
        product.disableAddToCart = true;

        this.dialogService.open(ProductDialogComponent, {
            product: product,
            showActions: false,
            compactView: false,
        });
    }

    canViewQuantity(): boolean {
        if (0 < this.item.meta_data?.force_quantity) {
            return true;
        }

        if ('ticket' === this.item.product.type) {
            return true;
        }

        if ('pcp' === this.item.product.type) {
            return true;
        }

        if (this.isLocked) {
            return true;
        }

        if (this.item.coupon_id) {
            return true;
        }

        return false;
    }

    canChangeQuantity() {
        if (this.canViewQuantity()) {
            return false;
        }

        const product = this.item.product;

        if (product.bonusCampaignId && product.bonusCampaignProductPerTrigger === true) {
            return true;
        }

        if (product.bonusWithLevelsCampaignId) {
            return true;
        }

        if (this.item.isChallengeReward) {
            return false;
        }

        if (this.item.coupon_id) {
            return false;
        }

        return true;
    }

    canRemove() {
        if (this.item.meta_data?.locked) {
            return false;
        }

        if (this.isLocked) {
            return false;
        }

        if (this.item.isChallengeReward && this.item.skip_reward) {
            return false;
        }

        return true;
    }

    shouldDisplayName() {
        if (this.item.coupon_id) {
            return false;
        }

        return true;
    }

    shouldPopupProduct(item: ShoppingCartItem) {
        return item.bonus_campaign_id
            || item.bonus_with_levels_campaign_id
            || item.isChallengeReward;
    }

    isSkippedReward() {
        return this.item.isChallengeReward && this.item.skip_reward;
    }

    shouldDisplayPrice() {
        if (this.item.product.type === 'configurable') {
            return false;
        }

        return true;
    }

    getRequiredPurchaseProductUrl(): string {
        return productBuildUrl(this.configService, this.item.product.requiredPurchaseProduct);
    }
}
