import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PersistedFilterService {

    public page: number;
    public activeSort = null;
    public activeFilters: any[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.page) {
                this.page = params.page;
            } else {
                this.page = null;
            }

            if (params.sort) {
                this.activeSort = JSON.parse(atob(params.sort));
            } else {
                this.activeSort = null;
            }

            if (params.filters) {
                this.activeFilters = JSON.parse(atob(params.filters));
            } else {
                this.activeFilters = [];
            }
        });
    }

    public findFilterData(agg) {
        return this.activeFilters?.find( ({ property }) => property === agg );
    }

    public setActiveSort(activeSort) {
        this.activeSort = activeSort;
    }

    public setActiveFilters(activeFilters: any[]) {
        this.activeFilters = activeFilters;
    }

    public setPage(page: number) {
        this.page = page;
    }

    public applyFilterToRoute(): void {
        const params = {
            page: null,
            sort: null,
            filters: null,
        };

        if (this.activeSort) {
            params.sort = btoa(JSON.stringify(this.activeSort));
        }

        if (2 <= this.page) {
            params.page = this.page;
        }

        if (this.activeFilters.length) {
            params.filters = btoa(JSON.stringify(this.activeFilters));
        }

        if (!this.activeFilters.length) {
            delete(params.filters);
        }

        if (!params.page) {
            delete(params.page);
        }

        this.router.navigate(
            [], {
                queryParams: params,
                relativeTo: this.activatedRoute,
                replaceUrl: true
            }
        );
    }
}
