import { Component, Input } from '@angular/core';
import { Product } from 'src/app/core/product.service';
import { ShoppingCartItem } from "src/app/core/shopping-cart-data.service";

@Component({
    selector: 'nc-product-price',
    templateUrl: './products-price.component.html',
    styleUrls: ['./products-price.component.scss']
})
export class ProductPriceComponent {

    isMinPrice = false;
    price = 0;
    newPrice = null;
    bonusPrice = null;

    @Input() set product(product: Product) {

        this.isMinPrice = false;
        this.newPrice = null;
        this.bonusPrice = null;

        if (product.type === 'wrapper') {
            this.price = product.minPrice;

            if (product.newMinPrice && product.newMinPrice !== this.price) {
                this.newPrice = product.newMinPrice;
            }

            if (product.newMinPrice !== product.newMaxPrice
                || product.minPrice !== product.maxPrice) {
                this.isMinPrice = true;
            }

            return;
        }

        // 0 means free. Null or undefined = use default price
        const hasNewPrice = typeof product.newPrice !== 'undefined' && product.newPrice !== null;

        if (hasNewPrice && product.newPrice !== product.price) {
            this.newPrice = product.newPrice;
        }

        this.price = product.price;
    }

    @Input() set cartItem(cartItem: ShoppingCartItem) {

        this.isMinPrice = false;
        this.price = cartItem.price;
        if (!cartItem.product.hideNewPrice) {
            this.newPrice = cartItem.newPrice;
        }

    }

}
