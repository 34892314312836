import { Component, Input } from '@angular/core';
import { Product } from "src/app/core/product.service";

@Component({
    selector: 'nc-product-out-of-stock',
    templateUrl: './product-out-of-stock.component.html',
    styleUrls: ['./product-out-of-stock.component.scss']
})
export class ProductOutOfStockComponent {

    @Input() product: Product;

}
