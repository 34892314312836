import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartItem } from "src/app/core/shopping-cart-data.service";
import * as moment from 'moment';
import { Product } from "../../../core/product.service";

@Component({
    selector: 'nc-item-metadata-ticket',
    templateUrl: './item-metadata-ticket.component.html',
    styleUrls: ['./item-metadata-ticket.component.scss']
})
export class ItemMetadataTicketComponent {

    @Input() item: ShoppingCartItem;
    moment = moment;
    Object = Object;

    getDays(item) {
        const product: Product = item.product;
        return Object.keys(item.meta_data?.days).filter((i) => {
               return item.meta_data?.days[i];
            })
            .map((key) => {
                // Find the human friendly label, or use the id/key.
                const day = product.days.find((day) => day.id === key);
                return day?.label ?? key;
            });
    }
}
