<mat-sidenav-container>
    <mat-sidenav
        class="shopping-cart-drawer"
        mode="over"
        position="end"
        [fixedInViewport]="true"
        [opened]="shoppingCartIsOpen"
        (closed)="closeShoppingCart()">
        <nc-shopping-cart [displayCheckoutButton]="true"></nc-shopping-cart>
    </mat-sidenav>
    <mat-sidenav-content>
        <nc-site-header [menu]="menus?.intranet" [config]="config"></nc-site-header>
        <ng-container *ngIf="!isAdminPage && !isCheckoutPage && !isStarterKitPage">
            <nc-mobile-shop-menu *ngIf="isMobile" [menu]="menus?.webshop" (openChange)="backdropIsActive=$event"></nc-mobile-shop-menu>
            <nc-desktop-shop-menu *ngIf="!isMobile" [menu]="menus?.webshop" (openChange)="backdropIsActive=$event"></nc-desktop-shop-menu>
        </ng-container>
        <div class="backdrop" [class.active]="backdropIsActive">
            <main>
                <router-outlet></router-outlet>
            </main>
            <nc-site-footer></nc-site-footer>
        </div>
        <nc-product-add-notification *ngIf="isMobile"></nc-product-add-notification>
    </mat-sidenav-content>
</mat-sidenav-container>
