import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { MenuService } from '../core/menu.service';
import { ConfigService, AppConfig } from '../core/config.service';
import { LayoutService } from './layout.service';

@Component({
    selector: 'nc-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {

    subs = new SubSink();

    menus;
    config: AppConfig;
    backdropIsActive = false;
    isMobile = false;
    isAdminPage = false;
    isCheckoutPage = false;
    isFrontPage = false;
    isOrderDonePage = false;
    isStarterKitPage = false;
    shoppingCartIsOpen = false;

    constructor(
        private router: Router,
        private menuService: MenuService,
        private configService: ConfigService,
        private breakpointObserver: BreakpointObserver,
        private layoutService: LayoutService,
    ) { }

    @HostBinding('class.admin-page') get adminPageClass() {
        return this.isAdminPage;
    }

    @HostBinding('class.checkout-page') get checkoutPageClass() {
        return this.isCheckoutPage;
    }

    @HostBinding('class.front-page') get frontPageClass() {
        return this.isFrontPage;
    }

    @HostBinding('class.gradient-bg') get hasGradientBackground() {
        return this.isFrontPage
            || this.isCheckoutPage
            || this.isOrderDonePage;
    }

    ngOnInit() {
        this.subs.sink = this.configService.configChanges.subscribe((config) => {
            this.config = config;
        });
        this.subs.sink = this.configService.localeChanges.subscribe((locale) => {
            this.menuService.getAllMenus(locale).subscribe((menus) => this.menus = menus);
        });
        this.subs.sink = this.breakpointObserver.observe('(max-width: 1000px)').subscribe((response) => {
            this.isMobile = response.matches;
        });
        this.subs.sink = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.isAdminPage = event.url.startsWith('/administration');
            this.isCheckoutPage = event.url.startsWith('/checkout');
            this.isOrderDonePage = event.url.startsWith('/orders/order/done');
            this.isStarterKitPage = event.url.startsWith('/starter-kit');
            this.isFrontPage = event.url === '/';
        });
        this.subs.sink = this.layoutService.openedCartChanges.subscribe((type) => {
            this.shoppingCartIsOpen = type !== null;
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    closeShoppingCart() {
        this.layoutService.closeShoppingCart();
    }

}
