import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/product.service';
import {
    ControlContainer,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { SubSink } from 'subsink';
import * as moment from 'moment';
import {conditionallyRequiredValidator, UniqueEmailForTicketEventValidator} from '../../../helpers/validators';

@Component({
    selector: 'nc-product-ticket-form',
    templateUrl: './product-ticket-form.component.html',
    styleUrls: ['./product-ticket-form.component.scss']
})
export class ProductTicketFormComponent implements OnInit {

    @Input() product: Product;

    subs = new SubSink();
    parentForm: FormGroup;
    metaFG: FormGroup;
    daysFG: FormGroup;
    moment = moment;

    // Let Angular inject the control container
    constructor(
        protected controlContainer: ControlContainer,
        protected formBuilder: FormBuilder,
        protected uniqueEmailForTicketEventValidator: UniqueEmailForTicketEventValidator,
    ) {
    }

    ngOnInit() {
        // Set our parentForm property to the parent control
        // (i.e. FormGroup) that was passed to us, so that our
        // view can data bind to it
        this.parentForm = this.controlContainer.control as FormGroup;

        // make a nested group
        this.parentForm.addControl('metaData', this.metaFG = this.formBuilder.group({
            name: ['', [conditionallyRequiredValidator(() => this.product.requireName)]],
            email: new FormControl('', {
                validators: [conditionallyRequiredValidator(() => this.product.requireEmail)],
                asyncValidators: [this.uniqueEmailForTicketEventValidator.validate.bind(this.uniqueEmailForTicketEventValidator)],
                updateOn: 'blur',
            }),
            mobile: ['', [conditionallyRequiredValidator(() => this.product.requireMobile)]],
            food: ['', []],
            days: this.daysFG = this.formBuilder.group({}),
        }));

        this.daysFG.setValidators(this.daysValidator());

        if (this.product.days) {
            this.addDaysToForm();
        }

        this.uniqueEmailForTicketEventValidator.productId = this.product?.id;
    }

    private addDaysToForm() {
        this.product.days.forEach((day) => {
            this.daysFG.addControl(day.id, new FormControl(false));
        });
    }

    daysValidator(): ValidatorFn {
        return (fg: FormGroup): ValidationErrors => {
            if (!this.product.days || !this.product.days.length) {
                return null;
            }

            for (const field in fg.controls) {
                const control = fg.get(field);
                if (control.value === true) {
                    return null; // at least one day is set
                }
            }

            return {something: 'someError'};
        };
    }

    displayTicketInformation(product: Product): boolean {
        // if all of these are true, then nothing should be shown.
        const hide = product.hideName
            && product.hideEmail
            && product.hideMobile
            && product.hideFoodPreferences
            && product.days?.length < 1;

        return !hide;
    }

    getEmailError(error: string): boolean {
        return this.metaFG.get('email').hasError(error);
    }

    getEmailHasError(): boolean {
        return this.getEmailError('emailIsConsultant')
            || this.getEmailError('emailIsRegistered')
            || this.getEmailError('emailHasErrors');
    }
}
