import { Component, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyCustomerBase, MyCustomerLanguageExtras, MyCustomersService } from '../../core/my-customers.service';

interface InputData {
    customerIds: string[];
}

@Component({
    selector: 'nc-pcp-table-by-customer-ids-dialog',
    templateUrl: './pcp-table-by-customer-ids-dialog.component.html',
    styleUrls: ['./pcp-table-by-customer-ids-dialog.component.scss']
})
export class PcpTableByCustomerIdsDialogComponent implements OnInit {

    loading = true;
    pcpCustomers?: (MyCustomerBase & MyCustomerLanguageExtras)[];

    constructor(
        protected myCustomersService: MyCustomersService,
        @Inject(MAT_DIALOG_DATA) public data: InputData,
    ) {
    }

    ngOnInit() {
        this.myCustomersService.getCustomerByCustomerIds(this.data.customerIds)
            .subscribe((response) => {
                this.pcpCustomers = response.customers;
                this.loading = false;
            });
    }

}
