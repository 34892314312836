<div>
    <div *ngIf="item.meta_data?.preview" class="field">
        <b>{{'shopping-cart.pcp.recipients' | translate}}:</b>
        <div *ngFor="let customer of getPreviewCustomers(item)">{{customer.firstname}} {{customer.lastname}}</div>
        <span *ngIf="getHowManyMoreCount(item)">
            <a href="javascript:void(0);" (click)="popupPcpCustomerList(getCustomers(item))">
                {{('shopping-cart.and-this-many-more' | translate: {count: getHowManyMoreCount(item)})}}
            </a>
        </span>

    </div>
</div>
