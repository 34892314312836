import { Component, OnInit } from '@angular/core';
import { ChallengesComponent } from "src/app/shared/cms/blocks/challenges/challenges.component";

@Component({
  selector: 'nc-shopping-cart-challenges',
  templateUrl: './shopping-cart-challenges.component.html',
  styleUrls: ['./shopping-cart-challenges.component.scss']
})
export class ShoppingCartChallengesComponent extends ChallengesComponent {

}
