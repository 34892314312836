<div class="container">
    <div *ngIf="loading">
        <mat-spinner diameter="25"></mat-spinner>
    </div>
    <div *ngIf="empty">
        {{'category.no-result' | translate}}
    </div>
</div>
<nc-category
    *ngIf="!loading && !empty"
    [categoryName]="categoryName"
    [breadcrumbs]="breadcrumbs"
    [baseFilterObservable]="baseFilterObservable"
    [noResultText]="'page.no-result.current-offers' | translate"
    [path]="path"
></nc-category>
