import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductLayoutService} from "../../../core/product-layout.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'nc-products-layout-switch',
    templateUrl: './products-layout-switch.component.html',
    styleUrls: ['./products-layout-switch.component.scss']
})
export class ProductsLayoutSwitchComponent implements OnInit, OnDestroy {
    private sub: Subscription;
    public layout: string;

    constructor(
        private productLayout: ProductLayoutService,
    ) {
    }

    ngOnInit(): void {
        this.sub = this.productLayout.onChange().subscribe((v) => this.layout = v)
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    select(layout: string) {
        this.productLayout.setValue(layout)
    }
}
